@import url(https://fonts.googleapis.com/css2?family=Red+Hat+Display:wght@400;500;600;700;800;900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
    --ui-dark: #0F172A;
    --ui-text: #202C40;
    --ui-green: #1155ED;
    --ui-green2: #00AC47;
    --ui-blue: #1877F2;
    --ui-blue2: #3283FC;
    --ui-grey: #CBD5E1;
    --ui-grey-600: #475569;
    --ui-grey-400: #94A3B8;
    --ui-red: #FE2C25;
    --ui-orange: #FFBB00;
    --ui-font-regular: '400';
    --ui-font-medium: '500';
    --ui-font-bold: '700';
    /* LAnding Page Variables */
    --text-color: #73737B;
    --text-light: #888888;
    /* --heading: #101621; */
    --heading: #050505;
    --blue-dark: #6F55FF;
    --red-light: #FD6A5E;
    --yellow-deep: #FFB840;
    --blue-light: #3BB0D7;
    /* --purple-blue: #655C97; */
    --purple-blue: #082A66;
    --theme-grey: #E6F1FF;
    --vin-red: #973C56;
    --brand-color: #1155ED;
    --animate-duration: 1s;
    --animate-delay: 1s;
    --animate-repeat: 1;
}


/* Importing Fonts */
.bg-green {
    background-color: #1155ED !important;
    background-color: var(--ui-green) !important;
}

.text-green {
    color: #1155ED !important;
    color: var(--ui-green) !important;
}

.text-error {
    color: #E22357;
}

* {
    box-sizing: border-box;
}

body {
    margin: 0;
    padding: 0;
    background: #202C40;
    background: var(--ui-text);
    font-family: 'Red Hat Display', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
li {
    margin: 0;
    padding: 0;
}

img {
    width: 100%;
    height: auto;
}

a,
button,
a:hover,
button:hover {
    transition: .3s all ease;
}


/* Customize Bootsrap Container */

@media (min-width: 1230px) {
    .container {
        max-width: 1202px;
    }
}


/* Adding Fonts */


/* @font-face {
    font-family: 'Objectivity Regular';
    src: url(../fonts/objectivity.regular.otf);
}

@font-face {
    font-family: 'Objectivity Medium';
    src: url(../fonts/Objectivity-Medium.otf);
}

@font-face {
    font-family: 'Objectivity Bold';
    src: url(../fonts/objectivity.bold.otf);
} */

.section_link a {
    font-weight: 600;
    font-size: 20px;
    letter-spacing: -0.02em;
    border-bottom: 1px solid transparent;
    padding-bottom: 4px;
    padding: 0 5px;
}

.section_link a.active,
.section_link a:hover {
    border-bottom: 1px solid #1155ED;
    border-bottom: 1px solid var(--ui-green);
    color: #1155ED;
    color: var(--ui-green);
    font-weight: 700;
}


/* Site Loader */

.site-loader {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 2;
}

.site-loader span {
    position: absolute;
    display: inline-block;
    width: 100px;
    height: 100px;
    border-radius: 100%;
    background: #1155ED;
    background: var(--ui-green);
    -webkit-animation: site-loader 1.5s linear infinite;
    animation: site-loader 1.5s linear infinite;
}

.site-loader span:last-child {
    animation-delay: -0.9s;
    -webkit-animation-delay: -0.9s;
}


/* Site Loader Animation */

@keyframes site-loader {
    0% {
        -webkit-transform: scale(0, 0);
                transform: scale(0, 0);
        opacity: 0.8;
    }
    100% {
        -webkit-transform: scale(1, 1);
                transform: scale(1, 1);
        opacity: 0;
    }
}

@-webkit-keyframes site-loader {
    0% {
        -webkit-transform: scale(0, 0);
        opacity: 0.8;
    }
    100% {
        -webkit-transform: scale(1, 1);
        opacity: 0;
    }
}

#cardOptions::after {
    content: none;
}


/* Site Header */

header .site-header {
    background: #fff;
    /* box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.06); */
    border-bottom: 1px solid #CBD5E1;
    border-bottom: 1px solid var(--ui-grey);
}

@media(min-width: 768px) {
    header .site-header {
        padding: 0 3%;
    }
}

.site-header nav {
    padding: 5px 0;
}

.site-header nav ul li a {
    opacity: 1;
    font-weight: 500;
}

.site-header nav ul li a:not(.btn) {
    color: #202C40 !important;
    color: var(--ui-text) !important;
    margin-left: 13px;
    position: relative;
    font-size: 14px;
}

.site-header nav ul li a.active {
    color: #1155ED !important;
    color: var(--ui-green) !important;
    font-weight: 700;
}

.site-header nav ul li a.btn {
    font-size: 16px;
    padding: 7px 23px !important;
    border: 1px solid #1155ED;
    border: 1px solid var(--ui-green);
    border-radius: 4px;
    color: #1155ED !important;
    color: var(--ui-green) !important;
}

.site-header nav ul li a.btn:hover {
    background: #1155ED;
    background: var(--ui-green);
    color: #fff !important;
}

.site-header nav ul li a.sign-up {
    margin: 20px 0 0;
    border-color: #1155ED;
    border-color: var(--ui-green);
    background: #1155ED;
    background: var(--ui-green);
    color: #fff !important;
}

@media (min-width: 576px) {
    .site-header nav ul li a.sign-up {
        margin: 0 0 0 20px;
    }
}

.site-header nav ul li a.sign-up:hover {
    background: transparent;
    color: #1155ED !important;
    color: var(--ui-green) !important;
}


/* Dashbaord Nav */

.site-header.dashboard button.navbar-toggler {
    position: absolute;
    right: 0;
}

.site-header nav ul li a:not(.btn)::before {
    content: '';
    position: absolute;
    background: #1155ED;
    background: var(--ui-green);
    height: 2px;
    width: 0;
    border-radius: 30px;
    left: 0;
    right: 0;
    bottom: 4px;
    margin: auto;
    transition: .3s all ease;
}

.site-header nav ul li.active a::before,
.site-header nav ul li a:hover::before {
    width: 40px;
    transition: .3s all ease;
}

.site-header .dropdown button {
    box-shadow: none;
    position: relative;
}

@media(min-width: 768px) {
    button#dropdownNotification {
        margin: 0 29px 0 22px;
    }
}

button#dropdownNotification img {
    width: 26px;
    height: 26px;
}

button#dropdownNotification::after {
    content: unset;
}

button#dropdownProfile {
    display: flex;
    align-items: center;
    text-align: left;
    font-size: 14px;
    color: #0F172A;
    color: var(--ui-dark);
    font-weight: 500;
}

button#dropdownProfile i {
    font-size: 10px;
}

@media (min-width: 768px) {
    button#dropdownProfile {
        min-width: 135px;
    }
}

button#dropdownProfile::after {
    display: none;
}

button#dropdownProfile img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
}

button#dropdownProfile+.dropdown-menu {
    left: -40%;
}

@media (max-width: 991px) {
    .site-header.dashboard nav {
        padding-right: 70px;
    }
    .site-header .navbar-collapse {
        position: absolute;
        top: 70px;
        background: #fff;
        left: 0px;
        right: 0;
        padding: 20px;
        z-index: 10;
        border-radius: 0 0 10px 10px;
        box-shadow: 0px 6px 30px rgb(0 0 0 / 6%);
    }
    .site-header .noti-drop {
        margin-left: auto;
    }
}

@media (max-width: 767px) {
    .site-header nav ul li a:not(.btn)::before {
        left: 0;
        right: unset;
    }
    .site-header .noti-drop {
        margin-right: 15px;
    }
    .site-header {
        position: unset;
    }
    .site-header.dashboard button.navbar-toggler {
        right: 15px;
    }
    .site-header .navbar-collapse {
        top: 56px;
    }
    .site-header nav ul li a:not(.btn) {
        margin-left: 15px;
    }
}

@media (max-width: 420px) {
    .site-header .navbar-brand img {
        max-width: 120px;
    }
    button#dropdownProfile img {
        width: 40px;
        height: 40px;
    }
}

@media (max-width: 360px) {
    .site-header .noti-drop {
        display: none;
    }
}


/* Layout Login SignUp Page */

section.site-main {
    position: relative;
}

section.site-main .shape {
    position: absolute;
    z-index: -1;
}

section.site-main .shape.left {
    width: 70%;
    height: 100%;
    top: 3%;
    left: -15%;
}

section.site-main .shape.right {
    right: 0;
}

section.site-main .main-row {
    padding: 50px 15px;
}

@media (min-width: 768px) {
    section.site-main .main-row {
        min-height: calc(100vh - 164px);
        padding: 0 0;
    }
}

section.site-main .page-col {
    max-width: 570px;
    margin: auto;
    width: 100%;
}

section.site-main .profile .page-col {
    max-width: 600px;
    padding: 30px 0;
    /* padding: 30px 30px; */
    /* box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.06); */
    border-radius: 5px;
}

@media (max-width: 767px) {
    section.site-main .profile .page-col {
        padding: 30px 0;
    }
}

.section-title h1 {
    position: relative;
    display: inline-block;
}

.section-title h1::before {
    content: '';
    position: absolute;
    width: calc(100% - 13px);
    height: 2px;
    left: 0;
    bottom: -8px;
    background: #0F172A;
    background: var(--ui-dark);
}

.section-title h1::after {
    content: '';
    position: absolute;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    right: 0;
    bottom: -10px;
    background: #1155ED;
    background: var(--ui-green);
}

.section-title p {
    color: #202C40;
    color: var(--ui-text);
    margin: 14px 0 0;
}

.page-col .form-col {
    padding-top: 40px;
    padding-bottom: 30px;
}


/* .page-col .form-col label {
    color: var(--ui-grey-600);
}

.page-col .form-col input {
    height: 50px;
    color: var(--ui-grey-400);
    border-color: var(--ui-grey);
    border-radius: 4px;
}

.page-col .form-col input::placeholder {
    color: var(--ui-grey-400);
} */

.page-col .form-col input[type="submit"] {
    background: #1155ED;
    background: var(--ui-green);
    color: #fff;
    transition: .3s all ease;
    border-color: #1155ED;
    border-color: var(--ui-green);
    height: 50px;
}

.page-col .form-col input[type="submit"]:hover {
    background: transparent;
    color: #1155ED;
    color: var(--ui-green);
    transition: .3s all ease;
}

.page-col .sign-with-client {
    border-bottom: 1px solid #CBD5E1;
    border-bottom: 1px solid var(--ui-grey);
    position: relative;
    padding: 40px 0 25px;
}

.sign-with-client p {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    bottom: -14px;
    background: #fff;
    padding: 0 9px;
}

.sign-with-client button {
    font-size: 14px;
    color: #202C40;
    color: var(--ui-text);
    border: 1px solid #CBD5E1;
    border: 1px solid var(--ui-grey);
    border-radius: 5px;
    width: 100%;
    padding: 13px 10px;
    text-align: center;
}

.sign-with-client button img {
    max-width: 19px;
    margin-right: 10px;
    display: inline-block;
    vertical-align: middle;
}

.sign-with-client button img[alt="facebook"] {
    max-width: 10px;
}


/* ========================================== create Profile Page ======================= */

.form-col .profile-img {
    width: 130px;
    height: 130px;
    margin: 0 auto 50px;
}

.form-col .profile-img img {
    height: 100%;
}

.form-col .profile-img label {
    background: #fff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.1);
    width: 30px;
    height: 30px;
    position: absolute;
    margin: 0;
    bottom: 13px;
    right: 13px;
    cursor: pointer;
}

.form-col .profile-img label input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}

.form-col .urlField label {
    position: relative;
    -webkit-transform: translate(-9px, 14px) scale(0.75);
            transform: translate(-9px, 14px) scale(0.75);
    font-weight: 700;
    color: #475569;
    color: var(--ui-grey-600);
    z-index: 1;
    background: #fff;
    font-size: 16px;
    padding: 0 5px;
}

.form-col .urlField input {
    height: 50px;
    border-left: 0;
    font-weight: 500;
    font-size: 14px;
    color: #0F172A;
    color: var(--ui-dark);
    padding-left: 0;
    outline: 0;
    box-shadow: none;
    border-color: rgba(0, 0, 0, 0.23);
}

.form-col .urlField .input-group-prepend span {
    background: transparent;
    font-weight: 500;
    font-size: 14px;
    color: #0F172A;
    color: var(--ui-dark);
    padding-right: 0;
}

.form-col .slect-with-icon select {
    padding-left: 48px;
    height: 50px;
}

.form-col .slect-with-icon img,
.form-col .slect-with-icon i {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 17px;
    height: 17px;
    left: 20px;
    margin: auto;
}


/* @media (min-width: 768px) {
    .sign-with-client button {
        width: 48%;
    }
    .sign-with-client button:last-of-type {
        margin-top: 0;
    }
} */

@media (max-width: 576px) {
    div#navbarNav {
        padding: 30px 0;
    }
    .section-title h1 {
        font-size: 30px;
    }
}

@media (max-width: 390px) {
    .section-title h1 {
        font-size: 24px;
    }
    .section-title p {
        font-size: 14px;
    }
}


/* Register Page */

.form-col .label-top label {
    position: absolute;
    color: #475569;
    color: var(--ui-grey-600);
    font-weight: 700;
    font-size: 12px;
    margin: auto;
    background: #fff;
    top: -7px;
    left: 9px;
    padding: 0 4px;
    line-height: 1.0;
}

.form-col .label-top input {
    color: #0F172A;
    color: var(--ui-dark);
    font-weight: 500;
    font-size: 14px;
}

.form-col .pwd-field-eye button {
    position: absolute;
    color: #202C40;
    color: var(--ui-text);
    right: 20px;
    top: 0;
    bottom: 0;
    margin: auto;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    line-height: 1.0;
}

span.pwd-error {
    display: block;
    margin: -12px 0 12px;
    font-size: 14px;
    color: #E22357;
}

.form-col .pwd-power span {
    border-radius: 50px;
    height: 4px;
    width: 18%;
    background-color: rgb(204, 216, 219);
}

@media (max-width: 767px) {
    .form-col .forget-pwd {
        flex-direction: column;
    }
}

.form-col .forget-pwd {}

.form-col .forget-pwd label,
.form-col .forget-pwd a {
    text-decoration: none;
}

.form-col .forget-pwd a {
    color: #202C40;
    color: var(--ui-text);
    font-weight: 500;
    font-size: 14px;
}

.page-content a.reset-pwd-btn {
    background: #1155ED;
    background: var(--ui-green);
    font-weight: '500';
    font-weight: var(--ui-font-medium);
    color: #fff;
    width: 100%;
    border: 1px solid #1155ED;
    border: 1px solid var(--ui-green);
    padding: 12px 10px;
}

.page-content a.reset-pwd-btn:hover {
    background: transparent;
    color: #1155ED;
    color: var(--ui-green);
}


/* .rec_pass h1 {
    letter-spacing: -0.02em;
    font-size: 24px;
    color: var(--ui-text);
} */

.rec_pass h1::before,
.rec_pass h1::after {
    content: none;
}


/* Button CSS Change for Connect Calendar Page */

.page-content.connect-calnder .client-btn button {
    position: relative;
}

.page-content.connect-calnder .client-btn button img {
    max-width: 22px !important;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 12px;
    margin: auto;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}


/* Skip These Steps */

.page-content .skip-this a {
    color: #202C40;
    color: var(--ui-text);
}


/* Dashboard Page */

.green-btn {
    font-size: 16px;
    color: #fff;
    background: #1155ED;
    background: var(--ui-green);
    border: 1px solid #1155ED;
    border: 1px solid var(--ui-green);
    border-radius: 5px;
    padding: 12px 26px;
}

.green-btn:hover {
    background: transparent;
    color: #1155ED;
    color: var(--ui-green);
}


/* Booking Columns */

.dashboard-content .booking-info-col {
    background: #fff;
    box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.06);
    border-radius: 15px;
    padding: 20px 20px;
}

.booking-info-col h2 {
    font-weight: 600;
    font-size: 20px;
    color: #0F172A;
    color: var(--ui-dark);
}

.booking-info-col .booking-on-off {
    color: #0F172A;
    color: var(--ui-dark);
    margin: 26px 0 13px;
}


/* .booking-info-col .dropdown-item {
    padding: 0;
}

.booking-info-col .dropdown-item a {
    padding: .25rem 1.5rem;
} */


/* The switch - the box around the slider */

.toggle-btn .switch {
    position: relative;
    display: inline-block;
    width: 36px;
    height: 20px;
    margin: 0;
}


/* Hide default HTML checkbox */

.toggle-btn .switch input {
    opacity: 0;
    width: 0;
    height: 0;
}


/* The slider */

.toggle-btn .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgb(204, 216, 219);
    border-radius: 3px;
    transition: .4s;
}

.toggle-btn .slider:before {
    position: absolute;
    content: "";
    height: 10px;
    width: 9px;
    left: 6px;
    bottom: 5px;
    background-color: #0F172A;
    background-color: var(--ui-dark);
    border-radius: 1px;
    transition: .4s;
}

.toggle-btn input:checked+.slider {
    background-color: #1155ED;
    background-color: var(--ui-green);
}

.toggle-btn input:checked+.slider:before {
    -webkit-transform: translateX(15px);
    transform: translateX(15px);
    background-color: #fff;
}


/* Booking Meeting Link */

.booking-info-col .meeting-link a i {
    color: #0F172A;
    color: var(--ui-dark);
    font-size: 19px;
    vertical-align: middle;
    margin-right: 3px;
}

.booking-info-col .meeting-link p {
    font-weight: '500';
    font-weight: var(--ui-font-medium);
    color: #0F172A;
    color: var(--ui-dark);
    margin-top: 15px;
}

.booking-info-col .meeting-link p i {
    font-size: 18px;
    margin-right: 5px;
}

.booking-info-col .meeting-description {
    font-size: 14px;
    margin: 16px 0 19px;
    line-height: 24px;
}

.booking-manage-button a,
.booking-manage-button button {
    width: 100%;
}

.btn-delete {
    border: 1px solid rgb(240, 145, 171);
    color: #E22357;
    padding: 12px 5px;
}

.btn-delete:hover,
.btn-delete:focus {
    border-color: #E22357;
    background: #E22357;
    color: #fff;
}

.btn-edit {
    border: 1px solid #1155ED;
    border: 1px solid var(--ui-green);
    color: #1155ED;
    color: var(--ui-green);
    padding: 12px 5px;
}

.btn-edit:hover,
.btn-edit:focus {
    border-color: #1155ED;
    border-color: var(--ui-green);
    background: #1155ED;
    background: var(--ui-green);
    color: #fff;
}


/* ========================== My Bookings ========================== */

.my-bokings.section-title {
    margin-bottom: 35px;
}


/* ========================== Create Event ========================== */


/* .creat-event-content .form-col input,
.creat-event-content .form-col textarea {
    padding-left: 20px;
}

.creat-event-content .form-col textarea,
.creat-event-content .form-col textarea::placeholder {
    color: var(--ui-dark);
} */

.creat-event-content .form-col .label-top label {
    left: 16px;
}

.creat-event-content .form-col .label-top i {
    position: absolute;
    left: 20px;
    top: 0;
    bottom: 0;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin: auto;
}


/* EVEnt Days */

.creat-event-content .event-days .day-time-container {
    border: 0.3px solid rgba(0, 0, 0, 0.23);
    border-radius: 5px;
    padding: 10px 20px;
}

.creat-event-content .event-days label span {
    font-size: 14px;
}


/* .general_avail .event-days label{
    position: relative;
}
.general_avail .event-days label::before {
    content: '';
    position: absolute;
    background: var(--ui-green);
    border-radius: 3px;
    width: 18px;
    height: 18px;
    top: 2px;
    left: -25px;
    cursor: pointer;
}

.general_avail .event-days label::after {
    content: '';
    position: absolute;
    background: #fff;
    border-radius: 1px;
    width: 8px;
    height: 8px;
    top: 7px;
    left: -20px;
    cursor: pointer;
    display: none;
    transition: .3s all ease;
} */

.creat-event-content [class*="event-day-"] {
    margin-bottom: 15px;
}

.creat-event-content [class*="event-day-"] input:checked+label::after {
    display: inline-block;
    transition: .3s all ease;
}

.creat-event-content .event-days [class*="jsx-"],
.book-event-timetable [class*="jsx-"] {
    width: 100%;
    margin-bottom: 5px;
    z-index: 2;
    background: white;
}

.event-days label p {
    color: #0F172A;
    color: var(--ui-dark);
}

.event-days .day-time {
    width: 72%;
    align-items: center;
    margin-left: auto;
    justify-content: flex-end;
}

.event-days .day-time-container .day-time:not(:last-of-type) {
    margin-bottom: 12px;
}

.event-days .day-time p {
    background: #F2F5F7;
    border-radius: 5px;
    line-height: 1.0;
    color: #0F172A;
    color: var(--ui-dark);
    padding: 12px 14px 11px;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
}

.event-days .day-time span {
    color: #0F172A;
    color: var(--ui-dark);
    margin: 0 10px;
}

.event-days .day-time button.plus-btn {
    margin-left: 20px;
    box-shadow: none !important;
}

.event-days .MuiFormControl-root .MuiSvgIcon-root {
    display: none;
}

.event-days .day-time button.plus-btn i:not(.text-error) {
    opacity: 0.6;
}

.event-days .day-time button.plus-btn:hover i {
    opacity: 1;
}

.event-days .day-time .dropdown {
    width: 100%;
    max-width: 85px;
}

.event-days .day-time button.dropdown-toggle {
    color: #202C40;
    color: var(--ui-text);
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-radius: 5px;
    width: 100%;
    padding: 9px 5px;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.0;
}

.event-days .day-time button.dropdown-toggle::after {
    display: none;
}

.event-days .day-time .dropdown-menu {
    -webkit-transform: none !important;
            transform: none !important;
    top: 31px !important;
    left: -13px !important;
    height: 170px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.creat-event-content .event-days label.is-unavail::before {
    background: #0F172A;
    background: var(--ui-dark);
    opacity: 0.2;
}

.creat-event-content .event-days label.is-unavail::after {
    background: #0F172A;
    background: var(--ui-dark);
}

.event-days label.is-unavail p {
    background: transparent;
}

.event-days .event-quest {
    margin: 30px 0;
}


/* .event-quest input {
    border: 0.3px solid #023D4D;
    border-radius: 5px;
    color: var(--ui-dark);
    padding-left: 20px;
    margin-right: 10px;
} */

.event-quest button.btn {
    background: #0F172A;
    background: var(--ui-dark);
    color: #fff;
    width: 50px;
    font-size: 24px;
}

.event-days .create-btn button {
    padding: 12px 29px;
    font-weight: '500';
    font-weight: var(--ui-font-medium);
}

.event-days .create-btn .btn-edit {
    background: #1155ED;
    background: var(--ui-green);
    margin-left: 20px;
    color: #fff;
}

.event-days .create-btn .btn-edit:hover {
    background: transparent;
    color: #1155ED;
    color: var(--ui-green);
}

@media (max-width: 767px) {
    .event-days label:not(.is-unavail) .day-time {
        width: 100%;
        font-size: 14px;
    }
    .event-days label p {
        font-size: 14px;
    }
    /* .creat-event-content .event-days label {
        padding: 10px 10px 10px 38px;
    } */
    .creat-event-content .event-days label::before {
        left: 10px;
    }
    .creat-event-content .event-days label::after {
        left: 14.5px;
    }
    .event-quest h4 {
        font-size: 15px;
    }
    .event-days .create-btn button {
        width: 100%;
    }
    .event-days .create-btn .btn-edit {
        margin: 20px 0 0;
    }
}


/* Event Details page */

section.site-main .section-title select,
.section-title option {
    color: #0F172A;
    color: var(--ui-dark);
    cursor: pointer;
}

section.site-main .event-details-content {
    min-height: 574px;
    background: #FFFFFF;
    box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.06);
    border-radius: 15px;
    overflow-x: scroll;
}

.event-details-content table {
    width: 100%;
}

.event-details-content table th,
.event-details-content table td {
    padding: 20px 30px;
    color: #0F172A;
    color: var(--ui-dark);
    font-size: 14px;
}

.event-details-content table th {
    border-bottom: 0.3px solid #023D4D;
    font-weight: '500';
    font-weight: var(--ui-font-medium);
}

table p {
    border-radius: 4px;
    display: inline-block;
    line-height: 1.0;
    padding: 8px 10px;
}

table p.is-schedule {
    background: #ECF8F2;
    color: #1155ED;
    color: var(--ui-green);
}

table p.is-past {
    background: #F2F5F7;
    color: #202C40;
    color: var(--ui-text);
}

table p.is-cancel {
    background: #FEF4E5;
    color: #F98D00;
}

table button {
    font-size: 14px;
    padding: 5px 5px 2px;
    margin-left: auto;
    display: block;
}

table button.btn-view {
    color: #202C40;
    color: var(--ui-text);
    border: 1px solid #202C40;
    border: 1px solid var(--ui-text);
}

table button.btn-view:hover {
    background: #202C40;
    background: var(--ui-text);
    color: #fff;
}

@media (max-width: 767px) {
    .event-details-content table th {
        padding: 20px 105px 20px 30px;
    }
    .event-details-content table th:last-of-type {
        padding: 20px 105px 20px 64px;
    }
}


/* Booking Popup */

.event-detail-popup .modal-dialog {
    position: absolute;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 20px auto;
}

.event-detail-popup .modal-content {
    padding: 50px;
    border-radius: 15px;
}

.event-detail-popup .modal-header button {
    font-weight: 400;
    font-size: 31px;
    position: absolute;
    top: 16px;
    right: 20px;
    padding: 0;
    margin: auto;
    outline: 0;
}

.event-detail-popup .modal-content h2 {
    color: #0F172A;
    color: var(--ui-dark);
    font-weight: 700;
    font-size: 36px;
    margin-bottom: 20px;
}

.event-detail-popup .modal-content p,
#popup_radio {
    color: #0F172A;
    color: var(--ui-dark);
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 24px;
    word-break: break-all;
}
#popup_radio{
    margin-bottom: 0;
}
.event-detail-popup .modal-content p span,
#popup_radio {
    color: #202C40;
    color: var(--ui-text);
}

.event-detail-popup .btn-delete {
    background: #E22357;
    color: #fff;
}

.event-detail-popup .btn-delete:hover {
    background: transparent;
    color: #E22357;
}


/* ============================ Connect Calnder 2 ============================ */

.form-col.connect-calnder2 .client-btn button {
    min-height: 180px;
    background: #F2F5F7;
    border-radius: 15px;
    border: none;
    font-size: 14px;
}

.form-col.connect-calnder2 .client-btn button img {
    display: block;
    width: 100%;
    max-width: 80px !important;
    position: unset;
    margin-bottom: 30px;
}

.page-content.book-event .main-row {
    padding-top: 0;
    min-height: auto;
}


/* book Meeting With Client */

.page-content.with-client [class*="book-event-"] {
    background: #fff;
    box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.06);
    border-radius: 15px;
    margin-bottom: 30px;
}

@media (min-width: 768px) {
    .page-content.with-client [class*="book-event-"] {
        min-height: 580px;
        margin-bottom: 0;
    }
}

.page-content.with-client [class*="book-event-"] .table-border {
    border-bottom: 0.3px solid rgba(2, 61, 77, 0.6);
}

.page-content.with-client .book-event-with {
    padding: 20px;
}

.page-content.with-client .profile-info img {
    width: 50px;
    height: 50px;
    margin-right: 10px;
    border-radius: 50%;
    object-fit: cover;
    object-position: center center;
}

.page-content.with-client .profile-info h2 {
    font-size: 16px;
}

.page-content.with-client .book-event-with span {
    font-size: 16px;
    color: #0F172A;
    color: var(--ui-dark);
}

.book-event-daytable h2 {
    padding: 15px 0;
}

.book-event-daytable .Calendar__sectionWrapper {
    min-height: 40em;
}

.book-event-daytable div.Calendar__weekDays .Calendar__weekDay {
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #678B94;
    font-weight: '500';
    font-weight: var(--ui-font-medium);
}

.book-event-daytable .Calendar__sectionWrapper .Calendar__weekRow {
    justify-content: space-between;
}

.book-event-daytable .Calendar__sectionWrapper .Calendar__day {
    border-radius: 10px !important;
    padding: 3px 5px 0;
    width: 35px;
    height: 35px;
    min-height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: '500';
    font-weight: var(--ui-font-medium);
    color: #0F172A !important;
    color: var(--ui-dark) !important;
    margin-bottom: 30px;
}

.book-event-daytable .Calendar__sectionWrapper .Calendar__day:not(.-blank,
.-disabled) {
    border: 1px solid #3DA371;
}

.book-event-daytable .Calendar__day.-today:not(.-selectedStart):not(.-selectedEnd):not(.-selectedBetween) {
    color: #3DA371 !important;
    font-weight: 500;
}

.book-event-daytable .Calendar__day.-today:not(.-selectedStart):not(.-selectedEnd):not(.-selectedBetween)::after {
    background: #3DA371;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    opacity: 1;
}

.book-event-daytable .Calendar__day.-selected,
.book-event-daytable .Calendar__day.-selectedStart,
.book-event-daytable .Calendar__day.-selectedEnd,
.book-event-daytable .Calendar__day:not(.-blank):not(.-selectedStart,
.-blank,
.-disabled):not(.-selectedEnd):not(.-selectedBetween):not(.-selected):hover {
    background: #3DA371;
    color: #fff !important;
    outline: 0;
}

.book-event-daytable .Calendar__section.-hiddenNext {
    opacity: 0.5;
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
}

.book-event-daytable .Calendar__section.-hiddenPrevious {
    opacity: 0.5;
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
}


/* .page-content.with-client .book-event-daytable h2 {
    padding: 20px 10px;
}

.page-content.with-client .book-event-daytable {
    overflow-x: auto;
}

.book-event-daytable .calender-month p {
    color: #678B94;
    font-weight: var(--ui-font-medium);
    line-height: 1.0;
    padding: 16px 0;
}

.book-event-daytable .calender-month p b {
    font-weight: var(--ui-font-bold);
    color: var(--ui-dark);
}

.book-event-daytable .calender-month p i {
    color: var(--ui-dark);
}

.book-event-daytable .calender-month p span {
    margin: 0 50px;
}

.book-event-daytable table {
    width: 100%;
    text-align: center;
}

.book-event-daytable table th {
    padding: 23px 10px;
    color: #678B94;
    font-weight: var(--ui-font-medium);
}

.book-event-daytable table td {
    padding: 21px 10px;
    font-weight: var(--ui-font-medium);
    color: var(--ui-dark);
}

.book-event-daytable table td span {
    border-radius: 10px;
    padding: 3px 5px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    margin: auto;
    cursor: pointer;
    position: relative;
}

.book-event-daytable table td span::before {
    position: absolute;
    content: '';
    background: #3DA371;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    display: none;
    transition: .3s all ease;
}

.book-event-daytable table td.not-current-date {
    color: #678B94;
}

.book-event-daytable table td.is-client-avail span {
    border: 1px solid #3DA371;
}

.book-event-daytable table td.selected span {
    background: #3DA371;
    color: #fff;
}

.book-event-daytable table td.current-date span {
    color: #3DA371;
}

.book-event-daytable table td.current-date span::before {
    transition: .3s all ease;
    display: inline-block;
} */

.page-content.with-client .book-event-timetable {
    max-height: 580px;
    overflow-y: auto;
}

.page-content.with-client .book-event-timetable::-webkit-scrollbar {
    display: none;
}


/* .page-content.with-client .book-event-timetable h2 {
    font-size: 16px;
    font-weight: var(--ui-font-medium);
    padding: 23px 10px;
} */

.book-event-timetable .city-time p {
    font-weight: '500';
    font-weight: var(--ui-font-medium);
    padding: 12px 0 12px 20px;
}

.book-event-timetable .city-time img {
    max-width: 17px;
    margin-right: 10px;
}

.book-event-timetable .time-selection {
    padding: 15px 5px 0;
}

.book-event-timetable .time-selection input {
    display: none;
}

.book-event-timetable .time-selection label {
    border: 1px solid rgba(2, 61, 77, 0.6);
    border-radius: 8px;
    color: #0F172A;
    color: var(--ui-dark);
    font-weight: '500';
    font-weight: var(--ui-font-medium);
    display: block;
    text-align: center;
    line-height: 1.0;
    padding: 18px 0 14px;
    margin-bottom: 12px;
    cursor: pointer;
}

.book-event-timetable .time-selection .col-6 {
    padding: 0 10px;
}

.book-event-timetable .time-selection input:checked+label {
    color: #fff;
    background: #0F172A;
    background: var(--ui-dark);
}

.event-confirm.modal p img {
    max-width: 17px;
}

.event-confirm.modal p i,
.event-confirm.modal p img {
    margin-right: 10px;
    display: inline-block;
}

.event-confirm.modal .event-confirm-form button {
    width: 100%;
}

.event-confirm-form button .spinner-border {
    width: 22px;
    height: 22px;
    margin-left: 12px;
}

@media (min-width: 768px) {
    .event-confirm.modal .event-confirm-form button {
        width: 48%;
    }
}

.event-confirm.modal .event-confirm-form {
    border-top: 0.3px solid rgba(2, 61, 77, 0.6);
    padding-top: 20px;
}

.event-confirm-form input {
    height: 50px;
    color: #0F172A;
    color: var(--ui-dark);
}

.event-confirm-form button.btn-delete {
    background: transparent;
    color: #E22357;
}

.event-confirm-form button.btn-delete:hover {
    background: #E22357;
    color: #fff;
}

.event-confirm-form button.btn-edit {
    background: #1155ED;
    background: var(--ui-green);
    color: #fff;
}

.event-confirm-form button.btn-edit:hover {
    color: #1155ED;
    color: var(--ui-green);
    background: transparent;
}

.Calendar {
    width: 100% !important;
}

@media (max-width: 767px) {
    .event-detail-popup .modal-content {
        padding: 30px;
    }
    .event-detail-popup .modal-content h2 {
        font-size: 28px;
    }
    .event-confirm-form button.btn-delete {
        margin-bottom: 20px;
    }
    .event-detail-popup .modal-content p {
        font-size: 14px;
    }
}

.form-col [class*='jsx-'],
.book-event-timetable [class*="jsx-"] {
    font-family: 'Red Hat Display', sans-serif;
    margin: 0;
}

.form-col [class*='jsx-'] input,
.book-event-timetable [class*="jsx-"] input {
    width: 100%;
    background: #fff;
    color: #0F172A;
    z-index: 2;
    font-size: 16px;
    height: 50px;
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-radius: 4px;
}

.book-event-timetable [class*="jsx-"] input {
    border-radius: 8px;
    border-color: rgba(2, 61, 77, 0.6);
}

.form-col [class*='jsx-'] ul,
.book-event-timetable [class*="jsx-"] ul {
    z-index: 99;
    background: #fff;
}

.form-col [class*='jsx-']::-webkit-input-placeholder {
    color: #94A3B8;
    color: var(--ui-grey-400);
}

.form-col [class*='jsx-']:-ms-input-placeholder {
    color: #94A3B8;
    color: var(--ui-grey-400);
}

.form-col [class*='jsx-']::placeholder {
    color: #94A3B8;
    color: var(--ui-grey-400);
}

[type="submit"]+fieldset {
    top: 0;
}

[type="submit"]+fieldset legend {
    display: none;
}

.input-icon input {
    height: 50px;
}

.input-icon input:focus {
    box-shadow: none;
    border: 2px solid #1155ED;
    border: 2px solid var(--ui-green);
}

.input-icon input:focus+label {
    color: #1155ED;
    color: var(--ui-green);
}


/*================================================================================================
             Appoitly Landing
=================================================================================================
 */


/* Home Page */


/*** 

====================================================================
  Loading Transition
====================================================================

 ***/

.ctn-preloader {
    align-items: center;
    -webkit-align-items: center;
    display: flex;
    display: -ms-flexbox;
    height: 100%;
    justify-content: center;
    -webkit-justify-content: center;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 999999;
    background: #fff;
}

.ctn-preloader .animation-preloader {
    position: absolute;
    z-index: 100;
}


/* Spinner cargando */

.ctn-preloader .animation-preloader .spinner {
    -webkit-animation: spinner 1.5s infinite linear;
            animation: spinner 1.5s infinite linear;
    border-radius: 50%;
    border: 3px solid rgb(17 85 237 / 6%);
    border-top-color: #1155ED;
    border-top-color: var(--brand-color);
    border-bottom-color: #1155ED;
    border-bottom-color: var(--brand-color);
    height: 170px;
    margin: 0 auto 45px auto;
    width: 170px;
}


/* Texto cargando */

.ctn-preloader .animation-preloader .txt-loading {
    text-align: center;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.ctn-preloader .animation-preloader .txt-loading .letters-loading:before {
    -webkit-animation: letters-loading 4s infinite;
            animation: letters-loading 4s infinite;
    color: #1155ED;
    color: var(--brand-color);
    content: attr(data-text-preloader);
    left: 0;
    opacity: 0;
    top: 0;
    line-height: 70px;
    position: absolute;
}

.ctn-preloader .animation-preloader .txt-loading .letters-loading {
    font-weight: 500;
    letter-spacing: 15px;
    display: inline-block;
    color: rgb(17 85 237 / 14%);
    position: relative;
    font-size: 70px;
    line-height: 70px;
}

.ctn-preloader .animation-preloader .txt-loading .letters-loading:nth-child(2):before {
    -webkit-animation-delay: 0.2s;
            animation-delay: 0.2s;
}

.ctn-preloader .animation-preloader .txt-loading .letters-loading:nth-child(3):before {
    -webkit-animation-delay: 0.4s;
            animation-delay: 0.4s;
}

.ctn-preloader .animation-preloader .txt-loading .letters-loading:nth-child(4):before {
    -webkit-animation-delay: 0.6s;
            animation-delay: 0.6s;
}

.ctn-preloader .animation-preloader .txt-loading .letters-loading:nth-child(5):before {
    -webkit-animation-delay: 0.8s;
            animation-delay: 0.8s;
}

.ctn-preloader .animation-preloader .txt-loading .letters-loading:nth-child(6):before {
    -webkit-animation-delay: 1s;
            animation-delay: 1s;
}

.ctn-preloader .animation-preloader .txt-loading .letters-loading:nth-child(7):before {
    -webkit-animation-delay: 1.2s;
            animation-delay: 1.2s;
}

.ctn-preloader .animation-preloader .txt-loading .letters-loading:nth-child(8):before {
    -webkit-animation-delay: 1.4s;
            animation-delay: 1.4s;
}

.ctn-preloader .loader-section {
    background-color: #ffffff;
    height: 100%;
    position: fixed;
    top: 0;
    width: calc(50% + 1px);
}

.loaded .animation-preloader {
    opacity: 0;
    transition: 0.3s ease-out;
}


/* Animación del preloader */

@-webkit-keyframes spinner {
    to {
        -webkit-transform: rotateZ(360deg);
                transform: rotateZ(360deg);
    }
}

@keyframes spinner {
    to {
        -webkit-transform: rotateZ(360deg);
                transform: rotateZ(360deg);
    }
}

@-webkit-keyframes letters-loading {
    0%,
    75%,
    100% {
        opacity: 0;
        -webkit-transform: rotateY(-90deg);
                transform: rotateY(-90deg);
    }
    25%,
    50% {
        opacity: 1;
        -webkit-transform: rotateY(0deg);
                transform: rotateY(0deg);
    }
}

@keyframes letters-loading {
    0%,
    75%,
    100% {
        opacity: 0;
        -webkit-transform: rotateY(-90deg);
                transform: rotateY(-90deg);
    }
    25%,
    50% {
        opacity: 1;
        -webkit-transform: rotateY(0deg);
                transform: rotateY(0deg);
    }
}

@media screen and (max-width: 767px) {
    .ctn-preloader .animation-preloader .spinner {
        height: 8em;
        width: 8em;
    }
}

@media screen and (max-width: 500px) {
    .ctn-preloader .animation-preloader .spinner {
        height: 7em;
        width: 7em;
    }
    .ctn-preloader .animation-preloader .txt-loading .letters-loading {
        font-size: 40px;
        letter-spacing: 10px;
    }
}


/*** 

====================================================================
  Scroll to top
====================================================================

 ***/

.scroll-top {
    width: 35px;
    height: 35px;
    line-height: 35px;
    position: fixed;
    bottom: 20px;
    right: 5px;
    z-index: 99;
    text-align: center;
    color: #fff;
    font-size: 18px;
    cursor: pointer;
    border-radius: 50%;
    background: #000;
    display: none;
    transition: all 0.3s ease-in-out;
}

.scroll-top:after {
    position: absolute;
    z-index: -1;
    content: '';
    top: 100%;
    left: 5%;
    height: 10px;
    width: 90%;
    opacity: 1;
    background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0) 80%);
}


/* Header */

.theme-menu-four ul,
.theme-menu-four li {
    list-style: none;
}

.theme-main-menu {
    position: fixed;
    z-index: 99;
    top: 0;
    left: 0;
    right: 0;
    background: #fff;
    padding: 30px 70px;
    transition: all 0.4s ease-out;
}

.theme-main-menu.bg-none {
    background: transparent;
}

.theme-main-menu>div {
    position: relative;
}

.theme-main-menu .logo a {
    display: block;
}

.theme-menu-four .logo img {
    max-width: 120px;
}

@media (min-width: 1200px) {
    .theme-menu-four .logo img {
        max-width: 165px;
    }
}

.theme-main-menu.sticky-menu.fixed {
    z-index: 9999;
    padding-top: 0;
    padding-bottom: 0;
    background: #fff;
    box-shadow: 0 13px 35px -12px rgb(35 35 35 / 10%);
}

.theme-main-menu.sticky-menu.fixed.theme-menu-four {
    background: #fff;
}

.theme-menu-four .navbar {
    padding: 0;
    z-index: 1;
    transition: 0.3s linear all;
}

@media screen and (min-width: 992px) {
    .theme-menu-four .navbar {
        padding-top: 0px;
        padding-bottom: 0px;
    }
}

#mega-menu-holder {
    position: static;
}

.theme-menu-four .navbar .navbar-toggler {
    float: right;
    font-size: 26px;
    padding: 5px 8px;
    margin: 0;
    color: #fff;
    background: #2a2a2a;
    box-shadow: 0 15px 20px 0px rgb(0 0 0 / 5%);
}

.theme-menu-four .navbar .navbar-toggler {
    background: #1155ED;
    background: var(--brand-color);
}

.theme-menu-four .navbar-nav .nav-item a {
    padding: 30px 0;
    margin: 0 15px;
    display: block;
    color: #2B2C2E;
    font-size: 18px;
    text-transform: capitalize;
    position: relative;
    transition: all 0.3s ease-in-out;
}

.theme-menu-four .navbar-nav .nav-item a[aria-current="page"],
.theme-menu-four .navbar-nav .nav-item:hover>a {
    color: #1155ED;
    color: var(--brand-color);
}

.theme-menu-four .navbar-nav .nav-item a[aria-current="page"] {
    font-weight: 600;
}

@media (min-width: 992px) {
    .theme-menu-four .right-button-group {
        margin-left: 100px;
    }
}

.theme-menu-four .right-button-group .signIn-action {
    font-weight: 500;
    font-size: 18px;
    line-height: 45px;
    color: #050505;
    color: var(--heading);
    margin-right: 40px;
    transition: all 0.3s ease-in-out;
}

.theme-menu-four .right-button-group .signIn-action:hover {
    color: #1155ED;
    color: var(--brand-color);
}

body .theme-btn-five {
    line-height: 56px;
    padding: 0 35px;
    text-align: center;
    /* border: 2px solid var(--brand-color); */
    border-radius: 6px;
    color: #fff;
    background: #1155ED;
    background: var(--brand-color);
    position: relative;
    transition: .3s all ease;
    z-index: 1;
    overflow: hidden;
}

body .theme-btn-five:before,
.fancy-short-banner-five form button::before,
.fancy-short-banner-three .form-wrapper form button::before,
body .theme-btn-two::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: #082A66;
    background: var(--purple-blue);
    z-index: -1;
    transition: all 0.3s ease-in-out;
    -webkit-transform: scale(0, 1);
            transform: scale(0, 1);
    -webkit-transform-origin: 0 100%;
            transform-origin: 0 100%;
}

body .theme-btn-five:hover:before,
.fancy-short-banner-five form button:hover::before,
.fancy-short-banner-three .form-wrapper form button:hover::before,
body .theme-btn-two:hover::before {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
}


/* body .theme-btn-five:hover {
    background: var(--brand-color);
    color: #fff;
    transition: .3s all ease;
} */

body .theme-main-menu .theme-btn-five,
.hero-banner-four a {
    background: #1155ED;
    background: var(--brand-color);
    color: #fff;
}

body .theme-main-menu .theme-btn-five:hover,
.hero-banner-four a:hover {
    border-color: #082A66;
    border-color: var(--purple-blue);
    /* background: var(--purple-blue); */
}

@media screen and (max-width: 991px) {
    .theme-menu-four .navbar-collapse {
        position: absolute;
        top: 100%;
        background-color: rgb(224, 240, 254);
        left: 0px;
        right: 0px;
        max-height: 70vh;
        overflow-y: auto;
        box-shadow: 0px 40px 50px 0px rgb(137 139 142 / 15%);
        padding-bottom: 20px;
    }
    .theme-main-menu.sticky-menu.fixed.theme-menu-four .navbar-collapse {
        background: #fff;
    }
    .theme-menu-four .navbar-collapse::-webkit-scrollbar {
        display: none;
    }
    .theme-menu-four .navbar-collapse .navbar-nav {
        align-items: center;
    }
    .theme-menu-four .navbar .navbar-collapse .navbar-nav .nav-item a {
        padding: 15px;
        font-size: 18px;
        border-top: 1px solid #ececec;
        margin: 0;
        text-align: center;
    }
    .hero-banner-four h1 .theme-menu-four .right-button-group {
        margin: 0 auto !important;
        padding: 15px 0;
    }
}


/* Hero Section */

.hero-banner-four {
    position: relative;
    background: rgb(224, 240, 254);
    padding: 250px 0 110px;
    z-index: 1;
}

.hero-banner-four:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 37px;
    left: 0;
    bottom: -33px;
    background: url(/static/media/116.7094817e.svg) no-repeat center bottom;
    background-size: 100% 100%;
}

.shapes {
    position: absolute;
    z-index: -1;
}

.hero-banner-four .shape-four {
    top: 1%;
    left: 15%;
}

.hero-banner-four .shape-five {
    top: 11%;
    right: 31%;
}

.hero-banner-four .shape-six {
    bottom: 5%;
    left: 0;
}

.hero-banner-four .shape-seven {
    bottom: 8%;
    right: 0;
}

.illustration-holder {
    position: relative;
}

.hero-banner-four .illustration {
    max-width: 660px;
}

.hero-banner-four .illustration-holder .shape-one {
    width: 14%;
    top: 9%;
    right: 8%;
    z-index: 1;
    -webkit-animation: jump10p 5s infinite linear;
            animation: jump10p 5s infinite linear;
}

.hero-banner-four .illustration-holder .shape-two {
    width: 14%;
    top: 0;
    right: 30%;
    z-index: 1;
    -webkit-animation: jump10pRsv 5s infinite linear;
            animation: jump10pRsv 5s infinite linear;
}

.hero-banner-four .illustration-holder .shape-three {
    width: 15%;
    top: 9%;
    right: 51%;
    z-index: 1;
    -webkit-animation: jump10p 5s infinite linear;
            animation: jump10p 5s infinite linear;
}

.hero-banner-four .review-text {
    text-align: right;
    font-size: 20px;
    color: #000;
    padding-top: 30px;
}

.hero-banner-four .review-text span {
    font-size: 1.6em;
    color: #082A66;
    color: var(--purple-blue);
    text-decoration: underline;
}

.hero-banner-four h1 {
    font-size: 72px;
    line-height: 1.14em;
}

.hero-banner-four h1 span {
    position: relative;
    z-index: 5;
}

.hero-banner-four h1 span:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 25px;
    background: rgba(186, 211, 255, 0.8);
    left: 0;
    bottom: 12px;
    z-index: -1;
}

.hero-banner-four .sub-text {
    font-size: 28px;
    color: #565657;
    line-height: 1.4em;
    padding: 50px 0 65px;
}

.hero-banner-four a {
    font-weight: 500;
}


/* Fancy Feature Section */

.fancy-feature-eight .bg-wrapper {
    position: relative;
    z-index: 5;
    background: #fff;
    padding: 35px 0 42px;
}

.fancy-feature-eight .bg-wrapper:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 24px;
    background: url(/static/media/97.3fc2c0a8.svg) no-repeat center top;
    left: 0;
    top: -20px;
}

.fancy-feature-eight .bg-wrapper:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 13px;
    background: url(/static/media/98.b3544d45.svg) no-repeat center bottom;
    left: 0;
    bottom: -10px;
}

.block-style-thirteen {
    position: relative;
    text-align: center;
    padding: 32px 40px 20px;
}


/* .block-style-thirteen .icon {
    height: 70px;
} */

.block-style-thirteen .icon img {
    margin: 0 auto;
    max-height: 100%;
}

.block-style-thirteen .title {
    font-size: 18px;
    color: rgba(0, 0, 0, 0.3);
    padding: 15px 0 20px;
}

.block-style-thirteen p {
    font-size: 24px;
    line-height: 1.45em;
    color: #000;
}

.block-style-thirteen.style-border {
    border-left: 1px solid #E7E7E7;
    border-right: 1px solid #E7E7E7;
}


/* Fancy Text Block */

.fancy-text-block-nineteen .text-wrapper .client-info {
    font-size: 24px;
    color: #A09A92;
    padding-bottom: 20px;
}

.fancy-text-block-nineteen .text-wrapper .client-info span {
    color: #000;
    text-decoration: underline;
}

.title-style-five h2 {
    font-size: 54px;
    line-height: 1.25em;
    font-weight: 500;
}

.title-style-five h2 span {
    position: relative;
    z-index: 1;
}

.title-style-five h2 span:before {
    content: '';
    width: 100%;
    height: 15px;
    position: absolute;
    background: rgba(186, 211, 255, 0.8);
    left: 0;
    bottom: 12px;
    z-index: -1;
}

.fancy-feature-nine .title-style-five h2 span:before {
    display: none;
}

.fancy-text-block-nineteen .text-wrapper p {
    font-size: 24px;
    color: #0E0E0E;
    line-height: 1.58em;
    padding-top: 40px;
}

.fancy-text-block-nineteen .text-wrapper .name {
    font-size: 24px;
    color: #000;
    position: relative;
    padding-left: 28px;
    margin-top: 50px;
}

.fancy-text-block-nineteen .text-wrapper .name:before {
    content: '';
    width: 18px;
    height: 3px;
    border-radius: 2px;
    background: #000;
    position: absolute;
    left: 0;
    top: 13px;
}


/* Fancy Feature Nine */

.fancy-feature-nine {
    position: relative;
    background: rgb(224, 240, 254);
    padding: 140px 0 135px;
    z-index: 5;
}

.fancy-feature-nine:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 52px;
    background: url(/static/media/105.ea4f2411.svg) no-repeat center top;
    background-size: cover;
    left: 0;
    top: -50px;
}

.fancy-feature-nine:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 37px;
    background: url(/static/media/116.7094817e.svg) no-repeat center bottom;
    background-size: 100% 100%;
    left: 0;
    bottom: -32px;
}

.fancy-feature-nine .shape-one {
    top: 7%;
    right: 13%;
    -webkit-animation: jumpThree 5s infinite linear;
            animation: jumpThree 5s infinite linear;
}

.fancy-feature-nine .shape-two {
    top: 45%;
    right: 5%;
    -webkit-animation: jumpTwo 5s infinite linear;
            animation: jumpTwo 5s infinite linear;
}

.fancy-feature-nine .shape-three {
    top: 88%;
    right: 10%;
    -webkit-animation: jumpThree 5s infinite linear;
            animation: jumpThree 5s infinite linear;
}

.fancy-feature-nine .shape-four {
    top: 89%;
    left: 11%;
    -webkit-animation: jumpTwo 5s infinite linear;
            animation: jumpTwo 5s infinite linear;
}

.fancy-feature-nine .shape-five {
    top: 43%;
    left: 4%;
    -webkit-animation: jumpThree 5s infinite linear;
            animation: jumpThree 5s infinite linear;
}

.fancy-feature-nine .shape-six {
    top: 9%;
    left: 15%;
    -webkit-animation: jumpTwo 5s infinite linear;
            animation: jumpTwo 5s infinite linear;
}

.title-style-five h6,
.block-style-nine .text-wrapper h6 {
    font-size: 20px;
    color: rgba(0, 0, 0, 0.35);
    text-transform: uppercase;
    letter-spacing: 1px;
    padding-bottom: 12px;
}

.block-style-fourteen {
    position: relative;
    padding: 0 25px;
    margin-top: 55px;
    text-align: center;
}

.block-style-fourteen .illustration {
    height: 220px;
}

.block-style-fourteen .illustration img {
    margin: 0 auto;
    max-height: 100%;
}

.block-style-fourteen .title {
    font-size: 20px;
    color: rgba(0, 0, 0, 0.35);
    padding: 20px 0 15px;
}

.block-style-fourteen p {
    font-size: 23px;
    line-height: 1.52em;
    color: #000;
}

.block-style-fourteen.arrow-shape:before,
.block-style-fourteen.arrow-shape:after {
    content: url(/static/media/104.815607a4.svg);
    position: absolute;
    top: 26%;
    z-index: 1;
}

.block-style-fourteen.arrow-shape:before {
    left: -20%;
}

.block-style-fourteen.arrow-shape:after {
    right: -20%;
}


/* Fancy Text Block Six */

.accordion-style-two .card {
    background: #fff;
    margin-bottom: 0;
    border-radius: 0;
    border: none;
    border-bottom: 2px solid #000;
}

.accordion-style-two .card .card-header {
    background: transparent;
    border-radius: 0;
    padding: 0;
    border: none;
}

.accordion-style-two .card .card-header button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    text-align: left;
    padding: 22px 70px 22px 0;
    font-size: 30px;
    line-height: 1.40em;
    border: none;
    border-radius: 0;
    margin: 0;
    color: #050505;
    color: var(--heading);
    text-decoration: none;
    position: relative;
    box-shadow: none;
}

.accordion-style-two .card .card-header button i {
    font-size: 22px;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin: auto;
    transition: .3s all ease;
}

.accordion-style-two .card .card-header button:not(.collapsed) i {
    -webkit-transform: rotate(-180deg);
            transform: rotate(-180deg);
    transition: .3s all ease;
}

.accordion-style-two .card .card-body {
    padding: 0 70px 30px 0;
}

.accordion-style-two .card .card-body p,
.accordion-style-two p {
    font-size: 20px;
    line-height: 1.55em;
}

.accordion-style-two h2 {
    font-size: 34px;
    font-weight: 500;
    line-height: 1.40em;
    margin-bottom: 15px;
}

.fancy-text-block-six .illustration-holder .shape-one {
    right: -20%;
    top: 29%;
    -webkit-animation: jumpThree 5s infinite linear;
            animation: jumpThree 5s infinite linear;
}

.fancy-text-block-six .illustration-holder img {
    -webkit-filter: drop-shadow(3px 3px 55px rgba(0, 0, 0, 0.1));
            filter: drop-shadow(3px 3px 55px rgba(0, 0, 0, 0.1));
}


/* 
=====================================================
	Pricing Section Three
=====================================================
*/

.pricing-section-three {
    background: rgb(224, 240, 254);
    position: relative;
    z-index: 1;
    padding: 105px 0 170px;
}

.pricing-section-three:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 52px;
    background: url(/static/media/105.ea4f2411.svg) no-repeat center top;
    background-size: 100% 100%;
    left: 0;
    top: -47px;
}

.pricing-section-three:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 33px;
    background: url(/static/media/116.7094817e.svg) no-repeat center bottom;
    background-size: 100% 100%;
    left: 0;
    bottom: -31px;
}

.pricing-section-three .shape-one {
    top: 7%;
    right: 13%;
    -webkit-animation: jumpThree 5s infinite linear;
            animation: jumpThree 5s infinite linear;
}

.pricing-section-three .shape-two {
    top: 45%;
    right: 5%;
    -webkit-animation: jumpTwo 5s infinite linear;
            animation: jumpTwo 5s infinite linear;
}

.pricing-section-three .shape-three {
    top: 88%;
    right: 10%;
    -webkit-animation: jumpThree 5s infinite linear;
            animation: jumpThree 5s infinite linear;
}

.pricing-section-three .shape-four {
    top: 89%;
    left: 11%;
    -webkit-animation: jumpTwo 5s infinite linear;
            animation: jumpTwo 5s infinite linear;
}

.pricing-section-three .shape-five {
    top: 43%;
    left: 4%;
    -webkit-animation: jumpThree 5s infinite linear;
            animation: jumpThree 5s infinite linear;
}

.pricing-section-three .shape-six {
    top: 9%;
    left: 15%;
    -webkit-animation: jumpTwo 5s infinite linear;
            animation: jumpTwo 5s infinite linear;
}

.pricing-section-three .pricing-table-area-three {
    position: relative;
}


/* .pricing-section-three .pricing-table-area-three:before {
    content: url(../images/landing/shape/117.svg);
    position: absolute;
    left: -5%;
    top: -5%;
    z-index: -1;
} */

.pricing-table-area-three .pr-table-wrapper {
    background: #fff;
    padding: 40px 20px 40px;
    text-align: center;
    margin-top: 45px;
    position: relative;
    transition: all 0.3s ease-in-out;
}

.pricing-table-area-three .pr-table-wrapper.skew-right:before {
    content: '';
    position: absolute;
    top: -8px;
    right: 0;
    width: 100%;
    height: 25px;
    background: #fff;
    -webkit-transform: skewY( 2.3deg);
            transform: skewY( 2.3deg);
}

.pricing-table-area-three .pr-table-wrapper .pack-name {
    font-size: 32px;
    color: #000;
}

.pricing-table-area-three .pr-table-wrapper .pack-name span {
    position: relative;
    z-index: 1;
}

.pricing-table-area-three .pr-table-wrapper .pack-name span:before {
    content: '';
    width: 109%;
    height: 15px;
    position: absolute;
    left: 50%;
    bottom: 5px;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    z-index: -1;
}

.pricing-table-area-three .pr-table-wrapper .pack-name.pc1 span:before {
    background: #D4FDF1;
}

.pricing-table-area-three .pr-table-wrapper .price {
    font-size: 65px;
    color: #000;
    padding: 24px 0 0;
    line-height: normal;
    line-height: initial;
}

.pricing-table-area-three .pr-table-wrapper .user-condition {
    font-size: 24px;
    color: #000;
}

.pricing-table-area-three .pr-table-wrapper .line {
    width: 100%;
    margin: 25px auto 38px;
}

.pricing-table-area-three .pr-table-wrapper ul {
    text-align: left;
    padding-left: 18px;
}

.pricing-table-area-three .pr-table-wrapper ul li {
    font-size: 17px;
    line-height: 42px;
    color: #464646;
    position: relative;
    padding-left: 30px;
}

.pricing-table-area-three .pr-table-wrapper ul li:before {
    content: url(/static/media/63.78ed43f3.svg);
    position: absolute;
    left: 0;
    top: 1px;
}

.pricing-table-area-three .pr-table-wrapper ul li.disable {
    color: #B9B9B9;
}

.pricing-table-area-three .pr-table-wrapper ul li.disable:before {
    content: url(/static/media/64.388c5a89.svg);
    top: 5px;
    left: -8px;
}

.pricing-table-area-three .pr-table-wrapper .subscribe-btn {
    display: block;
    width: 95%;
    margin: 37px auto 0;
    line-height: 46px;
    text-align: center;
    border: 2px solid #1155ED;
    border: 2px solid var(--brand-color);
    border-radius: 6px;
    color: #1155ED;
    color: var(--brand-color);
    transition: all 0.3s ease-in-out;
}

.pricing-table-area-three .pr-table-wrapper .subscribe-btn:hover {
    background: #1155ED;
    background: var(--brand-color);
    color: #fff;
}

.pricing-table-area-three .pr-table-wrapper.skew-left:before {
    content: '';
    position: absolute;
    top: -8px;
    right: 0;
    width: 100%;
    height: 25px;
    background: #fff;
    -webkit-transform: skewY( -2.3deg);
            transform: skewY( -2.3deg);
}

.pricing-table-area-three .pr-table-wrapper .pack-name.pc2 span:before {
    background: #DBF7FF;
}

.pricing-table-area-three .pr-table-wrapper .pack-name.pc3 span:before {
    background: #FFE3E3;
}

.pricing-table-area-three .pr-table-wrapper:hover {
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px);
}


/* =====================================================
				Client Feedback Slider Three
===================================================== */

.client-feedback-slider-three {
    position: relative;
    z-index: 5;
}

.clientSliderThree {
    text-align: center;
}

.clientSliderThree p {
    font-size: 28px;
    line-height: 1.71em;
    color: rgba(0, 0, 0, 0.8);
    padding: 25px 0 45px;
}

.clientSliderThree .name {
    font-weight: 500;
    font-size: 24px;
    color: #000;
}

.clientSliderThree .desig {
    color: #A8AFAE;
}

.slider-arrow {
    cursor: pointer;
    margin: 0 10px;
    font-size: 30px;
    color: #5D5D5D;
    transition: all 0.3s ease-in-out;
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    position: absolute;
    bottom: -50px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    left: 0;
    right: 0;
    margin: auto;
}

.slider-arrow:hover {
    -webkit-transform: scale(1);
            transform: scale(1);
}

.slider-arrow.slider-next {
    right: -50px;
}

.slider-arrow.slider-prev {
    left: -50px;
}

.client-feedback-slider-three .shape_1 {
    left: 18%;
    top: -3%;
}

.client-feedback-slider-three .shape_2 {
    left: 9%;
    top: 38%;
}

.client-feedback-slider-three .shape_3 {
    left: 13%;
    top: 85%;
}

.client-feedback-slider-three .shape_4 {
    right: 18%;
    top: -4%;
}

.client-feedback-slider-three .shape_5 {
    right: 4%;
    top: 33%;
}

.client-feedback-slider-three .shape_6 {
    right: 19%;
    top: 87%;
}


/* =====================================================
				Faq Section Four
===================================================== */

.faq-section-four {
    background: rgb(224, 240, 254);
    position: relative;
    z-index: 1;
    padding: 78px 0 150px;
}

.faq-section-four:before {
    content: '';
    position: absolute;
    width: 100%;
    /* height: 100px;
    background: url(../images/landing/shape/118.svg) no-repeat center top;
    background-size: cover;
    left: 0;
    top: -98px; */
    height: 52px;
    background: url(/static/media/105.ea4f2411.svg) no-repeat center top;
    background-size: 100% 100%;
    left: 0;
    top: -47px;
}

.faq-section-four:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 33px;
    background: url(/static/media/119.7094817e.svg) no-repeat center bottom;
    background-size: 100% 100%;
    left: 0;
    bottom: -31px;
}

.faq-section-four .shape-one {
    left: 0;
    top: 21%;
}

.faq-section-four .shape-two {
    right: 0;
    top: 75%;
}

.accordion-style-four .card {
    background: #fff;
    border-radius: 0;
    border: none;
    margin-bottom: 10px;
}

.accordion-style-four .card .card-header {
    background: transparent;
    border-radius: 0;
    padding: 0;
    border: none;
}

.accordion-style-four .card .card-header button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    text-align: left;
    padding: 27px 70px 27px 45px;
    font-size: 24px;
    border: none;
    border-radius: 0;
    margin: 0;
    color: #050505;
    color: var(--heading);
    text-decoration: none;
    position: relative;
    box-shadow: none;
}

.accordion-style-four .card .card-header button i {
    font-size: 22px;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 35px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin: auto;
    transition: .3s all ease;
}

.accordion-style-four .card .card-header button:not(.collapsed) i {
    -webkit-transform: rotate(-180deg);
            transform: rotate(-180deg);
    transition: .3s all ease;
}

@media (max-width: 767px) {
    .accordion-style-four .card .card-header button:before {
        font-size: 11px;
    }
}

.accordion-style-four .card .card-header button:before .accordion-style-four .card .card-body {
    padding: 0 50px 20px 45px;
}

.accordion-style-four .card .card-body p {
    padding-bottom: 15px;
}


/* =====================================================
				Fancy Short Banner Five
===================================================== */

.fancy-short-banner-five .sub-heading {
    font-size: 28px;
    line-height: 1.32em;
    color: #000;
    text-align: center;
    padding: 40px 0 55px;
}

.fancy-short-banner-five form {
    height: 70px;
    position: relative;
    margin-bottom: 17px;
}

.fancy-short-banner-five form input {
    width: 100%;
    height: 100%;
    border: 2px solid #000;
    padding: 0 170px 0 40px;
    color: #000;
    border-radius: 6px;
}

.fancy-short-banner-five form button {
    position: absolute;
    width: 160px;
    right: 8px;
    top: 8px;
    bottom: 8px;
    background: #1155ED;
    background: var(--brand-color);
    border-radius: 6px;
    color: #fff;
    font-size: 16px;
    transition: all 0.3s ease-in-out;
    overflow: hidden;
}

.fancy-short-banner-five form button::before {
    z-index: 1;
}

.fancy-short-banner-five form button span {
    position: relative;
    z-index: 1;
}

.fancy-short-banner-five .info-text {
    text-align: center;
    font-size: 16px;
    color: #999999;
}

.fancy-short-banner-five .info-text a {
    color: #000;
}

.fancy-short-banner-five .info-text a:hover {
    color: #1155ED;
    color: var(--brand-color);
}


/* =====================================================
    Footer
===================================================== */

.theme-footer-four {
    background: #1155ED;
    background: var(--ui-green);
    padding: 80px 0 70px;
    position: relative;
    z-index: 5;
}

.theme-footer-four:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 52px;
    background: url(/static/media/105-foooter.64b6864f.svg) no-repeat center top;
    background-size: 100% 100%;
    left: 0;
    top: -47px;
}

.theme-footer-four .footer-about-widget ul li a {
    font-size: 20px;
    color: #fff;
    margin-bottom: 5px;
    transition: all 0.2s ease-in-out;
}

.theme-footer-four .footer-about-widget ul li a {
    font-size: 20px;
    color: #fff;
    margin-bottom: 5px;
    transition: all 0.2s ease-in-out;
}

.theme-footer-four [class*="col-"] {
    margin-bottom: 35px;
}

.theme-footer-four .footer-title {
    font-size: 28px;
    color: #fff;
    position: relative;
    margin: 5px 0 21px;
}

.theme-footer-four .footer-list ul li a {
    color: #fff;
    line-height: 40px;
    transition: all 0.2s ease-in-out;
}

.theme-footer-four .footer-about-widget ul li a:hover,
.theme-footer-four .footer-list ul li a:hover {
    text-decoration: underline !important;
    color: #fff;
}

.theme-footer-four .bottom-footer-content p {
    font-size: 16px;
    color: #fff;
    margin-top: -70px;
}


/* =====================================================
    Contact Us Page
===================================================== */

.fancy-hero-four {
    position: relative;
    background: rgb(224, 240, 254);
    padding: 220px 0 320px;
    z-index: 5;
    text-align: center;
}

.fancy-hero-four h6 {
    font-size: 18px;
    letter-spacing: 1.8px;
    text-transform: uppercase;
    color: rgba(42, 42, 42, 0.4);
    padding-bottom: 25px;
}

.fancy-hero-four h2 {
    font-weight: 800;
    font-size: 80px;
    line-height: 1.1em;
}

.contact-style-two .contact-info-wrapper {
    position: relative;
    z-index: 5;
    margin-top: -195px;
}

.contact-style-two .contact-info-wrapper .address-info {
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 15px 30px rgb(0 0 0 / 4%);
    padding: 55px 15px 22px;
    text-align: center;
    margin-bottom: 35px;
    height: 100%;
    width: 100%;
}

.contact-style-two .contact-info-wrapper .address-info .icon {
    height: 82px;
}

.contact-style-two .contact-info-wrapper .address-info .icon img {
    margin: 0 auto;
    max-height: 100%;
}

.contact-style-two .contact-info-wrapper .address-info .title {
    font-size: 20px;
    color: #9E9E9E;
    padding: 17px 0 22px;
    position: relative;
    display: inline-block;
}

.contact-style-two .contact-info-wrapper .address-info .title:before {
    content: '';
    width: 100%;
    height: 3px;
    background: rgba(186, 211, 255, 0.8);
    border-radius: 2px;
    position: absolute;
    left: 0;
    bottom: 22px;
}

.contact-style-two .contact-info-wrapper .address-info p {
    font-size: 28px;
    line-height: 1.35em;
    color: #000;
}

.contact-style-two .contact-info-wrapper .address-info ul li a {
    font-size: 22px;
    margin: 5px 15px;
    color: rgba(0, 0, 0, 0.2);
    transition: all 0.2s ease-in-out;
}

.contact-style-two .contact-info-wrapper .address-info ul li a:hover {
    color: #1155ED;
    color: var(--brand-color);
}

.contact-style-two .contact-info-wrapper .shape-one {
    left: -7%;
    top: -18%;
    -webkit-animation: jumpTwo 5s infinite linear;
            animation: jumpTwo 5s infinite linear;
}


/* Contact Us form */

.form-style-classic .input-group-meta {
    height: 58px;
    position: relative;
}

.form-style-classic .input-group-meta label {
    font-size: 14px;
    font-weight: normal;
    color: #BFBFBF;
    position: absolute;
    left: 0;
    top: -20px;
    z-index: 1;
}

.form-style-classic .input-group-meta input {
    width: 100%;
    height: 100%;
    font-size: 23px;
    border: none;
    border-bottom: solid 2px #000;
    color: #050505;
    color: var(--heading);
    background: transparent;
}

#contact-form .form-group .help-block {
    position: absolute;
    left: 0;
    bottom: -24px;
    font-size: 14px;
    line-height: 22px;
    color: #fff;
    padding: 0 15px;
    border-radius: 3px;
    background: #dc3545;
    box-shadow: 0px 10px 25px 0px rgb(123 147 171 / 15%);
}

.form-style-classic .input-group-meta.lg {
    height: 200px;
}

.form-style-classic .input-group-meta textarea {
    width: 100%;
    height: 100%;
    font-size: 23px;
    color: #050505;
    color: var(--heading);
    border: none;
    border-bottom: solid 2px #000;
    resize: none;
    padding: 20px 0;
    overflow: auto;
}

body .theme-btn-two {
    font-weight: 600;
    font-size: 18px;
    color: #fff;
    line-height: 48px;
    background: #1155ED;
    background: var(--brand-color);
    padding: 0 42px;
    border-radius: 5px;
    transition: all 0.3s ease-in-out;
    overflow: hidden;
    position: relative;
    z-index: 1;
}


/* body .theme-btn-two:hover {
    background: var(--purple-blue);
    border-color: var(--purple-blue);
} */


/* =====================================================
    About Us Page
===================================================== */

.fancy-text-block-twenty {
    position: relative;
    padding: 215px 0 100px;
    z-index: 5;
}

.fancy-text-block-twenty:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 36%;
    background: url(/static/media/123.e217d7d7.svg) no-repeat center bottom;
    top: 0;
    left: 0;
    z-index: -1;
    background-size: cover;
}

.fancy-text-block-twenty .shape-one {
    left: 0;
    top: 19%;
}

.fancy-text-block-twenty .shape-two {
    right: 0;
    top: 19%;
}

.fancy-text-block-twenty .title {
    text-align: center;
    font-size: 72px;
    padding-bottom: 130px;
}

.fancy-video-box-one {
    position: relative;
}

.fancy-video-box-one .main-img {
    width: 100%;
}

.fancy-video-box-one .video-button {
    position: absolute;
    width: 100px;
    height: 100px;
    background: #fff;
    border-radius: 50%;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    z-index: 1;
    padding-left: 8px;
}

.fancy-text-block-twenty .client-info {
    font-size: 24px;
    color: #A09A92;
    padding-bottom: 20px;
}

.fancy-text-block-twenty .client-info span {
    color: #000;
    text-decoration: underline;
}

.fancy-text-block-twenty .nav-tabs {
    border: none;
}

.fancy-text-block-twenty .nav-tabs li {
    margin: 0 82px 0 0;
}

.fancy-text-block-twenty .nav-tabs li:last-child {
    margin-right: 0;
}

.fancy-text-block-twenty .nav-tabs li a {
    font-size: 22px;
    color: #969696;
    padding: 0;
    border: none;
}

.fancy-text-block-twenty .nav-tabs li a.active {
    color: #000;
    text-decoration: underline !important;
}

.fancy-text-block-twenty .tab-pane p {
    font-size: 20px;
    line-height: 1.9em;
    color: #000;
    padding-top: 35px;
}


/* =====================================================
				Counter With Icon One
===================================================== */

.counter-box-three {
    text-align: center;
    margin-top: 40px;
}

.counter-box-three .icon {
    height: 62px;
    display: inline-block;
}

.counter-box-three .number {
    font-size: 38px;
    padding: 5px 0 3px;
}

.counter-box-three p {
    font-weight: 300;
    font-size: 24px;
    color: #000;
}


/* =============================================
				Team Section
==============================================  */

.team-section-four {
    background: rgb(224, 240, 254);
    position: relative;
    padding: 100px 0 60px;
    z-index: 1;
}


/* .team-section-four:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 70px;
    background: url(../images/landing/shape/127.svg) no-repeat center top;
    background-size: cover;
    left: 0;
    top: -58px;
} */

.team-section-four:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 65px;
    background: url(/static/media/128.5c12c34b.svg) no-repeat center bottom;
    background-size: cover;
    left: 0;
    bottom: -63px;
}

.team-section-four .shape-one {
    right: 0;
    top: 5%;
}

.team-section-four .shape-two {
    left: 0;
    bottom: -3%;
}

.team-section-four .team-wrapper {
    position: relative;
    z-index: 5;
}

.team-section-four .team-member {
    margin-bottom: 60px;
    text-align: center;
    background: #fff;
    border-radius: 10px;
    overflow: hidden;
    padding-bottom: 30px;
    cursor: pointer;
}

.team-section-four .team-member .img-holder {
    overflow: hidden;
    border-radius: 10px 10px 0 0;
    margin-bottom: 30px;
}

.team-section-four .team-member .img-holder img {
    width: 100%;
    transition: all 0.5s ease-in-out;
}

.team-section-four .team-member:hover .img-holder img {
    -webkit-transform: scale3d(1.1, 1.1, 1);
            transform: scale3d(1.1, 1.1, 1);
}

.team-section-four .team-member .name {
    font-size: 24px;
}

.team-section-four .team-member .position {
    font-size: 16px;
    color: rgba(42, 42, 42, .4);
}

.team-section-four .team-wrapper .shape-one {
    top: -6%;
    left: -5%;
}


/* =====================================================
				Feature Blog Four
===================================================== */

.feature-blog-four {
    background: rgb(224, 240, 254);
    position: relative;
    z-index: 1;
    padding: 100px 0 100px;
}


/* .feature-blog-four:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 85px;
    background: url(../images/landing/shape/115.svg) no-repeat center top;
    background-size: cover;
    left: 0;
    top: -84px;
} */

.feature-blog-four:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 33px;
    background: url(/static/media/116.7094817e.svg) no-repeat center bottom;
    background-size: cover;
    left: 0;
    bottom: -32px;
}

.feature-blog-four .feature-article-meta {
    margin-bottom: 35px;
}

.feature-blog-four .feature-article-meta .img-meta {
    position: relative;
    overflow: hidden;
    margin-bottom: 40px;
}

.feature-blog-four .feature-article-meta .img-meta img {
    transition: all 0.5s ease-in-out;
    width: 100%;
}

.feature-blog-four .feature-article-meta:hover .img-meta img {
    -webkit-transform: scale3d(1.1, 1.1, 1);
            transform: scale3d(1.1, 1.1, 1);
}

.feature-blog-four .feature-article-meta .post-meta .author_info {
    font-size: 20px;
    color: #050505;
    color: var(--heading);
    margin-top: 25px;
}

.feature-blog-four .feature-article-meta .post-meta a {
    font-size: 30px;
    line-height: 1.3em;
    transition: all 0.3s ease-in-out;
}

.feature-blog-four .feature-article-meta:hover .post-meta a {
    color: #973C56;
    color: var(--vin-red);
}

.feature-blog-four .feature-article-meta .post-meta .author_info span {
    color: #B7B7B7;
}


/* =====================================================
		Our Features Page 
===================================================== */

.fancy-hero-three {
    background: rgb(224, 240, 254);
    position: relative;
    padding: 225px 0 180px;
    z-index: 5;
    text-align: center;
}

.fancy-hero-three:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 24px;
    background: url(/static/media/48.7e390e55.svg) no-repeat center;
    background-size: cover;
    left: 0;
    bottom: -20px;
}

.fancy-hero-three .shape-one {
    width: 6px;
    height: 6px;
    background: #F77A56;
    top: 29%;
    right: 27%;
    -webkit-animation: jumpThree 5s infinite linear;
            animation: jumpThree 5s infinite linear;
}

.fancy-hero-three .shape-two {
    width: 12px;
    height: 12px;
    background: #FFDBD0;
    top: 59%;
    right: 12%;
    -webkit-animation: jumpTwo 5s infinite linear;
            animation: jumpTwo 5s infinite linear;
}

.fancy-hero-three .shape-three {
    width: 8px;
    height: 8px;
    background: #F77A56;
    top: 84%;
    right: 25%;
    -webkit-animation: jumpThree 5s infinite linear;
            animation: jumpThree 5s infinite linear;
}

.fancy-hero-three .shape-four {
    width: 10px;
    height: 10px;
    background: #FFDBD0;
    top: 79%;
    left: 21%;
    -webkit-animation: jumpTwo 5s infinite linear;
            animation: jumpTwo 5s infinite linear;
}

.fancy-hero-three .shape-five {
    width: 6px;
    height: 6px;
    background: #F77A56;
    top: 50%;
    left: 12%;
    -webkit-animation: jumpThree 5s infinite linear;
            animation: jumpThree 5s infinite linear;
}

.fancy-hero-three .shape-six {
    width: 11px;
    height: 11px;
    background: #FF7052;
    top: 27%;
    left: 21%;
    -webkit-animation: jumpTwo 5s infinite linear;
            animation: jumpTwo 5s infinite linear;
}

.fancy-hero-three .heading {
    font-weight: 800;
    font-size: 80px;
    line-height: 1.10em;
    padding: 0 0 38px;
}

.fancy-hero-three .sub-heading {
    font-size: 22px;
    line-height: 1.41em;
    color: #2A2A2A;
}


/* =============================================
				Fancy Text block Eleven
============================================== */

.fancy-text-block-eleven .text-wrapper {
    padding: 50px 0 0 65px;
}

.title-style-two h2,
.title-style-one h2 {
    font-weight: 800;
    font-size: 51px;
    line-height: 1.15em;
}

.title-style-two span {
    position: relative;
}

.title-style-two span img {
    position: absolute;
    bottom: -30px;
    left: 0;
    z-index: -1;
}

.fancy-text-block-eleven .text-wrapper p {
    font-size: 22px;
    line-height: 1.81em;
}

.fancy-text-block-eleven .img-gallery {
    position: relative;
    display: inline-block;
}

.fancy-text-block-eleven .img-gallery .overlay-img {
    position: absolute;
    right: -130px;
    top: -70px;
    z-index: 1;
}

.fancy-text-block-eleven .img-gallery .shape-one {
    top: -13%;
    left: -16%;
}

.fancy-text-block-eleven .img-gallery .shape-two {
    bottom: -19%;
    right: -21%;
    -webkit-animation: rotatedTwo 50s infinite linear;
            animation: rotatedTwo 50s infinite linear;
}


/* =============================================
				Fancy Feature Six
============================================== */

.fancy-feature-six {
    padding: 0 15px;
}

.fancy-feature-six .bg-wrapper {
    max-width: 1440px;
    margin: 0 auto;
    padding: 110px 0 210px;
    background: url(/static/media/bg4.b871707b.svg) no-repeat top center;
    background-size: cover;
    position: relative;
    z-index: 5;
}

.fancy-feature-six .bg-wrapper .shape-one {
    width: 16px;
    height: 16px;
    background: #F96F60;
    z-index: 1;
    top: 5px;
    left: 14%;
    -webkit-animation: jumpThree 5s infinite linear;
            animation: jumpThree 5s infinite linear;
}

.fancy-feature-six .bg-wrapper .shape-two {
    width: 12px;
    height: 12px;
    background: #F96F60;
    top: 36%;
    right: 4%;
    -webkit-animation: jumpTwo 5s infinite linear;
            animation: jumpTwo 5s infinite linear;
}

.fancy-feature-six .bg-wrapper .shape-three {
    width: 7px;
    height: 7px;
    background: #F96F60;
    top: 68%;
    right: 4%;
    opacity: .25;
    -webkit-animation: jumpTwo 5s infinite linear;
            animation: jumpTwo 5s infinite linear;
}

.fancy-feature-six .bg-wrapper .shape-four {
    width: 10px;
    height: 10px;
    background: #F96F60;
    top: 91%;
    left: 7%;
    -webkit-animation: jumpThree 5s infinite linear;
            animation: jumpThree 5s infinite linear;
}

.fancy-feature-six .bg-wrapper .shape-five {
    width: 7px;
    height: 7px;
    background: #F96F60;
    top: 31%;
    left: 4%;
    opacity: .25;
    -webkit-animation: jumpTwo 5s infinite linear;
            animation: jumpTwo 5s infinite linear;
}

.fancy-feature-six .bg-wrapper .shape-six {
    top: 78%;
    right: 2%;
    -webkit-animation: rotatedTwo 50s infinite linear;
            animation: rotatedTwo 50s infinite linear;
}

.title-style-two .sub-text {
    font-size: 24px;
    line-height: 1.5em;
    color: #6D6D6D;
    padding-top: 15px;
}

.block-style-five {
    background: #fff;
    transition: all 0.3s ease-in-out;
    padding: 46px 30px 50px 48px;
    margin-top: 30px;
    border-radius: 5px;
    cursor: pointer;
}

.block-style-five:hover {
    box-shadow: 0px 30px 50px rgb(0 0 0 / 4%);
    -webkit-transform: translateY(-5px);
            transform: translateY(-5px);
}

.block-style-five .icon {
    height: 72px;
}

.block-style-five .icon img {
    max-height: 100%;
}

.block-style-five .title {
    font-weight: 800;
    font-size: 20px;
    margin: 24px 0 29px;
    display: inline-block;
    position: relative;
}

.block-style-five .title:before {
    content: '';
    width: 100%;
    height: 3px;
    background: rgba(186, 211, 255, 0.8);
    border-radius: 2px;
    position: absolute;
    left: 0;
    bottom: -2px;
}

.block-style-five p {
    font-size: 24px;
    line-height: 1.45em;
    color: #000;
}


/* =====================================================
				Fancy Text block Twelve
===================================================== */

.title-style-three h6 {
    font-size: 18px;
    color: #ADB1B5;
    padding-bottom: 15px;
}

.title-style-three h2 {
    font-weight: 800;
    font-size: 47px;
    line-height: 1.18em;
}

.title-style-three span {
    position: relative;
}

.title-style-three span img {
    position: absolute;
    bottom: -21px;
    left: 0;
    z-index: -1;
}

.fancy-text-block-twelve .text-wrapper .sub-text {
    font-size: 22px;
    line-height: 1.81em;
    padding-top: 40px;
}

.fancy-text-block-twelve .img-gallery {
    display: inline-block;
    padding: 30px 45px 0 25px;
    position: relative;
}

.fancy-text-block-twelve .img-gallery .shape-one {
    top: -7%;
    left: -10%;
}

.fancy-text-block-twelve .img-gallery .shape-two {
    bottom: -13%;
    right: -4%;
    -webkit-animation: jumpTwo 5s infinite linear;
            animation: jumpTwo 5s infinite linear;
}

.block-style-eight {
    background: #fff;
    transition: all 0.3s ease-in-out;
    padding: 20px 15px 25px 32px;
    margin-top: 30px;
    border: 2px solid #DCE4E8;
    border-radius: 5px;
}

.block-style-eight .title {
    font-weight: 800;
    font-size: 20px;
    margin: 0 0 24px;
    display: inline-block;
    position: relative;
}

.block-style-eight .title:before {
    content: '';
    width: 100%;
    height: 3px;
    background: rgba(186, 211, 255, 0.8);
    border-radius: 2px;
    position: absolute;
    left: 0;
    bottom: -2px;
}

.block-style-eight p {
    line-height: 1.72em;
    color: #000;
}

.block-style-eight:hover {
    border-color: #082A66;
    border-color: var(--purple-blue);
    background: #082A66;
    background: var(--purple-blue);
}

.block-style-eight:hover .title,
.block-style-eight:hover p {
    color: #fff;
}


/* =====================================================
				Useable Tools
===================================================== */

.useable-tools-section-two.bg-shape {
    padding: 0 15px;
}

.useable-tools-section-two.bg-shape .bg-wrapper {
    max-width: 1460px;
    margin: 0 auto;
    background: rgb(224, 240, 254);
    position: relative;
    padding: 108px 0 0;
    z-index: 1;
}

.useable-tools-section-two.bg-shape .bg-wrapper:before,
.team-section-four:before,
.feature-blog-four:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 15px;
    background-image: url(/static/media/37.c726899c.svg);
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    top: -13px;
    left: 0;
}

.useable-tools-section-two.bg-shape .bg-wrapper:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 24px;
    background-image: url(/static/media/38.c5347d20.svg);
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: cover;
    bottom: -23px;
    left: 0;
}

.useable-tools-section-two.bg-shape .bg-wrapper .shapes {
    z-index: 1;
}

.useable-tools-section-two.bg-shape .bg-wrapper .shape-one {
    width: 6px;
    height: 6px;
    background: #F2DAD5;
    top: 6%;
    left: 11%;
    -webkit-animation: jumpThree 5s infinite linear;
            animation: jumpThree 5s infinite linear;
}

.useable-tools-section-two.bg-shape .bg-wrapper .shape-two {
    width: 10px;
    height: 10px;
    background: #F77A56;
    top: 10%;
    right: 11%;
    -webkit-animation: jumpTwo 5s infinite linear;
            animation: jumpTwo 5s infinite linear;
}

.useable-tools-section-two.bg-shape .bg-wrapper .shape-three {
    width: 6px;
    height: 6px;
    background: #F2DAD5;
    top: 45%;
    right: 24%;
    -webkit-animation: jumpThree 5s infinite linear;
            animation: jumpThree 5s infinite linear;
}

.useable-tools-section-two.bg-shape .bg-wrapper .shape-four {
    width: 8px;
    height: 8px;
    background: #F77A56;
    top: 43%;
    left: 16%;
    -webkit-animation: jumpTwo 5s infinite linear;
            animation: jumpTwo 5s infinite linear;
}

.title-style-two p {
    text-transform: uppercase;
    font-size: 16px;
    color: #ADB1B5;
    letter-spacing: 1.12px;
    padding-bottom: 7px;
}

.useable-tools-section-two .icon-wrapper ul {
    margin: 0 -15px;
    position: relative;
    z-index: 1;
    -webkit-transform: translateY(58px);
            transform: translateY(58px);
}

.useable-tools-section-two .icon-wrapper ul li {
    padding: 0 15px;
    float: left;
    width: 11.11111%;
}

.useable-tools-section-two .icon-wrapper ul li:nth-child(odd) {
    margin-top: 50px;
}

.useable-tools-section-two .icon-wrapper ul li:nth-child(4),
.useable-tools-section-two .icon-wrapper ul li:nth-child(6) {
    margin-top: 110px;
}

.useable-tools-section-two .icon-wrapper ul li .icon-box {
    background: #fff;
    border-radius: 10px;
    height: 100px;
    margin: 20px 0;
    cursor: pointer;
}

.fancy-text-block-eleven .img-gallery .shape-three {
    bottom: -9%;
    left: -16%;
    -webkit-animation: jumpThree 5s infinite linear;
            animation: jumpThree 5s infinite linear;
}


/* =====================================================
				Fancy Short Banner Three
===================================================== */

.fancy-short-banner-three .bg-wrapper {
    background: url(/static/media/bg2.19a08be2.svg) no-repeat;
    background-size: cover;
    border-radius: 40px;
    padding: 82px 50px 89px;
    position: relative;
}


/* .fancy-short-banner-three .bg-wrapper:before {
    content: url(../images/landing/shape/43.svg);
    position: absolute;
    top: -124px;
    right: -104px;
    z-index: -1;
} */

.title-style-one h6 {
    font-size: 20px;
    color: #F96F60;
    padding-bottom: 15px;
}

.fancy-short-banner-three .title-style-one h2 {
    font-size: 42px;
}

@media (max-width: 1199px) {
    .fancy-short-banner-three .title-style-one h2 {
        font-size: 36px;
    }
}

.fancy-short-banner-three .form-wrapper {
    padding-left: 65px;
}

.fancy-short-banner-three .form-wrapper form {
    position: relative;
    height: 70px;
    background: #fff;
    border-radius: 7px;
}

.fancy-short-banner-three .form-wrapper form input {
    font-style: italic;
    font-size: 18px;
    width: 100%;
    border: none;
    height: 100%;
    padding: 0 150px 0 25px;
    background: transparent;
}

.fancy-short-banner-three .form-wrapper form button {
    position: absolute;
    right: 8px;
    bottom: 8px;
    top: 8px;
    width: 150px;
    border-radius: 6px;
    color: #fff;
    font-weight: 500;
    /* font-size: 16px;
    border: 2px solid var(--brand-color); */
    background: #1155ED;
    background: var(--brand-color);
    transition: all 0.3s ease-in-out;
    overflow: hidden;
}

.fancy-short-banner-three .form-wrapper form button span {
    position: relative;
    z-index: 1;
}

.fancy-short-banner-three .form-wrapper form button::before {
    z-index: 1;
}

.fancy-short-banner-three .form-wrapper p {
    font-size: 16px;
    padding-top: 10px;
}

.fancy-short-banner-three .form-wrapper p a {
    color: #000;
}


/* =============================================
		How it Works Page
============================================== */

.fancy-hero-three.bg-transparent::before {
    display: none;
}


/* =============================================
				Fancy Feature Seven
============================================== */

.fancy-feature-seven {
    background: #ECF6FF;
    padding: 170px 0 150px;
    position: relative;
    z-index: 1;
}

.fancy-feature-seven:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 45px;
    background: url(/static/media/58.ad8cdbcc.svg) no-repeat top center;
    background-size: cover;
    left: 0;
    top: -42px;
}

.fancy-feature-seven:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 23px;
    background: url(/static/media/59.f0db04b2.svg) no-repeat top center;
    background-size: cover;
    left: 0;
    bottom: -20px;
}

.block-style-nine {
    position: relative;
}

.block-style-nine:nth-child(1) {
    padding-bottom: 30px;
}

.block-style-nine:nth-child(1):before {
    content: url(/static/media/55.a8f5caa4.svg);
    position: absolute;
    left: 33%;
    bottom: -14%;
}

.block-style-nine:nth-child(2) {
    padding-bottom: 30px;
}

.block-style-nine:nth-child(2):before {
    content: url(/static/media/56.b0bb07b6.svg);
    position: absolute;
    right: 33%;
    bottom: -26%;
}

.block-style-nine:nth-child(3) {
    padding-bottom: 60px;
}

.block-style-nine:nth-child(3):before {
    content: url(/static/media/57.2bc50924.svg);
    position: absolute;
    left: 36%;
    bottom: -16%;
}

.block-style-nine .text-wrapper h6 {
    padding-bottom: 0;
}

.block-style-nine .text-wrapper .title {
    font-size: 38px;
    line-height: 1.28em;
    padding: 5px 0 30px;
}

.block-style-nine .text-wrapper p {
    font-size: 20px;
    line-height: 1.8em;
    color: rgba(14, 14, 14, 0.8);
}


/* =====================================================
		Fancy Text Block Thirteen
===================================================== */

.fancy-text-block-thirteen .text-wrapper p {
    font-size: 24px;
    line-height: 1.70em;
    padding-top: 45px;
}


/* =====================================================
				Fancy Text Block Fourteen
===================================================== */

.fancy-text-block-fourteen .text-wrapper .sub-text {
    font-size: 24px;
    line-height: 1.58em;
    color: #0E0E0E;
    padding-top: 40px;
}

.fancy-text-block-fourteen .text-wrapper .name {
    font-weight: 700;
    font-size: 24px;
    color: #000;
    position: relative;
    padding-left: 28px;
    margin-top: 40px;
}

.fancy-text-block-fourteen .text-wrapper .name:before {
    content: '';
    width: 18px;
    height: 3px;
    border-radius: 2px;
    background: #000;
    position: absolute;
    left: 0;
    top: 13px;
}

.fancy-text-block-fourteen .text-wrapper .name span {
    font-weight: 600;
    color: #AEB3B7;
}

.fancy-text-block-fourteen .img-holder {
    position: relative;
    padding: 0 50px 0 60px;
}

.fancy-text-block-fourteen .img-holder .shape-one {
    right: 0;
    top: -10%;
}

.fancy-text-block-fourteen .img-holder .shape-two {
    left: -1%;
    top: -12%;
    z-index: 1;
    -webkit-animation: jumpTwo 5s infinite linear;
            animation: jumpTwo 5s infinite linear;
}


/* =====================================================
		Pricing Page
===================================================== */

.pricing-section-three-inner-page {
    background: rgb(224, 240, 254);
    position: relative;
    z-index: 1;
    padding: 215px 0 110px;
}

.pricing-section-three-inner-page:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 33px;
    left: 0;
    bottom: -30px;
    background: url(/static/media/116.7094817e.svg) no-repeat center bottom;
    background-size: 100% 100%;
}

.pricing-section-three-inner-page .shape-one {
    left: 0;
    top: 19%;
}

.pricing-section-three-inner-page .shape-two {
    right: 0;
    top: 19%;
}


/* =====================================================
		FAQ Section
===================================================== */

.faq-section-four.bg-white::before,
.faq-section-four.bg-white::after {
    display: none;
}

.faq-section-four.bg-white .accordion-style-four .card {
    background: #F7F7F7;
}


/* =====================================================
		SignUp Section
===================================================== */

.fancy-short-banner-five.with-bg {
    position: relative;
    background: rgb(224, 240, 254);
    z-index: 1;
}

.fancy-short-banner-five.with-bg .shape-one {
    right: 5%;
    top: 20%;
}

.fancy-short-banner-five.with-bg .shape-two {
    left: 5%;
    top: 20%;
}


/* ===========================
		Privacy Policy Page
============================= */

section.policy {
    padding: 150px 0;
}

@media (max-width: 767px) {
    section.policy {
        padding: 120px 0;
    }
}

section.policy .section-title {
    margin-bottom: 40px;
}

section.terms .section-title p {
    font-size: 20px !important;
}

section.policy [class*="text-que-"] {
    margin-bottom: 30px;
}

section.policy [class*="text-que-"]:last-of-type {
    margin-bottom: 0;
}

[class*="text-que-"] h3 {
    margin-bottom: 15px;
    font-size: 24px;
}

[class*="text-que-"] p,
[class*="text-que-"] ul li {
    font-size: 18px;
}

[class*="text-que-"] ul li {
    margin-top: 10px;
}

.MuiCalendarPicker-root {
    width: 100% !important;
    max-height: unset !important;
}

.MuiCalendarPicker-root .css-1n2mv2k {
    justify-content: space-around;
}

.MuiCalendarPicker-root .css-dhopo2 {
    min-height: 480px;
}

.MuiCalendarPicker-root .PrivatePickersSlideTransition-root [role="row"] {
    justify-content: space-around;
    margin: 26px 0;
}

.MuiCalendarPicker-root .MuiTypography-caption {
    font-weight: 600;
    font-size: 14px;
    font-family: 'Red Hat Display', sans-serif;
    color: #202C40;
    color: var(--ui-text);
}

.MuiCalendarPicker-root .MuiPickersDay-root {
    width: 40px;
    height: 40px;
    font-size: 16px;
    font-family: 'Red Hat Display', sans-serif;
    border-radius: 10px;
}


/* Integration Responsive */

.connect-calnder2 h3,
.conference_apps h3 {
    font-size: 18px;
    margin-bottom: 4px;
}

.connect-calnder2 p,
.conference_apps p {
    font-size: 14px;
    line-height: 1.1 !important;
}

.connect-calnder2 .MuiFormControlLabel-root span {
    font-size: 14px;
}
*{margin:0;padding:0;box-sizing: border-box;}
html,body,div,span,applet,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,abbr,acronym,address,big,cite,code,dfn,em,img,ins,kbd,q,s,samp,small,strike,strong,sub,sup,tt,var,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td{vertical-align:baseline;text-decoration:none;margin:0;padding:0;}
ol,ul {list-style-type: none;}
a {text-decoration: none; display: inline-block; color: inherit;}
a:hover,a:focus,a:visited {text-decoration: none; outline: none;}
img {width: auto; max-width: 100%; display: block;}
button {border:none;outline: none;box-shadow: none;display: block; padding: 0;cursor: pointer;background: transparent;}
button:focus {outline: none;}
input,textarea {outline: none; box-shadow: none;transition: all 0.3s ease-in-out;}
audio,video,canvas {max-width: 100%;}
iframe {border: none !important;}

/* Some Seperate css */
::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-thumb {
  background: var(--brand-color);
  border-radius: 0;
}

::-webkit-scrollbar-track {
  background: #fff;
}

::selection {
  background-color: var(--brand-color);
  color: #fff;
}
body{
  font-size: 18px;
  position: relative;
  line-height: 1.7em;
  color: var(--text-color);
  background: transparent;
}
ol,
ul {
    list-style-type: none;
}

a {
    text-decoration: none !important;
    display: inline-block;
}

.h1,
h1,
.h2,
h2,
.h3,
h3,
.h4,
h4,
.h5,
h5,
.h6,
h6 {
    color: var(--heading);
    font-weight: normal;
}

.h1,
h1 {
    font-weight: 600;
}

.h2,
h2 {
    font-size: 52px;
    line-height: 1.1em;
}

.h3,
h3 {
    font-size: 36px;
}

.h5,
h5 {
    font-size: 20px;
}

.h6,
h6 {
    font-size: 15px;
}

.font-rubik {
    font-family: 'Montserrat', sans-serif;
}

.font-slab {
    font-family: 'Montserrat', sans-serif;
}

.font-gilroy-bold {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
}

.main-page-wrapper {
    overflow-x: hidden;
    padding-top: 150px;
}
/* Seperate Css End */

.p0 {padding: 0 !important;}
.m0 {margin:  0 !important;}


.pt-5   {padding-top: 5px !important;}
.pt-10  {padding-top: 10px;}
.pt-15  {padding-top: 15px;}
.pt-20  {padding-top: 20px;}
.pt-25  {padding-top: 25px;}
.pt-30  {padding-top: 30px;}
.pt-35  {padding-top: 35px;}
.pt-40  {padding-top: 40px;}
.pt-45  {padding-top: 45px;}
.pt-50  {padding-top: 50px;}
.pt-55  {padding-top: 55px;}
.pt-60  {padding-top: 60px;}
.pt-65  {padding-top: 65px;}
.pt-70  {padding-top: 70px;}
.pt-75  {padding-top: 75px;}
.pt-80  {padding-top: 80px;}
.pt-85  {padding-top: 85px;}
.pt-90  {padding-top: 90px;}
.pt-95  {padding-top: 95px;}
.pt-100 {padding-top: 100px;}
.pt-110 {padding-top: 110px;}
.pt-120 {padding-top: 120px;}
.pt-130 {padding-top: 130px;}
.pt-140 {padding-top: 140px;}
.pt-150 {padding-top: 150px;}
.pt-160 {padding-top: 160px;}
.pt-170 {padding-top: 170px;}
.pt-180 {padding-top: 180px;}
.pt-190 {padding-top: 190px;}
.pt-200 {padding-top: 200px;}
.pt-225 {padding-top: 225px;}
.pt-250 {padding-top: 250px;}
.pt-300 {padding-top: 300px;}
.pt-350 {padding-top: 350px;}

.pb-5   {padding-bottom: 5px !important;}
.pb-10  {padding-bottom: 10px;}
.pb-15  {padding-bottom: 15px;}
.pb-20  {padding-bottom: 20px;}
.pb-25  {padding-bottom: 25px;}
.pb-30  {padding-bottom: 30px;}
.pb-35  {padding-bottom: 35px;}
.pb-40  {padding-bottom: 40px;}
.pb-45  {padding-bottom: 45px;}
.pb-50  {padding-bottom: 50px;}
.pb-55  {padding-bottom: 55px;}
.pb-60  {padding-bottom: 60px;}
.pb-65  {padding-bottom: 65px;}
.pb-70  {padding-bottom: 70px;}
.pb-75  {padding-bottom: 75px;}
.pb-80  {padding-bottom: 80px;}
.pb-85  {padding-bottom: 85px;}
.pb-90  {padding-bottom: 90px;}
.pb-95  {padding-bottom: 95px;}
.pb-100 {padding-bottom: 100px;}
.pb-110 {padding-bottom: 110px;}
.pb-120 {padding-bottom: 120px;}
.pb-130 {padding-bottom: 130px;}
.pb-140 {padding-bottom: 140px;}
.pb-150 {padding-bottom: 150px;}
.pb-160 {padding-bottom: 160px;}
.pb-170 {padding-bottom: 170px;}
.pb-180 {padding-bottom: 180px;}
.pb-190 {padding-bottom: 190px;}
.pb-200 {padding-bottom: 200px;}
.pb-225 {padding-bottom: 225px;}
.pb-250 {padding-bottom: 250px;}
.pb-300 {padding-bottom: 300px;}
.pb-350 {padding-bottom: 350px;}

.mt-5   {margin-top: 5px !important;}
.mt-10  {margin-top: 10px;}
.mt-15  {margin-top: 15px;}
.mt-20  {margin-top: 20px;}
.mt-25  {margin-top: 25px;}
.mt-30  {margin-top: 30px;}
.mt-35  {margin-top: 35px;}
.mt-40  {margin-top: 40px;}
.mt-45  {margin-top: 45px;}
.mt-50  {margin-top: 50px;}
.mt-55  {margin-top: 55px;}
.mt-60  {margin-top: 60px;}
.mt-65  {margin-top: 65px;}
.mt-70  {margin-top: 70px;}
.mt-75  {margin-top: 75px;}
.mt-80  {margin-top: 80px;}
.mt-85  {margin-top: 85px;}
.mt-90  {margin-top: 90px;}
.mt-95  {margin-top: 95px;}
.mt-100 {margin-top: 100px;}
.mt-110 {margin-top: 110px;}
.mt-120 {margin-top: 120px;}
.mt-130 {margin-top: 130px;}
.mt-140 {margin-top: 140px;}
.mt-150 {margin-top: 150px;}
.mt-160 {margin-top: 160px;}
.mt-170 {margin-top: 170px;}
.mt-180 {margin-top: 180px;}
.mt-190 {margin-top: 190px;}
.mt-200 {margin-top: 200px;}
.mt-225 {margin-top: 225px;}
.mt-250 {margin-top: 250px;}
.mt-300 {margin-top: 300px;}
.mt-350 {margin-top: 350px;}


.mb-5   {margin-bottom: 5px !important;}
.mb-10  {margin-bottom: 10px;}
.mb-15  {margin-bottom: 15px;}
.mb-20  {margin-bottom: 20px;}
.mb-25  {margin-bottom: 25px;}
.mb-30  {margin-bottom: 30px;}
.mb-35  {margin-bottom: 35px;}
.mb-40  {margin-bottom: 40px;}
.mb-45  {margin-bottom: 45px;}
.mb-50  {margin-bottom: 50px;}
.mb-55  {margin-bottom: 55px;}
.mb-60  {margin-bottom: 60px;}
.mb-65  {margin-bottom: 65px;}
.mb-70  {margin-bottom: 70px;}
.mb-75  {margin-bottom: 75px;}
.mb-80  {margin-bottom: 80px;}
.mb-85  {margin-bottom: 85px;}
.mb-90  {margin-bottom: 90px;}
.mb-95  {margin-bottom: 95px;}
.mb-100 {margin-bottom: 100px;}
.mb-110 {margin-bottom: 110px;}
.mb-120 {margin-bottom: 120px;}
.mb-130 {margin-bottom: 130px;}
.mb-140 {margin-bottom: 140px;}
.mb-150 {margin-bottom: 150px;}
.mb-160 {margin-bottom: 160px;}
.mb-170 {margin-bottom: 170px;}
.mb-180 {margin-bottom: 180px;}
.mb-190 {margin-bottom: 190px;}
.mb-200 {margin-bottom: 200px;}
.mb-225 {margin-bottom: 225px;}
.mb-250 {margin-bottom: 250px;}
.mb-300 {margin-bottom: 300px;}
.mb-350 {margin-bottom: 350px;}




@media (max-width: 991px) {
  .md-p0 {padding: 0 !important;}
  .md-m0 {margin:  0 !important;}

  .md-pt-10  {padding-top: 10px !important;}
  .md-pt-20  {padding-top: 20px !important;}
  .md-pt-30  {padding-top: 30px !important;}
  .md-pt-40  {padding-top: 40px !important;}
  .md-pt-50  {padding-top: 50px !important;}
  .md-pt-60  {padding-top: 60px !important;}
  .md-pt-70  {padding-top: 70px !important;}
  .md-pt-80  {padding-top: 80px !important;}
  .md-pt-90  {padding-top: 90px !important;}
  .md-pt-100 {padding-top: 100px !important;}
  .md-pt-110 {padding-top: 110px !important;}
  .md-pt-120 {padding-top: 120px !important;}
  .md-pt-130 {padding-top: 130px !important;}
  .md-pt-140 {padding-top: 140px !important;}
  .md-pt-150 {padding-top: 150px !important;}
  .md-pt-200 {padding-top: 200px !important;}

  .md-pb-10  {padding-bottom: 10px !important;}
  .md-pb-20  {padding-bottom: 20px !important;}
  .md-pb-30  {padding-bottom: 30px !important;}
  .md-pb-40  {padding-bottom: 40px !important;}
  .md-pb-50  {padding-bottom: 50px !important;}
  .md-pb-60  {padding-bottom: 60px !important;}
  .md-pb-70  {padding-bottom: 70px !important;}
  .md-pb-80  {padding-bottom: 80px !important;}
  .md-pb-90  {padding-bottom: 90px !important;}
  .md-pb-100 {padding-bottom: 100px !important;}
  .md-pb-110 {padding-bottom: 110px !important;}
  .md-pb-120 {padding-bottom: 120px !important;}
  .md-pb-130 {padding-bottom: 130px !important;}
  .md-pb-140 {padding-bottom: 140px !important;}
  .md-pb-150 {padding-bottom: 150px !important;}
  .md-pb-200 {padding-bottom: 200px !important;}

  .md-mt-10  {margin-top: 10px !important;}
  .md-mt-20  {margin-top: 20px !important;}
  .md-mt-30  {margin-top: 30px !important;}
  .md-mt-40  {margin-top: 40px !important;}
  .md-mt-50  {margin-top: 50px !important;}
  .md-mt-60  {margin-top: 60px !important;}
  .md-mt-70  {margin-top: 70px !important;}
  .md-mt-80  {margin-top: 80px !important;}
  .md-mt-90  {margin-top: 90px !important;}
  .md-mt-100 {margin-top: 100px !important;}
  .md-mt-110 {margin-top: 110px !important;}
  .md-mt-120 {margin-top: 120px !important;}
  .md-mt-130 {margin-top: 130px !important;}
  .md-mt-140 {margin-top: 140px !important;}
  .md-mt-150 {margin-top: 150px !important;}
  .md-mt-200 {margin-top: 200px !important;}

  .md-mb-10  {margin-bottom: 10px !important;}
  .md-mb-20  {margin-bottom: 20px !important;}
  .md-mb-30  {margin-bottom: 30px !important;}
  .md-mb-40  {margin-bottom: 40px !important;}
  .md-mb-50  {margin-bottom: 50px !important;}
  .md-mb-60  {margin-bottom: 60px !important;}
  .md-mb-70  {margin-bottom: 70px !important;}
  .md-mb-80  {margin-bottom: 80px !important;}
  .md-mb-90  {margin-bottom: 90px !important;}
  .md-mb-100 {margin-bottom: 100px !important;}
  .md-mb-110 {margin-bottom: 110px !important;}
  .md-mb-120 {margin-bottom: 120px !important;}
  .md-mb-130 {margin-bottom: 130px !important;}
  .md-mb-140 {margin-bottom: 140px !important;}
  .md-mb-150 {margin-bottom: 150px !important;}
  .md-mb-200 {margin-bottom: 200px !important;}
} 
/*(max-width: 991px)*/

@media (max-width: 767px) {
  .sm-p0 {padding: 0 !important;}
  .sm-m0 {margin:  0 !important;}

  .sm-pt-10  {padding-top: 10px !important;}
  .sm-pt-20  {padding-top: 20px !important;}
  .sm-pt-30  {padding-top: 30px !important;}
  .sm-pt-40  {padding-top: 40px !important;}
  .sm-pt-50  {padding-top: 50px !important;}
  .sm-pt-60  {padding-top: 60px !important;}
  .sm-pt-70  {padding-top: 70px !important;}
  .sm-pt-80  {padding-top: 80px !important;}
  .sm-pt-90  {padding-top: 90px !important;}
  .sm-pt-100 {padding-top: 100px !important;}
  .sm-pt-110 {padding-top: 110px !important;}
  .sm-pt-120 {padding-top: 120px !important;}
  .sm-pt-130 {padding-top: 130px !important;}
  .sm-pt-140 {padding-top: 140px !important;}
  .sm-pt-150 {padding-top: 150px !important;}
  .sm-pt-200 {padding-top: 200px !important;}

  .sm-pb-10  {padding-bottom: 10px !important;}
  .sm-pb-20  {padding-bottom: 20px !important;}
  .sm-pb-30  {padding-bottom: 30px !important;}
  .sm-pb-40  {padding-bottom: 40px !important;}
  .sm-pb-50  {padding-bottom: 50px !important;}
  .sm-pb-60  {padding-bottom: 60px !important;}
  .sm-pb-70  {padding-bottom: 70px !important;}
  .sm-pb-80  {padding-bottom: 80px !important;}
  .sm-pb-90  {padding-bottom: 90px !important;}
  .sm-pb-100 {padding-bottom: 100px !important;}
  .sm-pb-110 {padding-bottom: 110px !important;}
  .sm-pb-120 {padding-bottom: 120px !important;}
  .sm-pb-130 {padding-bottom: 130px !important;}
  .sm-pb-140 {padding-bottom: 140px !important;}
  .sm-pb-150 {padding-bottom: 150px !important;}
  .sm-pb-200 {padding-bottom: 200px !important;}

  .sm-mt-10  {margin-top: 10px !important;}
  .sm-mt-20  {margin-top: 20px !important;}
  .sm-mt-30  {margin-top: 30px !important;}
  .sm-mt-40  {margin-top: 40px !important;}
  .sm-mt-50  {margin-top: 50px !important;}
  .sm-mt-60  {margin-top: 60px !important;}
  .sm-mt-70  {margin-top: 70px !important;}
  .sm-mt-80  {margin-top: 80px !important;}
  .sm-mt-90  {margin-top: 90px !important;}
  .sm-mt-100 {margin-top: 100px !important;}
  .sm-mt-110 {margin-top: 110px !important;}
  .sm-mt-120 {margin-top: 120px !important;}
  .sm-mt-130 {margin-top: 130px !important;}
  .sm-mt-140 {margin-top: 140px !important;}
  .sm-mt-150 {margin-top: 150px !important;}
  .sm-mt-200 {margin-top: 200px !important;}

  .sm-mb-10  {margin-bottom: 10px !important;}
  .sm-mb-20  {margin-bottom: 20px !important;}
  .sm-mb-30  {margin-bottom: 30px !important;}
  .sm-mb-40  {margin-bottom: 40px !important;}
  .sm-mb-50  {margin-bottom: 50px !important;}
  .sm-mb-60  {margin-bottom: 60px !important;}
  .sm-mb-70  {margin-bottom: 70px !important;}
  .sm-mb-80  {margin-bottom: 80px !important;}
  .sm-mb-90  {margin-bottom: 90px !important;}
  .sm-mb-100 {margin-bottom: 100px !important;}
  .sm-mb-110 {margin-bottom: 110px !important;}
  .sm-mb-120 {margin-bottom: 120px !important;}
  .sm-mb-130 {margin-bottom: 130px !important;}
  .sm-mb-140 {margin-bottom: 140px !important;}
  .sm-mb-150 {margin-bottom: 150px !important;}
  .sm-mb-200 {margin-bottom: 200px !important;}
}
/*(max-width: 767px)*/

@media (min-width: 1350px) {
    .lg-container .container {
        max-width: 1320px;
    }
}

@media (min-width: 1200px) {
    .container {
        max-width: 1170px;
    }
    .container-custom.container {
        max-width: 1200px;
    }
}

@media (min-width: 992px) {
    .container {
        padding-right: 0;
        padding-left: 0;
    }
}

@media (min-width: 1200px) and (max-width: 1450px) {
    .theme-main-menu {
        padding: 30px 40px;
    }
}

@media (max-width: 1199px) {
    .block-style-fourteen {
        padding: 0;
    }
    .accordion-style-two {
        padding-right: 0 !important;
    }
    .pricing-table-area-three .pr-table-wrapper ul {
        padding-left: 0;
    }
}


/*(max-width: 1199px)*/
@media (max-width: 1199px){
    .hero-banner-four:before,
    .fancy-feature-nine:after{
        bottom: -28px;
    }
    .pricing-section-three:before,
    .faq-section-four:before,
    .theme-footer-four:before{
        top: -40px;
    }
    .pricing-section-three:after,
    .pricing-section-three-inner-page:before{
        bottom: -25px;
    }
    .faq-section-four:after{
        bottom: -26px;
    }
}
@media (min-width: 992px) and (max-width: 1199px) {
    .theme-main-menu {
        padding: 30px 25px;
    }
    .hero-banner-four h1 {
        font-size: 70px;
    }
    .hero-banner-four .sub-text {
        font-size: 24px;
        padding: 45px 0 50px;
    }
    .hero-banner-four .illustration {
        max-width: 538px;
    }
    .block-style-thirteen p {
        font-size: 22px;
    }
    .block-style-thirteen {
        padding: 32px 25px 20px;
    }
    .title-style-five h2,
    .title-style-three h2 {
        font-size: 48px;
    }
    .title-style-one h2 {
        font-size: 50px;
    }
    .title-style-two h2 {
        font-size: 50px;
    }
    .title-style-three p {
        font-size: 18px;
    }
    .fancy-text-block-nineteen .text-wrapper .client-info,
    .fancy-text-block-twenty .client-info {
        font-size: 22px;
    }
    .fancy-text-block-twenty .nav-tabs li {
        margin-right: 55px;
    }
    .block-style-five {
        padding-left: 20px;
        padding-right: 20px;
    }
    .block-style-five p {
        font-size: 22px;
    }
    .contact-style-two .contact-info-wrapper .address-info p {
        font-size: 22px;
    }
    .fancy-text-block-eleven .img-gallery .overlay-img {
        right: -100px;
    }
    .theme-menu-four .right-button-group {
        margin-left: 30px;
    }
    body .theme-btn-five {
        padding: 0 15px;
    }
    .theme-menu-four .navbar-nav .nav-item a,
    .navbar-nav .nav-item a {
        margin: 0 12px;
        font-size: 15px;
    }
    .theme-menu-four .right-button-group .signIn-action,
    .theme-menu-four .right-button-group .theme-btn-five {
        font-size: 15px;
    }
    .theme-menu-four .right-button-group .signIn-action {
        margin-right: 20px;
    }
    .theme-menu-one .right-button-group {
        margin-left: 30px;
    }
    .theme-footer-four .bottom-footer-content p{
        font-size: 15px;
    }
}


/*(min-width: 992px) and (max-width: 1199px)*/

@media (max-width: 991px) {
    .hero-banner-four:before,
    .fancy-feature-nine:after {
        bottom: -26px;
    }
    .pricing-section-three:before,
    .faq-section-four:before {
        top: -37px;
    }
    .pricing-section-three:after,
    .faq-section-four:after,
    .pricing-section-three-inner-page:before {
        bottom: -23px;
    }
    .theme-footer-four:before {
        top: -36px;
    }
    .title-style-five h2 {
        font-size: 40px;
    }
    .title-style-one h2 {
        font-size: 42px;
    }
    .title-style-two h2 {
        font-size: 40px;
        line-height: 1.2em;
    }
    .title-style-three h2 {
        font-size: 38px;
    }
    .title-style-five h2 span:before {
        height: 10px;
    }
    .title-style-five h6 {
        font-size: 16px;
        padding-bottom: 5px;
    }
    .title-style-two p {
        font-size: 15px;
    }
    .title-style-three p {
        font-size: 15px;
        padding-bottom: 8px;
    }
    .title-style-two .sub-text {
        font-size: 20px;
    }
    body .theme-btn-five {
        line-height: 50px;
        padding: 0 25px;
        min-width: 155px;
    }
    .theme-main-menu {
        padding: 30px 15px;
    }
    .theme-main-menu>div {
        position: static;
    }
    .main-page-wrapper {
        padding-top: 99px;
    }
    .theme-main-menu.sticky-menu.fixed {
        padding: 20px 15px;
    }
    .hero-banner-four .illustration {
        max-width: 100%;
        margin: 0 auto;
    }
    .hero-banner-four {
        padding: 150px 0 50px;
    }
    .hero-banner-four .review-text {
        text-align: center;
        padding-top: 20px;
    }
    .hero-banner-four .shape-four,
    .block-style-fourteen.arrow-shape:before,
    .block-style-fourteen.arrow-shape:after,
    .fancy-text-block-four .wrapper:before {
        display: none;
    }
    .hero-banner-four .text-wrapper {
        padding-top: 50px;
    }
    .hero-banner-four h1 {
        font-size: 55px;
    }
    .hero-banner-four .sub-text {
        font-size: 22px;
        line-height: 1.6em;
        padding: 25px 0 40px;
    }
    .fancy-text-block-nineteen .text-wrapper .client-info {
        font-size: 20px;
    }
    .fancy-text-block-nineteen .text-wrapper p {
        font-size: 20px;
        padding-top: 30px;
    }
    .fancy-text-block-nineteen .text-wrapper .name {
        font-size: 22px;
        margin-top: 40px;
    }
    .fancy-text-block-nineteen .text-wrapper {
        padding-top: 50px;
    }
    .fancy-feature-nine {
        padding: 70px 0 40px;
    }
    .accordion-style-two .card .card-header button {
        font-size: 20px;
    }
    .accordion-style-two .card .card-body p {
        font-size: 18px;
    }
    .accordion-style-two .card .card-body {
        padding: 0 30px 30px 0;
    }
    .pricing-section-three {
        padding: 40px 0 60px;
    }
    .pricing-table-area-three .pr-table-wrapper .pack-name {
        font-size: 28px;
    }
    .pricing-table-area-three .pr-table-wrapper .price {
        font-size: 50px;
        padding-top: 15px;
    }
    .pricing-table-area-three .pr-table-wrapper .user-condition {
        font-size: 20px;
    }
    .client-feedback-slider-three .shapes{
        display: none;
    }
    .clientSliderThree p {
        font-size: 24px;
    }
    .clientSliderThree .name {
        font-size: 22px;
    }
    .faq-section-four {
        padding: 50px 0 75px;
    }
    .fancy-short-banner-five .sub-heading {
        font-size: 20px;
        padding: 35px 0 40px;
    }
    .theme-footer-four .bottom-footer-content p {
        margin: 0;
        text-align: center;
    }
    .theme-footer-four {
        padding: 40px 0 25px;
    }
    .fancy-text-block-twenty {
        padding: 130px 0 70px;
    }
    .fancy-text-block-twenty .title {
        font-size: 48px;
        padding-bottom: 50px;
    }
    .counter-box-three .number {
        font-size: 30px;
        padding-bottom: 10px;
    }
    .counter-box-three p {
        font-size: 20px;
    }
    .team-section-four {
        padding: 40px 0 0;
    }
    .feature-blog-four {
        padding: 35px 0 30px;
    }
    .feature-blog-four .feature-article-meta .img-meta {
        margin-bottom: 25px;
    }
    .feature-blog-four .feature-article-meta .post-meta a {
        font-size: 26px;
    }
    .feature-blog-four .feature-article-meta .post-meta .author_info {
        font-size: 18px;
        margin-top: 10px;
    }
    .pricing-section-three-inner-page {
        padding: 130px 0 30px;
    }
    .useable-tools-section h2 {
        font-size: 40px;
        margin: 10px 0 80px;
    }
    .useable-tools-section .img-box {
        width: 145px;
        height: 145px;
    }
    .useable-tools-section .img-box.bx-g {
        width: 160px;
        height: 160px;
    }
    .useable-tools-section.bg-color {
        padding: 100px 0 60px;
    }
    .title-style-one p {
        font-size: 18px;
        padding-top: 20px;
    }
    .block-style-five {
        padding: 40px 20px 50px;
    }
    .block-style-five p {
        font-size: 20px;
        line-height: 1.6em;
    }
    .fancy-text-block-six .img-meta-container {
        position: static;
        margin: 50px auto 0;
        max-width: 60%;
    }
    .block-style-six .text-details .title {
        font-size: 30px;
        padding: 18px 0 22px;
    }
    .block-style-six .text-details .text-meta {
        font-size: 18px;
        padding-bottom: 50px;
    }
    .useable-tools-section-two.bg-shape .bg-wrapper {
        padding-top: 70px;
    }
    .client-feedback-slider-one .feedback-meta .watermark {
        display: none;
    }
    .client-feedback-slider-one .feedback-meta {
        padding-top: 60px;
    }
    .client-feedback-slider-one .shapes-holder {
        position: static;
        width: 100%;
    }
    .client-feedback-slider-one .shapes-holder img {
        display: none;
    }
    .client-feedback-slider-one .shapes-holder .title-style-two {
        position: relative;
        z-index: 5;
        top: 0;
        right: 50%;
        -webkit-transform: translateX(50%);
                transform: translateX(50%);
        text-align: center;
    }
    .client-feedback-slider-one .feedback-meta .icon,
    .client-feedback-slider-one .clientSliderOne .c_img {
        margin: 0 auto;
    }
    .client-feedback-slider-one .clientSliderOne {
        text-align: center;
    }
    .client-feedback-slider-one .clientSliderOne p {
        font-size: 20px;
        padding: 20px 0 40px;
    }
    .client-feedback-slider-one .clientSliderOne .info strong {
        font-size: 20px;
        padding-top: 15px;
    }
    .client-feedback-slider-one .slider-arrow {
        justify-content: center;
    }
    .client-feedback-slider-one .slider-arrow li {
        margin: 0 10px;
    }
    .fancy-short-banner-three .bg-wrapper {
        padding: 60px 20px;
    }
    .fancy-short-banner-three .title-style-one h2 {
        font-size: 40px;
    }
    .fancy-short-banner-three .form-wrapper {
        padding: 30px 0 0;
    }
    .fancy-short-banner-three .form-wrapper form {
        height: 60px;
    }
    .fancy-hero-three .heading {
        font-size: 55px;
        line-height: 1.22em;
        padding-bottom: 30px;
    }
    .fancy-hero-three .sub-heading {
        font-size: 20px;
    }
    .fancy-hero-three {
        padding: 150px 0 80px;
    }
    .fancy-feature-seven {
        padding: 80px 0 80px;
    }
    .block-style-nine .text-wrapper h6 {
        font-size: 15px;
    }
    .block-style-nine .text-wrapper .title {
        font-size: 35px;
        padding-bottom: 15px;
    }
    .block-style-nine .text-wrapper p {
        font-size: 18px;
    }
    .block-style-nine:before {
        display: none !important;
    }
    .fancy-text-block-thirteen .text-wrapper p {
        font-size: 20px;
        padding-top: 20px;
        padding-bottom: 30px;
    }
    .fancy-text-block-fourteen .text-wrapper .sub-text {
        font-size: 20px;
        padding-top: 25px;
    }
    .fancy-text-block-fourteen .text-wrapper .name {
        font-size: 20px;
        margin-top: 30px;
    }
    .fancy-hero-two .page-title {
        font-size: 20px;
    }
    .fancy-hero-two .heading {
        font-size: 50px;
    }
    .fancy-hero-two .sub-heading {
        font-size: 18px;
        line-height: 1.7em;
    }
    .fancy-hero-two {
        padding: 0 15px;
    }
    .fancy-hero-two .bg-wrapper {
        padding: 60px 0;
    }
    .fancy-text-block-nine .text-meta {
        font-size: 18px;
        padding-top: 25px;
        line-height: 1.75em;
    }
    .counter-with-icon-one .border-style {
        padding: 20px 0 40px;
    }
    .fancy-feature-five {
        padding: 0 15px;
    }
    .fancy-feature-five .bg-wrapper {
        padding: 80px 0 90px;
    }
    .block-style-seven .icon {
        height: 60px;
    }
    .block-style-seven {
        padding: 40px 0 0;
    }
    .block-style-seven .feature-info {
        padding-top: 22px;
    }
    .block-style-seven p {
        font-size: 20px;
        line-height: 1.45em;
    }
    body .theme-btn-three {
        padding: 0 25px;
        line-height: 48px;
        font-size: 16px;
    }
    .fancy-hero-four h6 {
        font-size: 16px;
    }
    .fancy-hero-four h2 {
        font-size: 50px;
    }
    .fancy-hero-four.space-fix {
        padding: 150px 0 265px;
    }
    .fancy-hero-four {
        padding: 150px 0 285px;
    }
    .fancy-hero-four .sub-heading {
        font-size: 20px;
        padding-top: 45px;
    }
    .contact-style-two .contact-info-wrapper .address-info {
        height: auto;
        padding: 35px 15px 35px;
    }
    .contact-style-two .contact-info-wrapper .address-info .icon {
        height: 55px;
    }
    .contact-style-two .contact-info-wrapper .address-info p {
        font-size: 18px;
        line-height: 1.5em;
    }
    .form-style-classic .input-group-meta {
        height: 50px;
    }
    .form-style-classic .input-group-meta input,
    .form-style-classic .input-group-meta textarea {
        font-size: 18px;
    }
    .form-style-classic .input-group-meta.lg {
        height: 150px;
    }
    .pricing-section-two .pricing-table-area {
        margin-top: -253px;
    }
    .pricing-section-two .pr-table-wrapper {
        border-bottom: 1px solid #F1F1F1;
    }
    .pricing-section-two .pr-table-wrapper .pack-name {
        font-size: 16px;
    }
    .pricing-section-two .pr-table-wrapper .price {
        font-size: 50px;
        padding: 25px 0 15px;
    }
    .pricing-section-two .pr-table-wrapper .price sup {
        top: -23px;
    }
    .pricing-section-two .pr-table-wrapper .theme-btn-three {
        line-height: 40px;
        padding: 0 50px;
    }
    .pricing-section-two .pr-table-wrapper .pr-feature {
        padding-left: 15px;
    }
    .pricing-section-two .pr-table-wrapper .pr-feature li {
        font-size: 15px;
    }
    .accordion-style-three .card .card-header button {
        font-size: 20px;
        padding: 25px 35px 12px 0;
    }
    .accordion-style-three .card .card-header button[data-toggle="collapse"].collapsed {
        padding-bottom: 25px;
    }
    .fancy-text-block-eleven .text-wrapper {
        padding: 0 0 100px 0 !important;
    }
    .fancy-text-block-eleven .text-wrapper p {
        font-size: 20px;
    }
    .title-style-two span img {
        bottom: -18px;
    }
    .fancy-feature-six .bg-wrapper {
        padding: 80px 0;
    }
    .fancy-text-block-twelve .text-wrapper .sub-text {
        font-size: 18px;
        padding-top: 30px;
    }
    .fancy-text-block-twelve .img-gallery {
        margin-top: 60px;
    }
    .blog-page-white-bg {
        padding-top: 100px;
    }
    .feature-blog-three .post-meta .post .title {
        font-size: 26px;
        margin-bottom: 20px;
    }
    .feature-blog-three .post-meta .post .read-more {
        margin-top: 20px;
    }
    .feature-blog-three .post-meta .post {
        padding: 35px 15px 20px;
    }
    .feature-blog-three .post-meta {
        margin-bottom: 40px;
    }
    .hero-banner-three h1 {
        font-size: 55px;
        line-height: 1.18em;
    }
    .hero-banner-three .sub-text {
        font-size: 22px;
        padding-bottom: 40px;
    }
    .search-filter-form form {
        height: 65px;
    }
    .search-filter-form select {
        top: 16px;
        width: 100px;
        right: 80px;
    }
    .search-filter-form button {
        width: 60px;
    }
    .search-filter-form input {
        padding: 0 180px 0 15px;
    }
    .hero-banner-three .illustration {
        margin-top: 70px;
    }
    .block-style-ten {
        padding: 45px 20px 30px;
    }
    .block-style-ten .icon {
        height: 60px;
    }
    .block-style-ten p {
        font-size: 20px;
    }
    .fancy-text-block-sixteen .img-slick-slider .slick-dots li button {
        width: 100%;
    }
    .block-style-eleven p {
        font-size: 20px;
    }
    .fancy-text-block-sixteen {
        padding-bottom: 80px;
    }
    .fancy-text-block-sixteen .img-slick-slider {
        margin-bottom: 70px;
    }
    .fancy-text-block-sixteen .img-slick-slider .slick-dots {
        bottom: -70px;
    }
    .block-style-twelve .ml-auto,
    .block-style-twelve .mr-auto {
        margin: 0 auto !important;
    }
    .block-style-twelve .text-wrapper {
        padding-top: 60px;
    }
    .block-style-twelve .text-wrapper h6 {
        font-size: 15px;
    }
    .block-style-twelve .text-wrapper .title {
        font-size: 28px;
        padding: 15px 0 20px;
    }
    .block-style-twelve .text-wrapper p {
        font-size: 18px;
    }
    .block-style-twelve {
        padding: 55px 0;
    }
    .client-feedback-slider-two {
        padding: 80px 0 50px;
    }
    .client-feedback-slider-two .bg-wrapper {
        padding: 35px 20px 30px;
    }
    .useable-tools-section-three .logo-wrapper {
        position: static;
        -webkit-transform: none;
                transform: none;
        margin: 50px auto 0;
        max-width: 100%;
    }
    .useable-tools-section-three .sub-text {
        font-size: 20px;
        padding: 35px 0 25px 0;
    }
    .useable-tools-section-three .all-button {
        font-size: 17px;
    }
    .faq-classic.with-bg {
        padding: 80px 0 200px;
    }
    .fancy-short-banner-four .bg-wrapper {
        padding: 55px 20px 50px;
    }
    .fancy-short-banner-four .title h6 {
        font-size: 15px;
    }
    .fancy-short-banner-four .title h2 {
        font-size: 35px;
    }
    .fancy-short-banner-four .form-wrapper form {
        height: 60px;
        margin-top: 40px;
    }
    .theme-footer-three .bottom-footer-content p {
        padding-left: 0;
    }
    .fancy-hero-five .img-meta {
        position: static;
    }
    .fancy-hero-five .heading {
        font-size: 50px;
    }
    .fancy-hero-five .sub-heading {
        font-size: 20px;
        padding: 35px 0 0;
    }
    .fancy-hero-five {
        padding: 150px 0 80px;
    }
    .fancy-text-block-seventeen .text-meta {
        font-size: 20px;
        padding: 30px 0 0;
    }
    .teamSliderOne .team-member {
        margin-left: 10px;
        margin-right: 10px;
    }
    .team-section-three {
        margin-top: -260px;
    }
    .fancy-text-block-eighteen {
        padding-bottom: 300px;
    }
    .useable-tools-section .sub-text {
        font-size: 20px;
    }
    .doc-container.top-border {
        margin-top: 99px;
    }
    .doc-sidebar .list-item li h4 {
        font-size: 20px;
    }
    .doc-sidebar .list-item>li {
        padding: 5px 0;
    }
    .doc-container.full-width .doc-main-body {
        border-right: none;
        padding-left: 20px;
        padding-right: 15px;
    }
    .doc-container .doc-main-body h2 {
        font-size: 30px;
        padding-bottom: 20px;
    }
    .doc-container .doc-main-body h3 {
        font-size: 22px;
        padding-bottom: 20px;
    }
    .doc-container .doc-main-body p {
        padding-bottom: 25px;
    }
    #doc-sidebar-nav {
        padding-right: 15px;
    }
    .doc-container .doc-main-body {
        padding-left: 15px;
    }
    .blog-v4 .post-meta .post {
        padding-top: 30px;
    }
    .blog-v4 .post-meta .post .title {
        font-size: 30px;
        margin-bottom: 30px;
        line-height: 1.2em;
    }
    .blog-v4 .post-meta .post p {
        font-size: 18px;
    }
    .blog-v4 .post-meta .post .read-more {
        margin-top: 25px;
        font-size: 17px;
    }
    .blog-v4 .post-meta {
        margin-bottom: 60px;
    }
    .pricing-section-one .pr-table-wrapper {
        margin-bottom: 45px;
    }
    .pricing-section-one .pricing-table-area {
        padding-bottom: 40px;
    }
    .pricing-section-one .pr-table-wrapper .price {
        font-size: 50px;
    }
    .fancy-text-block-fifteen .bg-wrapper {
        padding: 40px 15px 0;
    }
    .fancy-text-block-fifteen .text-wrapper p {
        font-size: 26px;
    }
    .fancy-text-block-fifteen .text-wrapper h6 {
        font-size: 18px;
        padding-top: 25px;
    }
    .fancy-text-block-fifteen .contact-banner p {
        font-size: 30px;
        text-align: center;
    }
    .fancy-text-block-fifteen .contact-banner .theme-btn-four {
        margin: 25px auto 0 !important;
        line-height: 50px;
        font-size: 18px;
        width: 170px;
    }
    .fancy-text-block-fifteen {
        padding-bottom: 80px;
    }
    .blog-details-post-v1 .post-meta .mark-text {
        font-size: 20px;
    }
    .blog-details-post-v1 .post-meta h4 {
        font-size: 28px;
        padding-top: 40px;
    }
    .hero-banner-five {
        padding-top: 40px;
    }
    .hero-banner-five .hero-heading {
        font-size: 45px;
        line-height: 1.29em;
    }
    .hero-banner-five .hero-sub-heading {
        font-size: 18px;
        padding-bottom: 40px;
    }
    .fancy-feature-ten .bg-wrapper {
        padding: 60px 0 80px;
    }
    .fancy-feature-ten .sub-text {
        font-size: 20px;
        padding-bottom: 0;
    }
    .block-style-fifteen {
        padding: 30px 15px;
    }
    .block-style-fifteen h3 {
        font-size: 22px;
    }
    .fancy-text-block-twentyOne .text-wrapper .icon {
        width: 45px;
    }
    .fancy-text-block-twentyOne .text-wrapper .client-info {
        font-size: 20px;
        padding: 15px 0 22px;
    }
    .fancy-text-block-twentyOne .text-wrapper p {
        font-size: 30px;
        padding-bottom: 0px;
    }
    .counter-style-two .border-bottom {
        padding-bottom: 40px;
    }
    .block-style-sixteen {
        margin-top: 60px;
        padding: 40px 0 0;
    }
    .block-style-sixteen .shapes {
        position: static;
        -webkit-transform: none;
                transform: none;
        margin: 10px auto 0;
    }
    .block-style-sixteen .icon {
        width: 55px;
        height: 55px;
    }
    .block-style-sixteen .title {
        font-size: 28px;
        padding: 20px 0 25px;
    }
    .block-style-sixteen p {
        font-size: 18px;
        line-height: 1.65em;
        padding-bottom: 45px;
    }
    .pricing-nav-three {
        margin: 40px 0 50px;
    }
    .pricing-nav-four {
        margin: 40px auto 50px;
    }
    .pricing-table-area-four .pr-table-wrapper.most-popular:after {
        right: 6%;
    }
    .client-feedback-slider-four .inner-container {
        padding: 60px 0;
    }
    .clientSliderFour .slick-dots {
        margin-top: 40px;
    }
    .clientSliderFour {
        margin-top: 60px;
    }
    .fancy-short-banner-six p {
        font-size: 20px;
        padding: 25px 0 40px;
    }
    body .theme-btn-seven {
        line-height: 54px;
        padding: 0 30px;
        font-size: 16px;
    }
    .theme-footer-five,
    .theme-footer-six {
        text-align: center;
    }
    .theme-footer-five .inner-container {
        padding: 40px 0 0;
    }
    .theme-footer-five .copyright {
        padding: 25px 0;
    }
    .hero-banner-six .illustration-content {
        position: relative;
        top: 0;
        padding: 0;
        -webkit-transform: none;
                transform: none;
        margin: 0 auto 70px;
    }
    .hero-banner-six {
        padding: 80px 0;
    }
    .hero-banner-six .hero-heading {
        font-size: 45px;
    }
    .hero-banner-six .hero-sub-heading {
        padding: 40px 0 60px;
    }
    .fancy-feature-thirteen .carousel-indicators li {
        flex: 0 0 50%;
        max-width: 50%;
    }
    .fancy-feature-thirteen .carousel-indicators {
        flex-wrap: wrap;
        justify-content: center !important;
    }
    .fancy-feature-thirteen .carousel-inner .shape-one {
        width: 80px;
        top: -27px
    }
    .fancy-feature-thirteen .carousel-inner .shape-two {
        width: 180px;
        bottom: -76px;
        left: -83px;
    }
    .fancy-portfolio-one {
        text-align: center;
    }
    .fancy-portfolio-one .slider-arrows {
        position: static;
        margin-top: 40px;
    }
    .fancy-portfolio-one .slider-arrows>div {
        width: 45px;
        height: 45px;
        margin: 0 5px;
    }
    .client-feedback-slider-five {
        padding: 40px 0 0;
    }
    .clientSliderFive p {
        font-size: 22px;
        padding-bottom: 70px;
    }
    .fancy-short-banner-seven .shape-five {
        width: 200px;
    }
    .theme-footer-six {
        margin-top: 80px;
    }
    .theme-footer-six .shape-one {
        opacity: 0.2;
    }
    .hero-banner-six .illustration-content .oval-one {
        width: 375px;
        height: 375px;
    }
    .hero-banner-six .illustration-content .oval-two,
    .hero-banner-six .illustration-content .oval-three {
        width: 368px;
        height: 368px;
        top: 64%
    }
    .fancy-feature-thirteen:before {
        width: 390px;
        height: 390px;
        top: 0;
    }
    .fancy-feature-thirteen:after,
    .fancy-portfolio-one:before,
    .fancy-portfolio-one:after {
        width: 390px;
        height: 390px;
    }
    .fancy-feature-fourteen .right-side:after,
    .fancy-feature-fourteen .right-side:before {
        width: 290px;
        height: 290px;
    }
    .client-feedback-slider-five:after,
    .client-feedback-slider-five:before {
        width: 400px;
        height: 400px;
    }
    .pricing-section-five:before {
        width: 360px;
        height: 360px;
        top: 10%;
    }
    .pricing-section-five:after {
        width: 360px;
        height: 360px;
        bottom: 0;
    }
    .theme-footer-six .inner-container:before {
        width: 400px;
        height: 400px;
        top: 0;
    }
    .theme-footer-six .inner-container:after {
        width: 400px;
        height: 400px;
        bottom: 0;
    }
    .dark-style .modal-contact-popup-one .right-side:after,
    .dark-style .modal-contact-popup-one .right-side:before {
        width: 270px;
        height: 270px;
        top: 10%;
    }
    .hero-banner-seven .illustration-container {
        position: relative;
        margin: 0 auto 50px;
        max-width: 80%;
        right: auto;
        top: 0;
    }
    .hero-banner-seven {
        margin-top: 40px;
    }
    .hero-banner-seven .hero-heading {
        font-size: 45px;
        padding: 20px 0 0;
    }
    .hero-banner-seven .hero-heading span:before {
        bottom: 10px;
    }
    .hero-banner-seven .hero-sub-heading {
        padding: 30px 0 45px 0;
    }
    .block-style-eighteen .text-wrapper h6 {
        font-size: 14px;
    }
    .block-style-eighteen .text-wrapper .title {
        font-size: 32px;
    }
    .block-style-eighteen .text-wrapper .title span:before {
        height: 10px;
        bottom: 2px;
    }
    .block-style-eighteen .text-wrapper p {
        font-size: 18px;
        padding: 30px 0 40px;
    }
    .block-style-eighteen .text-wrapper {
        padding-bottom: 60px;
    }
    .block-style-eighteen .screen-holder-one,
    .block-style-eighteen .screen-holder-three {
        margin: 0 auto;
    }
    .fancy-text-block-twentyTwo .text-wrapper {
        padding-bottom: 50px;
    }
    .fancy-text-block-twentyTwo .text-wrapper .icon {
        width: 45px;
    }
    .fancy-text-block-twentyTwo .text-wrapper p {
        font-size: 18px;
        padding: 30px 0 40px;
    }
    .fancy-feature-seventeen .bg-wrapper {
        padding: 60px 0 10px;
    }
    .pricing-nav-four .nav-item .nav-link {
        line-height: 50px;
        font-size: 16px;
    }
    .pricing-table-area-six .pr-table-wrapper .pack-name {
        font-size: 20px;
    }
    .pricing-table-area-six .pr-table-wrapper .price {
        font-size: 40px;
    }
    .pricing-table-area-six .pr-table-wrapper .pack-details {
        font-size: 14px;
        padding-bottom: 20px;
    }
    .pricing-table-area-six .pr-table-wrapper {
        padding: 30px 15px;
    }
    .clientSliderSix {
        margin-top: 0;
    }
    .clientSliderSix .feedback-wrapper {
        padding: 25px 15px 25px 20px;
    }
    .clientSliderSix .feedback-wrapper p {
        font-size: 18px;
        padding-bottom: 30px;
    }
    .theme-footer-seven .title {
        color: #000;
        padding-bottom: 12px;
        font-size: 18px;
    }
    .theme-footer-seven .footer-list a {
        font-size: 16px;
    }
    .theme-footer-seven .bottom-footer {
        margin-top: 30px;
        padding: 20px 0 5px;
    }
    .theme-footer-seven .bottom-footer .footer-nav a {
        margin: 0 12px;
    }
    .theme-footer-seven .bottom-footer .social-icon a {
        margin: 0 8px;
    }
    .hero-banner-eight .hero-heading {
        font-size: 45px;
    }
    .hero-banner-eight .hero-sub-heading {
        font-size: 18px;
        padding-bottom: 40px;
    }
    .hero-banner-eight .video-wrapper {
        height: 100%;
    }
    .hero-banner-eight .shape-right,
    .hero-banner-eight .shape-left {
        top: auto;
        bottom: 41%;
    }
    .title-style-eight h2 {
        font-size: 42px;
    }
    .title-style-eight h2 br {
        display: none;
    }
    .title-style-eight p {
        font-size: 18px;
        padding-top: 25px;
    }
    .fancy-feature-eighteen {
        padding: 80px 15px;
    }
    .fancy-feature-eighteen .nav-item .nav-link {
        width: 60px;
        height: 60px;
        margin: 10px 12px;
    }
    .fancy-feature-eighteen .nav-item .hover {
        width: 67%;
    }
    .fancy-feature-eighteen .nav-tabs {
        margin: 50px 0 40px;
    }
    .fancy-feature-eighteen .tab-content .text-wrapper h4 {
        font-size: 24px;
        padding-bottom: 30px;
    }
    .fancy-feature-eighteen .tab-content .text-wrapper p {
        font-size: 18px;
    }
    .fancy-feature-eighteen .tab-content .text-wrapper {
        padding: 40px 20px;
    }
    .block-style-twenty .text-wrapper {
        padding: 0 0 50px !important;
    }
    .block-style-twenty .text-wrapper h6 {
        font-size: 15px;
        padding-bottom: 25px;
    }
    .block-style-twenty .text-wrapper .title {
        font-size: 35px;
    }
    .block-style-twenty .text-wrapper p {
        font-size: 18px;
        padding: 30px 0;
    }
    .fancy-feature-twentyOne {
        padding: 100px 0;
    }
    .coming-soon-content h6 {
        font-size: 14px;
    }
    .coming-soon-content h1 {
        font-size: 45px;
        padding: 30px 0 40px;
    }
    .coming-soon-content p {
        font-size: 18px;
        padding-bottom: 40px;
    }
    .coming-soon-content form {
        height: 65px;
    }
    .coming-soon-content form button {
        width: 150px;
        font-size: 16px;
    }
    .coming-soon-content form input {
        padding: 0 165px 0 15px;
        font-size: 16px;
    }
    .coming-soon-content .social-icon {
        position: static;
        -webkit-transform: none;
                transform: none;
        margin-top: 50px;
    }
    .coming-soon-content .shape-ten {
        display: none;
    }
    .theme-menu-six .right-widget {
        margin-left: auto;
        margin-right: 15px;
    }
    .theme-menu-six .right-widget .signIn-action {
        margin-left: 0;
    }
    .theme-menu-six .navbar-nav .nav-item .nav-link {
        color: #2B2C2E;
    }
    .hero-banner-nine .illustration-container {
        position: relative;
        width: 100%;
        margin-bottom: 60px;
    }
    .hero-banner-nine {
        padding-top: 150px;
    }
    .hero-banner-nine .hero-heading {
        font-size: 45px;
    }
    .hero-banner-nine .hero-sub-heading {
        font-size: 18px;
    }
    .block-style-twentyTwo {
        padding: 40px 15px;
    }
    .title-style-nine h2 {
        font-size: 35px;
        line-height: 1.45em;
    }
    .block-style-twentyThree .screen-container {
        margin: 0 auto !important;
    }
    .block-style-twentyThree .text-wrapper h6 {
        font-size: 14px;
    }
    .block-style-twentyThree .text-wrapper .title {
        font-size: 30px;
        padding: 25px 0 18px;
    }
    .block-style-twentyThree .text-wrapper p {
        font-size: 18px;
    }
    .block-style-twentyThree .text-wrapper {
        padding-top: 90px;
    }
    .block-style-twentyThree {
        margin-bottom: 100px;
    }
    .fancy-feature-twentyFour .bg-wrapper {
        padding: 100px 0 80px;
    }
    .fancy-short-banner-ten h2 {
        font-size: 42px;
    }
    .fancy-short-banner-ten p {
        font-size: 20px;
        line-height: 1.6em;
    }
    .theme-footer-eight .bottom-footer .social-icon a {
        margin: 0 10px;
    }
    .team-section-five {
        padding: 100px 0 60px;
    }
    .fancy-short-banner-eleven h2 {
        font-size: 45px;
    }
    .fancy-short-banner-eleven.bg-color {
        padding: 80px 0 100px;
    }
    .fancy-short-banner-eleven .shapes,
    .team-details .shapes {
        display: none;
    }
    .team-details {
        padding: 80px 0 100px;
    }
    .team-details .main-bg .img-meta {
        width: 100%;
    }
    .team-details .main-bg .text-wrapper {
        width: 100%;
        padding: 50px 25px;
    }
    .team-details .pager {
        position: static;
        width: 100%;
        margin-top: 50px;
    }
    .team-details .main-bg .text-wrapper .name {
        font-size: 30px;
    }
    .terms_and_policy .nav-tabs {
        display: none !important;
    }
    .terms_and_policy .tab-content>.tab-pane {
        display: block;
        opacity: 1;
        margin-bottom: 60px;
    }
    .terms_and_policy {
        padding: 80px 0 0;
    }
    .terms_and_policy .tab-content h2 {
        font-size: 38px;
    }
    .terms_and_policy .tab-content h3 {
        font-size: 24px;
        padding: 40px 0 20px;
    }
    .error-page .img-holder {
        width: 100%;
        height: 100%;
    }
    .error-page .img-holder .illustration {
        min-height: 100%;
    }
    .error-page .text-wrapper {
        width: 100%;
        padding: 50px 15px;
        text-align: center;
    }
    .error-page .text-wrapper h1 {
        font-size: 40px;
    }
    .error-page .text-wrapper .back-home {
        margin: 0 auto;
    }
    .hero-banner-ten {
        margin-top: 40px;
    }
    .hero-banner-ten .hero-heading {
        font-size: 45px;
        line-height: 1.4em;
    }
    .hero-banner-ten [class*="icon-box"] {
        display: inline-block;
        margin: 5px 10px;
        position: static;
    }
    .hero-banner-ten .hero-sub-heading {
        font-size: 20px;
        padding: 35px 0 40px;
    }
    .hero-banner-ten .button-group {
        margin-bottom: 40px;
    }
    .title-style-ten h2 {
        font-size: 40px;
    }
    .title-style-ten p {
        font-size: 20px;
        line-height: 1.6em;
    }
    .title-style-eleven h2 {
        font-size: 38px;
    }
    .block-style-twentyFive .text-wrapper {
        padding-top: 60px;
    }
    .block-style-twentyFive .text-wrapper h6 {
        font-size: 18px;
        padding-bottom: 15px;
    }
    .block-style-twentyFive .title {
        font-size: 36px;
        padding-bottom: 25px;
    }
    .block-style-twentyFive p {
        font-size: 18px;
        line-height: 1.65em;
    }
    .block-style-twentyFive ul {
        padding-top: 30px;
    }
    .client-feedback-slider-seven {
        padding: 100px 0;
    }
    .fancy-short-banner-twelve {
        padding: 100px 0 80px;
    }
    .hero-banner-eleven .hero-text-wrapper h1 {
        font-size: 42px;
    }
    .hero-banner-eleven .illustration-container {
        position: static;
        max-width: 95%;
        margin: 0 auto;
    }
    .hero-banner-eleven {
        margin-top: 70px;
    }
    .partner-slider-two p.text-lg {
        font-size: 24px;
    }
    .block-style-twentySix {
        padding: 50px 35px 45px;
    }
    .block-style-twentySix h6 {
        font-size: 18px;
    }
    .block-style-twentySix h4 {
        font-size: 22px;
        margin-bottom: 25px;
    }
    .title-style-twelve .upper-title {
        font-size: 14px;
    }
    .title-style-twelve h2 {
        font-size: 40px;
    }
    .title-style-twelve p {
        font-size: 18px;
    }
    .title-style-twelve span img {
        display: none;
    }
    .video-box-two .video-icon {
        width: 120px;
        height: 70px;
    }
    .video-box-two .video-icon img {
        width: 45px;
    }
    .fancy-text-block-twentyThree .text-wrapper .cl-text {
        font-size: 18px;
        margin-bottom: 12px;
    }
    .block-style-twentyEight h4 {
        font-size: 22px;
    }
    .useable-tools-section-four .logo-container .inner-wrapper {
        position: static;
        -webkit-transform: none;
                transform: none;
    }
    .useable-tools-section-four .text-wrapper p {
        padding: 25px 0 30px;
    }
    .useable-tools-section-four .text-wrapper {
        padding: 60px 0 70px;
    }
    .useable-tools-section-four .logo-container .line-two {
        -webkit-transform: none;
                transform: none;
    }
    .useable-tools-section-four .logo-container .icon {
        height: 150px;
        margin: 0 0 35px;
    }
    .block-style-twentyNine {
        padding: 30px 25px 40px;
    }
    .block-style-twentyNine .video-icon {
        margin-top: 20px;
    }
    .block-style-twentyNine h3 a {
        font-size: 24px;
        margin-bottom: 35px;
    }
    .pricing-section-eight .pr-column .price {
        font-size: 35px;
    }
    .pricing-section-eight .pr-body li {
        padding: 22px 0;
    }
    .pricing-section-eight .pr-footer {
        padding: 25px 0 20px;
    }
    .fancy-hero-six .heading {
        font-size: 42px;
    }
    .fancy-hero-six .sub-heading {
        font-size: 20px;
        margin-top: 12px;
    }
    .fancy-hero-six {
        padding-bottom: 70px;
    }
    .po-control-one button {
        font-size: 16px;
        line-height: 40px;
        padding: 0 18px;
        margin: 0 0.8% 10px;
    }
    .mixitUp-container.gutter-space-one,
    .mixitUp-container.gutter-space-two {
        margin: 0 -15px;
    }
    .mixitUp-container.gutter-space-one .mix,
    .mixitUp-container.gutter-space-two .mix {
        padding: 0 15px;
        margin-bottom: 30px;
    }
    .portfolio-block-two .hover-content h3 a {
        font-size: 20px;
        margin-bottom: 10px;
    }
    .portfolio-block-two .hover-content {
        padding: 10px 5px 12px 20px;
    }
    .fancy-portfolio-four .contact-title {
        font-size: 28px;
    }
    .portfolio_slider_one {
        margin: 0 -10px;
    }
    .portfolio_slider_one .item {
        padding: 0 10px;
    }
    .portfolio-block-three .text a {
        font-size: 24px;
    }
    .portfolio-block-three .text .tag {
        margin-bottom: 0;
    }
    .portfolio-block-three .img-holder {
        margin-bottom: 20px;
    }
    .fancy-portfolio-five .slider-wrapper {
        margin: 0 -50vw;
    }
    .po-control-two button {
        font-size: 18px;
    }
    .fancy-portfolio-six .scroll-target {
        width: 60px;
        height: 60px;
        padding: 13px;
    }
    .fancy-portfolio-six .mix {
        margin-bottom: 30px;
    }
    .portfolio-details-one .portfolio-pagination .tp1 {
        font-size: 13px;
        margin-bottom: 0;
    }
    .portfolio-details-one .portfolio-pagination .tp2 {
        font-size: 20px;
    }
    .theme-menu-seven .right-widget {
        margin-left: auto;
    }
    .theme-menu-seven .right-widget .signIn-action {
        margin-right: 22px;
    }
    .theme-menu-seven .navbar-collapse {
        background: #242424;
    }
    .theme-menu-seven .navbar .navbar-collapse .navbar-nav .nav-item .nav-link {
        border-top: 1px solid rgba(255, 255, 255, 0.1);
    }
    .theme-menu-seven .navbar .navbar-collapse .navbar-nav .nav-item:last-child .nav-link {
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    }
    .hero-banner-twelve .hero-heading {
        font-size: 45px;
        line-height: 1.19em;
    }
    .hero-banner-twelve {
        padding-top: 190px;
    }
    .hero-banner-twelve .screen-holder {
        padding: 10px;
    }
    .title-style-thirteen h2 {
        font-size: 42px;
    }
    .title-style-thirteen .upper-title {
        font-size: 13px;
        padding-bottom: 10px;
    }
    .title-style-thirteen span img {
        display: none;
    }
    .block-style-thirty .feature {
        font-size: 13px;
        padding-bottom: 0;
        margin-bottom: 15px;
    }
    .block-style-thirty .feature-list-one strong {
        font-size: 20px;
        padding-bottom: 10px;
    }
    .block-style-thirty .feature-list-one li {
        padding-left: 50px;
    }
    .block-style-thirty .text-block p {
        font-size: 20px;
        margin-bottom: 25px;
    }
    .fancy-feature-thirty .wrapper {
        padding: 40px 0 90px;
    }
    .block-style-thirtyTwo {
        padding: 35px 15px 25px 10px;
    }
    .block-style-thirtyTwo .icon {
        width: 45px;
        height: 45px;
    }
    .block-style-thirtyTwo .text {
        width: calc(100% - 45px);
        padding-left: 20px;
    }
    .block-style-thirtyTwo .text h4 {
        font-size: 22px;
    }
    .fancy-short-banner-fourteen p {
        font-size: 20px;
        padding: 30px 0;
    }
    .fancy-short-banner-fourteen {
        padding: 80px 0 90px;
    }
    .title-style-four .sub-text {
        font-size: 18px;
    }
    .block-style-thirtyFour .hover-content {
        padding: 70px 15px 20px;
    }
    .block-style-thirtyFour .hover-content h4 a {
        font-size: 20px;
    }
    .fancy-feature-thirtyThree .contact-title {
        font-size: 30px;
    }
    .title-style-eleven p,
    .fancy-hero-seven p {
        font-size: 18px;
    }
    .theme-menu-one.center-white .logo {
        left: 15px;
    }
    .theme-menu-one.center-white #mega-menu-holder {
        margin-left: auto;
    }
    .fancy-hero-seven h2 {
        font-size: 42px;
    }
    .fancy-hero-seven {
        padding: 150px 0 80px;
    }
    .theme-menu-one.center-white .navbar-collapse {
        background: #161616;
    }
    .theme-menu-one.center-white .navbar-nav .nav-item .nav-link {
        margin: 0;
        border-top: 1px solid rgba(255, 255, 255, 0.09);
    }
    .theme-menu-one.center-white .navbar .navbar-collapse .navbar-nav .nav-item:last-child .nav-link {
        border-bottom: 1px solid rgba(255, 255, 255, 0.09);
    }
    .theme-menu-one.center-white .right-button-group {
        position: static;
        -webkit-transform: none;
                transform: none;
    }
    .service-details-one .details-wrapper h2 {
        font-size: 36px;
    }
    .service-details-one .details-wrapper .sub-heading,
    .service-details-one .accordion-style-three .card .card-header button {
        font-size: 20px;
    }
    .service-details-one .details-wrapper h3 {
        font-size: 26px;
    }
}


/*(max-width: 991px)*/

@media (min-width: 768px) and (max-width: 991px) {
    .block-style-thirteen.style-border {
        border-right: none;
    }
    .team-section-one .mix,
    .team-section-one .gap {
        width: calc(100%/3 - (((3 - 1) * 1rem) / 3));
    }
    .feature-blog-one .header .title-style-one {
        max-width: 460px;
    }
    .useable-tools-section-two .icon-wrapper ul li .icon-box {
        height: 70px;
    }
    .useable-tools-section-two .icon-wrapper ul li .icon-box img {
        max-width: 70%;
        margin: 0 auto;
    }
    .fancy-text-block-ten .container,
    .fancy-text-block-twentyOne .container {
        max-width: 720px;
    }
    .map-area-one {
        height: 600px;
    }
    .doc-container.full-width {
        padding-left: 15px;
    }
    .doc-sidebar {
        top: 80px;
    }
    #doc-sidebar-nav {
        padding-right: 15px;
    }
    #mega-menu-holder .mega-menu li {
        flex: 0 0 25%;
        max-width: 25%;
    }
    .app-screen-preview-one {
        margin-top: 170px;
    }
    .app-screen-preview-one .round-bg {
        width: 74%;
    }
    .fancy-portfolio-two .mix,
    .fancy-portfolio-two .mix.mixL,
    .fancy-portfolio-four .mix {
        width: 50%;
    }
}


/*(min-width: 768px) and (max-width: 991px)*/

@media (max-width: 767px) {
    .hero-banner-four:before,
    .fancy-feature-nine:after {
        bottom: -21px;
    }
    .pricing-section-three:before,
    .faq-section-four:before {
        top: -31px;
    }
    .pricing-section-three:after,
    .faq-section-four:after,
    .pricing-section-three-inner-page:before {
        bottom: -20px;
    }
    .theme-footer-four:before {
        top: -31px;
    }    
    .fancy-feature-eight .bg-wrapper {
        padding: 0 15px 15px;
    }
    .block-style-thirteen p {
        font-size: 20px;
    }
    .block-style-thirteen.style-border {
        border-left: none;
        border-right: none;
        border-top: 1px solid #E7E7E7;
        border-bottom: 1px solid #E7E7E7;
        margin: 15px 0;
    }
    .block-style-thirteen .title {
        padding: 15px 0;
    }
    .block-style-thirteen .icon {
        height: 55px;
    }
    .pricing-section-three .pricing-table-area-three:before,
    .fancy-text-block-four:after,
    .fancy-text-block-four:before,
    .fancy-text-block-one:before,
    .fancy-text-block-one:after,
    .faqs-inner-page .shape-one,
    .title-style-four span img,
    .client-feedback-slider-two .shapes,
    .faq-classic.with-bg .shapes,
    .fancy-feature-eleven .shape-one,
    .fancy-feature-eleven .shape-two,
    .client-feedback-slider-five .circle-area,
    .pricing-table-area-five .shape-two,
    .fancy-short-banner-eight .shapes {
        display: none;
    }
    .fancy-video-box-one .video-button {
        width: 70px;
        height: 70px;
        padding-left: 12px;
    }
    .fancy-video-box-one .video-button img {
        width: 65%;
    }
    .theme-footer-one .top-footer .footer-title {
        padding-bottom: 18px;
    }
    .useable-tools-section-two .icon-wrapper ul {
        -webkit-transform: none;
                transform: none;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin-top: 40px;
    }
    .useable-tools-section-two .icon-wrapper ul li {
        width: 20%;
        margin: 0 !important;
        float: none;
    }
    .fancy-text-block-ten p {
        font-size: 26px;
        padding-bottom: 30px;
    }
    .team-section-two .team-member .name {
        font-size: 22px;
        padding-top: 25px;
    }
    .contact-style-two .contact-info-wrapper .shape-one {
        display: none;
    }
    .accordion-style-three .card .card-body {
        padding-right: 0;
    }
    .theme-footer-three .bottom-footer-content {
        text-align: center;
        padding-top: 20px;
    }
    .theme-footer-three .bottom-footer-content ul li a {
        margin: 0 10px 5px;
    }
    .fancy-hero-five .img-meta {
        margin: 40px 0 0;
    }
    .team-section-three .slider-arrows li {
        width: 40px;
        height: 40px;
        line-height: 40px;
        margin-left: 10px;
    }
    .map-area-one {
        height: 500px;
    }
    .map-area-one .si-content h3 {
        font-size: 28px;
    }
    .map-area-one .si-content {
        padding: 35px 15px;
    }
    .map-area-one .si-content {
        width: auto;
        left: 20px;
        height: auto;
        max-width: 250px;
        top: 20px;
    }
    .map-area-one .si-content p {
        font-size: 15px;
        padding: 4px 0 15px;
    }
    .map-area-one .si-content span {
        font-size: 15px;
    }
    .doc-sidebar {
        height: 100%;
        padding-top: 0 !important;
    }
    #doc-sidebar-nav {
        position: fixed;
        top: 79px;
        right: 0;
        height: 100%;
        background: #fff;
        width: 280px;
        border-left: 1px solid #E1E6EE;
        padding: 30px 15px 10px;
        max-height: calc(100vh - 79px);
    }
    .doc-sidebar {
        border: none;
        background: #f5f5f5;
        top: 78px;
        padding: 10px 0 !important;
        z-index: 9;
    }
    .doc-container.full-width {
        padding: 0;
    }
    .doc-sidebar .btn {
        color: #fff;
        background: #2a2a2a;
        margin: 0 15px;
        padding: 2px 5px;
    }
    .doc-container.full-width .doc-main-body {
        padding: 50px 15px 30px;
    }
    .doc-container .doc-main-body {
        padding-left: 0;
        padding-top: 50px;
    }
    .blog-v4 .post-meta.text-post .post {
        padding: 35px 15px;
    }
    .partner-slider-two p {
        font-size: 18px;
        padding-bottom: 30px;
    }
    .counter-box-four .number {
        font-size: 40px;
    }
    .counter-box-four p {
        font-size: 16px;
    }
    .block-style-sixteen .shapes {
        width: 95% !important;
        margin-top: 30px;
    }
    .pricing-nav-three .nav-item .nav-link {
        width: 130px;
        line-height: 48px;
        font-size: 16px;
    }
    .pricing-table-area-four .pr-table-wrapper {
        padding: 25px 15px;
    }
    .pricing-table-area-four .row {
        margin: 0 -15px;
    }
    .pricing-table-area-four .row [class*="col-"] {
        padding: 0 15px;
    }
    .pricing-table-area-four .pr-table-wrapper .top-banner {
        padding: 5px 10px;
        text-align: center;
    }
    .pricing-table-area-four .pr-table-wrapper .price {
        padding: 25px 0 5px;
    }
    .clientSliderFour .feedback-wrapper p {
        padding-bottom: 30px;
    }
    .clientSliderFour .feedback-wrapper .name {
        padding-bottom: 5px;
    }
    .clientSliderFour .feedback-wrapper {
        padding: 30px 20px;
    }
    .modal-contact-popup-one .main-body .left-side,
    .modal-contact-popup-one .main-body .right-side {
        flex: 0 0 100%;
        max-width: 100%
    }
    .modal-contact-popup-one .main-body {
        display: block !important;
    }
    .fancy-feature-thirteen .carousel-indicators li {
        flex: 0 0 100%;
        max-width: 100%;
        padding: 0;
        margin-top: 40px;
    }
    .fancy-feature-thirteen .carousel-inner {
        margin-top: 60px;
    }
    .fancy-feature-fourteen .logo-meta {
        margin-top: 0;
        margin-bottom: 30px;
    }
    .pricing-table-area-five .shape-one {
        top: 0
    }
    .fancy-feature-fifteen .bg-wrapper:before {
        display: none;
    }
    .fancy-feature-fifteen .carousel-indicators li p {
        font-size: 16px;
    }
    .block-style-twentySeven h4 {
        font-size: 22px;
        margin: 30px 0 20px;
    }
    .block-style-twentySeven {
        padding: 35px 12px;
    }
    .pricing-section-eight .pr-column {
        border: 1px solid #ededed;
        border-radius: 15px;
        margin-bottom: 40px;
    }
    .pricing-section-eight .pr-table-wrapper {
        border: none;
    }
    .pricing-section-eight .pr-column:last-child {
        border-right: 1px solid #ededed;
    }
    .accordion-style-five .card .card-header button {
        font-size: 18px;
        padding: 22px 30px 22px 0
    }
    .accordion-style-five .card .card-body {
        padding-right: 30px;
    }
    .fancy-portfolio-three .mix {
        width: 100%;
    }
    .portfolio-details-one .main-content .project-info li {
        width: 48%;
        float: left;
        padding: 0 1%;
    }
    .portfolio-details-one .main-content .project-info strong {
        font-size: 15px;
    }
    .portfolio-details-one .main-content .project-info span {
        font-size: 15px;
        margin: 0 0 20px;
        letter-spacing: 0px;
    }
    .portfolio-details-one .main-content .project-info {
        margin-bottom: 35px;
    }
    .fancy-video-box-two .video-button {
        width: 65px;
        height: 65px;
    }
}


/*(max-width: 767px)*/

@media (min-width: 768px) {
    #doc-sidebar-nav {
        display: block !important;
    }
}

@media (min-width: 576px) and (max-width: 767px) {
    .team-section-one .mix,
    .team-section-one .gap {
        width: calc(100%/2 - (((2 - 1) * 1rem) / 2));
    }
    .fancy-text-block-ten .container,
    .fancy-text-block-twentyOne .container {
        max-width: 540px;
    }
    #mega-menu-holder .mega-menu li {
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }
    .app-screen-preview-one {
        margin-top: 150px;
    }
    .app-screen-preview-one .round-bg {
        width: 80%;
    }
    .fancy-portfolio-two .mix,
    .fancy-portfolio-two .mix.mixL,
    .fancy-portfolio-four .mix {
        width: 50%;
    }
}


/*(min-width: 576px) and (max-width: 767px)*/

@media (max-width: 575px) {
    .accordion-style-four .card .card-header button {
        padding-left: 15px;
        font-size: 20px;
    }
    .accordion-style-four .card .card-body {
        padding: 0 40px 20px 15px;
    }
    .fancy-short-banner-five form button {
        width: 130px;
    }
    .fancy-short-banner-five form input {
        padding: 0 135px 0 20px;
    }
    .fancy-text-block-twenty .nav-tabs li {
        margin: 20px 0 0;
    }
    .fancy-text-block-twenty .nav-tabs li a {
        font-size: 20px;
    }
    .fancy-text-block-twenty .nav-tabs {
        justify-content: space-between;
    }
    .fancy-text-block-twenty .tab-pane p {
        padding-top: 20px;
        font-size: 18px;
    }
    .fancy-text-block-twenty .shape-one,
    .team-section-four .team-wrapper .shape-one,
    .clients-feedback-classic .feedback-wrapper:before,
    .title-style-two span img,
    .title-style-three span img,
    .fancy-text-block-six .img-meta-container .shape-five,
    .title-style-two h2 br,
    .theme-footer-two:before,
    .fancy-feature-five .shape-five,
    .hero-banner-three .shapes,
    .client-feedback-slider-two .bg-wrapper:before,
    .fancy-hero-five span img,
    .pricing-section-one .pricing-table-area .shape-two,
    .hero-banner-five .shapes,
    .pricing-table-area-four .pr-table-wrapper.most-popular:after,
    .title-style-five h2 span:before,
    .fancy-feature-fifteen .bg-wrapper .shapes {
        display: none;
    }
    .counter-box-three .icon img {
        width: 70%;
        margin: 0 auto;
    }
    .counter-box-three .icon {
        height: 45px;
    }
    .team-section-four .team-member {
        margin-bottom: 40px;
    }
    .fancy-short-banner-five form {
        height: 60px;
    }
    .theme-footer-four .footer-title {
        font-size: 24px;
        margin-bottom: 15px;
    }
    .user-data-form .input-group-meta {
        height: 45px;
    }
    .user-data-form .input-group-meta .placeholder_icon {
        line-height: 45px;
    }
    .user-data-form .input-group-meta input {
        font-size: 16px;
    }
    .user-data-form .input-group-meta label {
        top: -23px;
    }
    .user-data-form .input-group-meta .placeholder_icon span:before {
        top: 10px
    }
    .hero-banner-one .subscription-form button {
        width: 120px;
    }
    .hero-banner-one .subscription-form input {
        padding-right: 122px;
    }
    .block-style-one .block-meta-data .hover-content span {
        padding: 5px 15px;
    }
    .block-style-two .img-holder-two .screen-one {
        right: -17%;
    }
    .useable-tools-section h2 {
        font-size: 32px;
        line-height: 1.35em;
    }
    .counter-box-one.color-one {
        left: 0;
    }
    .counter-box-one.color-two {
        left: 190px;
    }
    .counter-box-one.color-three {
        left: 70px;
    }
    .clients-feedback-classic .feedback-wrapper {
        padding-left: 35px;
        padding-right: 35px;
    }
    .fancy-short-banner-one .form-wrapper form button {
        width: 135px;
        line-height: 45px;
    }
    .fancy-short-banner-one .form-wrapper form input {
        width: calc(100% - 150px);
    }
    .faq-classic .card .card-header button {
        font-size: 20px;
        padding-left: 45px;
    }
    .faq-classic .card .card-header button:before {
        left: 18px
    }
    .faq-classic .card .card-body {
        padding: 0 25px 20px 45px;
    }
    .theme-footer-one .bottom-footer-content {
        padding-top: 10px;
        text-align: center;
    }
    .theme-footer-one .bottom-footer-content ul li a {
        margin: 0 10px 10px;
    }
    .block-style-four {
        padding-left: 20px;
        padding-right: 20px;
    }
    .team-section-one .mix,
    .team-section-one .gap {
        width: calc(100%/1 - (((1 - 1) * 1rem) / 1));
    }
    .team-section-one .controls .control:before {
        bottom: 23px;
    }
    .faqs-all-qus .article-preview h3 {
        font-size: 22px;
    }
    .faqs-all-qus .article-preview .article-details .list-meta {
        padding-left: 15px;
    }
    .feature-blog-one .post-meta .read-more {
        font-size: 14px;
    }
    .feature-blog-one.width-lg .post-meta {
        padding-left: 20px;
        padding-right: 20px;
    }
    .feature-blog-one.width-lg .post-meta .read-more {
        left: 20px;
        right: 20px;
    }
    .blog-sidebar-one .sidebar-title {
        font-size: 26px;
    }
    .blog-sidebar-one .sidebar-recent-news .title {
        font-size: 22px;
    }
    .hero-banner-two .hero-text-wrapper form button {
        width: 140px;
    }
    .hero-banner-two .hero-text-wrapper form input {
        padding: 0 150px 0 20px;
        font-size: 16px;
    }
    .fancy-text-block-six .img-meta-container {
        max-width: 90%;
    }
    .fancy-text-block-seven .quote-wrapper blockquote {
        font-size: 28px;
    }
    .block-style-six .text-details .quote-wrapper {
        padding: 35px 25px 35px;
    }
    .useable-tools-section-two .icon-wrapper ul li .icon-box {
        height: 70px;
    }
    .useable-tools-section-two .icon-wrapper ul li .icon-box img {
        max-width: 70%;
        margin: 0 auto;
    }
    .fancy-short-banner-three .form-wrapper form button {
        width: 115px;
    }
    .fancy-short-banner-three .form-wrapper form input {
        padding: 0 125px 0 15px;
    }
    .theme-footer-two .top-footer .footer-title {
        font-size: 22px;
        padding-bottom: 10px;
    }
    .fancy-hero-three .heading {
        font-size: 48px;
    }
    .block-style-nine .text-wrapper .title {
        font-size: 30px;
    }
    .fancy-text-block-thirteen .text-wrapper p {
        font-size: 18px;
    }
    .fancy-text-block-fourteen .img-holder {
        padding: 0 15px;
    }
    .fancy-hero-two .heading {
        font-size: 40px;
        padding-top: 8px;
    }
    .pricing-nav-two .nav-item .nav-link {
        width: 130px;
    }
    .block-style-five .icon {
        height: 55px;
    }
    .feature-blog-three .post-meta .post .title {
        font-size: 22px;
    }
    .block-style-eleven .num {
        font-size: 25px;
    }
    .client-feedback-slider-two .slider-content {
        padding: 0;
    }
    .fancy-short-banner-four .form-wrapper form button {
        width: 125px;
    }
    .fancy-short-banner-four .form-wrapper form input {
        padding: 0 130px 0 15px;
        font-size: 16px;
    }
    .theme-footer-three .top-footer .footer-title {
        font-size: 22px;
        padding-bottom: 10px;
    }
    .fancy-hero-five .heading {
        font-size: 46px;
    }
    .fancy-text-block-seventeen .text-meta {
        font-size: 18px;
        line-height: 1.7em;
    }
    .team-section-three .slider-arrows li {
        display: none !important;
    }
    .teamSliderOne {
        margin-right: 0;
    }
    .useable-tools-section h6 {
        padding-bottom: 10px;
    }
    .doc-sidebar .list-item li h4 {
        font-size: 18px;
    }
    .doc-sidebar .list-item>li {
        padding: 5px 0 0;
    }
    .doc-pagination ul li span {
        display: none;
    }
    .pricing-nav-one .nav-item .nav-link {
        width: 130px;
        line-height: 45px;
    }
    .blog-details-post-v1 .comment-area,
    .blog-details-post-v1 .comment-form-section {
        padding: 40px 15px;
    }
    .blog-details-post-v1 .comment-area .reply {
        position: static;
        margin-top: 10px;
    }
    .blog-details-post-v1 .comment-form-section .title,
    .blog-details-post-v1 .comment-area .title {
        font-size: 30px;
    }
    #mega-menu-holder .mega-menu li {
        flex: 0 0 50%;
        max-width: 50%;
    }
    #mega-menu-holder .mega-menu li .img-box .hover {
        font-size: 18px;
    }
    .theme-menu-five .right-widget .demo-button {
        width: 50px;
        padding: 7px 0;
        display: block;
    }
    .theme-menu-five .right-widget .demo-button img {
        display: block;
    }
    .theme-menu-five .right-widget .demo-button span {
        display: none;
    }
    .hero-banner-five .button-group a {
        margin: 10px auto;
    }
    .hero-banner-five .img-gallery {
        margin-top: 60px;
    }
    .pricing-nav-three {
        margin-bottom: 20px;
    }
    .modal-contact-popup-one .main-body .left-side {
        display: none !important;
    }
    .hero-banner-six .illustration-content {
        width: 90%;
        margin-right: 0;
    }
    .hero-banner-six .button-group .ios-button {
        margin: 25px 0 0 0;
    }
    .fancy-feature-thirteen .carousel-inner .shape-two {
        left: -45px;
    }
    .pricing-table-area-five .pr-table-wrapper .popular-badge {
        left: 10px;
    }
    .fancy-short-banner-seven .shape-five {
        width: 150px;
    }
    .fancy-short-banner-seven .shape-four {
        width: 70px;
    }
    .hero-banner-seven .illustration-container {
        max-width: 100%;
    }
    .hero-banner-seven form button {
        width: 130px;
        font-size: 14px;
    }
    .hero-banner-seven form input {
        padding: 0 150px 0 15px;
    }
    .fancy-feature-fifteen .carousel-indicators {
        flex-direction: column;
    }
    .fancy-feature-fifteen .carousel-indicators li {
        margin-bottom: 15px;
    }
    .fancy-feature-fifteen .carousel-indicators li:before {
        display: none;
    }
    .block-style-eighteen .screen-holder-one {
        width: 100%;
    }
    .block-style-eighteen .screen-holder-one .logo-two,
    .block-style-eighteen .screen-holder-one .logo-three {
        left: 0;
    }
    .block-style-eighteen .screen-holder-one .logo-five,
    .block-style-eighteen .screen-holder-two .screen-one {
        right: 0;
    }
    .block-style-eighteen .screen-holder-three {
        width: auto;
        height: auto;
        padding: 50px 15px;
    }
    .clientSliderSix .item {
        margin-top: 60px;
    }
    .fancy-short-banner-eight .download-btn,
    .fancy-short-banner-nine .download-btn {
        width: 220px;
        margin-top: 50px;
    }
    .fancy-short-banner-eight .download-btn button,
    .fancy-short-banner-nine .download-btn button {
        line-height: 60px;
        font-size: 16px;
    }
    .theme-menu-five .right-widget .signup-btn {
        display: none;
    }
    .block-style-nineteen {
        padding: 30px 15px;
    }
    .block-style-nineteen .text h4 {
        font-size: 20px;
    }
    .fancy-feature-eighteen .tab-content .img-holder .ripple-box {
        width: 80%;
        height: 70%;
        font-size: 20px;
        background: rgba(0, 0, 0, 0.2);
    }
    .theme-menu-six .right-widget .signup-btn {
        display: none;
    }
    .theme-menu-six .right-widget .signIn-action {
        margin-right: 0;
    }
    .hero-banner-nine form button {
        width: 60px;
        height: 60px;
    }
    .hero-banner-nine form {
        height: 60px;
    }
    .hero-banner-nine form input {
        width: calc(100% - 80px);
        padding: 0 20px;
    }
    .block-style-twentyFour {
        padding: 30px 20px 40px;
    }
    .block-style-twentyFour .icon {
        width: 60px;
        height: 60px;
    }
    .block-style-twentyFour .text {
        width: calc(100% - 60px);
        padding-left: 25px;
    }
    .fancy-short-banner-ten .download-btn {
        width: 260px;
    }
    .fancy-short-banner-ten {
        padding: 80px 0;
    }
    .block-style-twentyThree .screen-container .shape-one {
        left: 0;
    }
    .block-style-twentyThree .screen-container .shape-two {
        right: 0;
    }
    .block-style-twentyThree .screen-container .shape-three {
        max-width: 100%;
        left: 0;
        bottom: 0;
    }
    .hero-banner-ten .button-group a,
    .fancy-short-banner-twelve .button-group a {
        margin: 10px auto;
    }
    .app-preview-slider-one .img-holder img {
        -webkit-transform: scale(1);
                transform: scale(1);
    }
    .app-preview-slider-one .item {
        margin: 0 15px;
    }
    .app-preview-slider-one {
        margin: 0 -15px;
    }
    .app-screen-preview-one {
        margin-top: 100px;
    }
    .fancy-short-banner-twelve .shapes {
        opacity: 0.4;
    }
    .block-style-twentyFive .screen-container .block-content {
        position: static;
        -webkit-transform: none;
                transform: none;
    }
    .block-style-twentyFive .screen-container .feature-meta {
        margin: 0 0 30px;
    }
    .block-style-twentyFive .screen-container .bg-round-shape {
        position: absolute;
        left: 50%;
        top: 50%;
        z-index: -1;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
    }
    .hero-banner-eleven form button {
        width: 130px;
        font-size: 14px;
    }
    .hero-banner-eleven form input {
        padding: 0 150px 0 15px;
    }
    .hero-banner-eleven form {
        height: 70px
    }
    .fancy-portfolio-two .mix,
    .fancy-portfolio-two .mix.mixL,
    .fancy-portfolio-four .mix,
    .portfolio-details-one .main-content .project-info li {
        width: 100%;
    }
    .fancy-portfolio-five .slider-wrapper {
        margin: 0 -70vw;
    }
    .fancy-portfolio-five .slider-arrows li {
        margin: 25px 5px 0;
        width: 45px;
        height: 45px;
    }
    .hero-banner-twelve .hero-heading {
        font-size: 40px;
    }
    .hero-banner-twelve .screen-holder .screen-one {
        left: 0;
    }
    .hero-banner-twelve .screen-holder .screen-two {
        right: 0;
    }
    .hero-banner-twelve>.shapes,
    .fancy-feature-thirty .wrapper .shapes,
    .fancy-short-banner-fourteen .shapes {
        display: none;
    }
    .title-style-thirteen h2 {
        font-size: 38px;
    }
}


/*(max-width: 575px)*/

@media (max-width: 500px) {
    .useable-tools-section-two .icon-wrapper ul li {
        width: 30%;
    }
}

@media (max-width: 430px) {
    .theme-main-menu .logo {
        max-width: 150px;
        margin: 0;
    }
}

@media (max-width: 400px) {
    .block-style-thirteen {
        padding-right: 0;
        padding-left: 0;
    }
    .clientSliderThree p {
        font-size: 22px;
    }
}



@-webkit-keyframes jump {
  0%   {-webkit-transform: translate3d(0,0,0);transform: translate3d(0,0,0);}
  40%  {-webkit-transform: translate3d(0,50%,0);transform: translate3d(0,50%,0);}
  100% {-webkit-transform: translate3d(0,0,0);transform: translate3d(0,0,0);}
}
@keyframes jump {
  0%   {-webkit-transform: translate3d(0,0,0);transform: translate3d(0,0,0);}
  40%  {-webkit-transform: translate3d(0,50%,0);transform: translate3d(0,50%,0);}
  100% {-webkit-transform: translate3d(0,0,0);transform: translate3d(0,0,0);}
}

@-webkit-keyframes jumpTwo {
  0%   {-webkit-transform: translate3d(0,0,0);transform: translate3d(0,0,0);}
  40%  {-webkit-transform: translate3d(0,20px,0);transform: translate3d(0,20px,0);}
  100% {-webkit-transform: translate3d(0,0,0);transform: translate3d(0,0,0);}
}
@keyframes jumpTwo {
  0%   {-webkit-transform: translate3d(0,0,0);transform: translate3d(0,0,0);}
  40%  {-webkit-transform: translate3d(0,20px,0);transform: translate3d(0,20px,0);}
  100% {-webkit-transform: translate3d(0,0,0);transform: translate3d(0,0,0);}
}
@-webkit-keyframes jumpThree {
  0%   {-webkit-transform: translate3d(0,0,0);transform: translate3d(0,0,0);}
  40%  {-webkit-transform: translate3d(0,-20px,0);transform: translate3d(0,-20px,0);}
  100% {-webkit-transform: translate3d(0,0,0);transform: translate3d(0,0,0);}
}
@keyframes jumpThree {
  0%   {-webkit-transform: translate3d(0,0,0);transform: translate3d(0,0,0);}
  40%  {-webkit-transform: translate3d(0,-20px,0);transform: translate3d(0,-20px,0);}
  100% {-webkit-transform: translate3d(0,0,0);transform: translate3d(0,0,0);}
}

@-webkit-keyframes rotated {
  0%   {-webkit-transform: rotate(0);transform: rotate(0);}
  100% {-webkit-transform: rotate(360deg);transform: rotate(360deg);}
}
@keyframes rotated {
  0%   {-webkit-transform: rotate(0);transform: rotate(0);}
  100% {-webkit-transform: rotate(360deg);transform: rotate(360deg);}
}

@-webkit-keyframes rotatedTwo {
  0%   {-webkit-transform: rotate(0);transform: rotate(0);}
  100% {-webkit-transform: rotate(-360deg);transform: rotate(-360deg);}
}
@keyframes rotatedTwo {
  0%   {-webkit-transform: rotate(0);transform: rotate(0);}
  100% {-webkit-transform: rotate(-360deg);transform: rotate(-360deg);}
}

@-webkit-keyframes rotatedHalf {
  0%   {-webkit-transform: rotate(0);transform: rotate(0);}
  50% {-webkit-transform: rotate(90deg);transform: rotate(90deg);}
  100%   {-webkit-transform: rotate(0);transform: rotate(0);}
}
@keyframes rotatedHalf {
  0%   {-webkit-transform: rotate(0);transform: rotate(0);}
  50% {-webkit-transform: rotate(90deg);transform: rotate(90deg);}
  100%   {-webkit-transform: rotate(0);transform: rotate(0);}
}

@-webkit-keyframes rotatedHalfTwo {
  0% {-webkit-transform: rotate(-90deg);transform: rotate(-90deg);}
  100%   {-webkit-transform: rotate(90deg);transform: rotate(90deg);}
}
@keyframes rotatedHalfTwo {
  0% {-webkit-transform: rotate(-90deg);transform: rotate(-90deg);}
  100%   {-webkit-transform: rotate(90deg);transform: rotate(90deg);}
}


@-webkit-keyframes scale-upOne {
  0%   {-webkit-transform: scale(1);transform: scale(1);}
  50% {-webkit-transform: scale(0.85);transform: scale(0.85);}
  100%  {-webkit-transform: scale(1);transform: scale(1);}
}
@keyframes scale-upOne {
  0%   {-webkit-transform: scale(1);transform: scale(1);}
  50% {-webkit-transform: scale(0.85);transform: scale(0.85);}
  100%  {-webkit-transform: scale(1);transform: scale(1);}
}

@-webkit-keyframes scale-right {
  0%  {-webkit-transform: translateX(0%);transform: translateX(0%);}
  50% {-webkit-transform: translateX(20%);transform: translateX(20%);}
  100% {-webkit-transform: translateX(0%);transform: translateX(0%);}
}
@keyframes scale-right {
  0%  {-webkit-transform: translateX(0%);transform: translateX(0%);}
  50% {-webkit-transform: translateX(20%);transform: translateX(20%);}
  100% {-webkit-transform: translateX(0%);transform: translateX(0%);}
}

@-webkit-keyframes fade-in {
  0%   {opacity: 0.7;}
  40%  {opacity: 1;}
  100% {opacity: 0.7;}
}
@keyframes fade-in {
  0%   {opacity: 0.7;}
  40%  {opacity: 1;}
  100% {opacity: 0.7;}
}
@-webkit-keyframes fade-in-rev {
  0%   {opacity: 1;}
  40%  {opacity: 0.7;}
  100% {opacity: 1;}
}
@keyframes fade-in-rev {
  0%   {opacity: 1;}
  40%  {opacity: 0.7;}
  100% {opacity: 1;}
}

@-webkit-keyframes hvr-ripple-out {
  0% {
    top: -5px;
    right: -5px;
    bottom: -5px;
    left: -5px;
  }
  50% {
    top: -15px;
    right: -15px;
    bottom: -15px;
    left: -15px;
    opacity: 0.8;
  }

  100% {
    top: -5px;
    right: -5px;
    bottom: -5px;
    left: -5px;
    opacity: 1;
  }
}

@keyframes hvr-ripple-out {
  0% {
    top: -5px;
    right: -5px;
    bottom: -5px;
    left: -5px;
  }
  50% {
    top: -15px;
    right: -15px;
    bottom: -15px;
    left: -15px;
    opacity: 0.8;
  }

  100% {
    top: -5px;
    right: -5px;
    bottom: -5px;
    left: -5px;
    opacity: 1;
  }
}
@-webkit-keyframes hvr-ripple-out-two {
  0% {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  100% {
    top: -30px;
    right: -30px;
    bottom: -30px;
    left: -30px;
    opacity: 0;
  }
}
@keyframes hvr-ripple-out-two {
  0% {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  100% {
    top: -30px;
    right: -30px;
    bottom: -30px;
    left: -30px;
    opacity: 0;
  }
}

@-webkit-keyframes scale-up-one {
  0%   {-webkit-transform: scale(1);transform: scale(1);}
  40%  {-webkit-transform: scale(0.5);transform: scale(0.5);}
  100% {-webkit-transform: scale(1);transform: scale(1);}
}
@keyframes scale-up-one {
  0%   {-webkit-transform: scale(1);transform: scale(1);}
  40%  {-webkit-transform: scale(0.5);transform: scale(0.5);}
  100% {-webkit-transform: scale(1);transform: scale(1);}
}
@-webkit-keyframes scale-up-two {
  0%   {-webkit-transform: scale(0.5);transform: scale(0.5);}
  40%  {-webkit-transform: scale(0.8);transform: scale(0.8);}
  100% {-webkit-transform: scale(0.5);transform: scale(0.5);}
}
@keyframes scale-up-two {
  0%   {-webkit-transform: scale(0.5);transform: scale(0.5);}
  40%  {-webkit-transform: scale(0.8);transform: scale(0.8);}
  100% {-webkit-transform: scale(0.5);transform: scale(0.5);}
}
@-webkit-keyframes scale-up-three {
  0%   {-webkit-transform: scale(1);transform: scale(1);}
  40%  {-webkit-transform: scale(2);transform: scale(2);}
  100% {-webkit-transform: scale(1);transform: scale(1);}
}
@keyframes scale-up-three {
  0%   {-webkit-transform: scale(1);transform: scale(1);}
  40%  {-webkit-transform: scale(2);transform: scale(2);}
  100% {-webkit-transform: scale(1);transform: scale(1);}
}


@-webkit-keyframes jump5p {
  0%   {-webkit-transform: translate3d(0,0,0);transform: translate3d(0,0,0);}
  40%  {-webkit-transform: translate3d(0,5px,0);transform: translate3d(0,5px,0);}
  100% {-webkit-transform: translate3d(0,0,0);transform: translate3d(0,0,0);}
}
@keyframes jump5p {
  0%   {-webkit-transform: translate3d(0,0,0);transform: translate3d(0,0,0);}
  40%  {-webkit-transform: translate3d(0,5px,0);transform: translate3d(0,5px,0);}
  100% {-webkit-transform: translate3d(0,0,0);transform: translate3d(0,0,0);}
}

@-webkit-keyframes jump5pRsv {
  0%   {-webkit-transform: translate3d(0,0,0);transform: translate3d(0,0,0);}
  40%  {-webkit-transform: translate3d(0,-5px,0);transform: translate3d(0,-5px,0);}
  100% {-webkit-transform: translate3d(0,0,0);transform: translate3d(0,0,0);}
}
@keyframes jump5pRsv {
  0%   {-webkit-transform: translate3d(0,0,0);transform: translate3d(0,0,0);}
  40%  {-webkit-transform: translate3d(0,-5px,0);transform: translate3d(0,-5px,0);}
  100% {-webkit-transform: translate3d(0,0,0);transform: translate3d(0,0,0);}
}

@-webkit-keyframes jump10p {
  0%   {-webkit-transform: translate3d(0,0,0);transform: translate3d(0,0,0);}
  40%  {-webkit-transform: translate3d(0,10px,0);transform: translate3d(0,10px,0);}
  100% {-webkit-transform: translate3d(0,0,0);transform: translate3d(0,0,0);}
}
@keyframes jump10p {
  0%   {-webkit-transform: translate3d(0,0,0);transform: translate3d(0,0,0);}
  40%  {-webkit-transform: translate3d(0,10px,0);transform: translate3d(0,10px,0);}
  100% {-webkit-transform: translate3d(0,0,0);transform: translate3d(0,0,0);}
}

@-webkit-keyframes jump10pRsv {
  0%   {-webkit-transform: translate3d(0,0,0);transform: translate3d(0,0,0);}
  40%  {-webkit-transform: translate3d(0,-10px,0);transform: translate3d(0,-10px,0);}
  100% {-webkit-transform: translate3d(0,0,0);transform: translate3d(0,0,0);}
}
@keyframes jump10pRsv {
  0%   {-webkit-transform: translate3d(0,0,0);transform: translate3d(0,0,0);}
  40%  {-webkit-transform: translate3d(0,-10px,0);transform: translate3d(0,-10px,0);}
  100% {-webkit-transform: translate3d(0,0,0);transform: translate3d(0,0,0);}
}
