*{margin:0;padding:0;-webkit-box-sizing: border-box;-moz-box-sizing: border-box;box-sizing: border-box;}
html,body,div,span,applet,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,abbr,acronym,address,big,cite,code,dfn,em,img,ins,kbd,q,s,samp,small,strike,strong,sub,sup,tt,var,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td{vertical-align:baseline;text-decoration:none;margin:0;padding:0;}
ol,ul {list-style-type: none;}
a {text-decoration: none; display: inline-block; color: inherit;}
a:hover,a:focus,a:visited {text-decoration: none; outline: none;}
img {width: auto; max-width: 100%; display: block;}
button {border:none;outline: none;box-shadow: none;display: block; padding: 0;cursor: pointer;background: transparent;}
button:focus {outline: none;}
input,textarea {outline: none; box-shadow: none;transition: all 0.3s ease-in-out;}
audio,video,canvas {max-width: 100%;}
iframe {border: none !important;}

/* Some Seperate css */
::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-thumb {
  background: var(--brand-color);
  border-radius: 0;
}

::-webkit-scrollbar-track {
  background: #fff;
}

::selection {
  background-color: var(--brand-color);
  color: #fff;
}
body{
  font-size: 18px;
  position: relative;
  line-height: 1.7em;
  color: var(--text-color);
  background: transparent;
}
ol,
ul {
    list-style-type: none;
}

a {
    text-decoration: none !important;
    display: inline-block;
}

.h1,
h1,
.h2,
h2,
.h3,
h3,
.h4,
h4,
.h5,
h5,
.h6,
h6 {
    color: var(--heading);
    font-weight: normal;
}

.h1,
h1 {
    font-weight: 600;
}

.h2,
h2 {
    font-size: 52px;
    line-height: 1.1em;
}

.h3,
h3 {
    font-size: 36px;
}

.h5,
h5 {
    font-size: 20px;
}

.h6,
h6 {
    font-size: 15px;
}

.font-rubik {
    font-family: 'Montserrat', sans-serif;
}

.font-slab {
    font-family: 'Montserrat', sans-serif;
}

.font-gilroy-bold {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
}

.main-page-wrapper {
    overflow-x: hidden;
    padding-top: 150px;
}
/* Seperate Css End */

.p0 {padding: 0 !important;}
.m0 {margin:  0 !important;}


.pt-5   {padding-top: 5px !important;}
.pt-10  {padding-top: 10px;}
.pt-15  {padding-top: 15px;}
.pt-20  {padding-top: 20px;}
.pt-25  {padding-top: 25px;}
.pt-30  {padding-top: 30px;}
.pt-35  {padding-top: 35px;}
.pt-40  {padding-top: 40px;}
.pt-45  {padding-top: 45px;}
.pt-50  {padding-top: 50px;}
.pt-55  {padding-top: 55px;}
.pt-60  {padding-top: 60px;}
.pt-65  {padding-top: 65px;}
.pt-70  {padding-top: 70px;}
.pt-75  {padding-top: 75px;}
.pt-80  {padding-top: 80px;}
.pt-85  {padding-top: 85px;}
.pt-90  {padding-top: 90px;}
.pt-95  {padding-top: 95px;}
.pt-100 {padding-top: 100px;}
.pt-110 {padding-top: 110px;}
.pt-120 {padding-top: 120px;}
.pt-130 {padding-top: 130px;}
.pt-140 {padding-top: 140px;}
.pt-150 {padding-top: 150px;}
.pt-160 {padding-top: 160px;}
.pt-170 {padding-top: 170px;}
.pt-180 {padding-top: 180px;}
.pt-190 {padding-top: 190px;}
.pt-200 {padding-top: 200px;}
.pt-225 {padding-top: 225px;}
.pt-250 {padding-top: 250px;}
.pt-300 {padding-top: 300px;}
.pt-350 {padding-top: 350px;}

.pb-5   {padding-bottom: 5px !important;}
.pb-10  {padding-bottom: 10px;}
.pb-15  {padding-bottom: 15px;}
.pb-20  {padding-bottom: 20px;}
.pb-25  {padding-bottom: 25px;}
.pb-30  {padding-bottom: 30px;}
.pb-35  {padding-bottom: 35px;}
.pb-40  {padding-bottom: 40px;}
.pb-45  {padding-bottom: 45px;}
.pb-50  {padding-bottom: 50px;}
.pb-55  {padding-bottom: 55px;}
.pb-60  {padding-bottom: 60px;}
.pb-65  {padding-bottom: 65px;}
.pb-70  {padding-bottom: 70px;}
.pb-75  {padding-bottom: 75px;}
.pb-80  {padding-bottom: 80px;}
.pb-85  {padding-bottom: 85px;}
.pb-90  {padding-bottom: 90px;}
.pb-95  {padding-bottom: 95px;}
.pb-100 {padding-bottom: 100px;}
.pb-110 {padding-bottom: 110px;}
.pb-120 {padding-bottom: 120px;}
.pb-130 {padding-bottom: 130px;}
.pb-140 {padding-bottom: 140px;}
.pb-150 {padding-bottom: 150px;}
.pb-160 {padding-bottom: 160px;}
.pb-170 {padding-bottom: 170px;}
.pb-180 {padding-bottom: 180px;}
.pb-190 {padding-bottom: 190px;}
.pb-200 {padding-bottom: 200px;}
.pb-225 {padding-bottom: 225px;}
.pb-250 {padding-bottom: 250px;}
.pb-300 {padding-bottom: 300px;}
.pb-350 {padding-bottom: 350px;}

.mt-5   {margin-top: 5px !important;}
.mt-10  {margin-top: 10px;}
.mt-15  {margin-top: 15px;}
.mt-20  {margin-top: 20px;}
.mt-25  {margin-top: 25px;}
.mt-30  {margin-top: 30px;}
.mt-35  {margin-top: 35px;}
.mt-40  {margin-top: 40px;}
.mt-45  {margin-top: 45px;}
.mt-50  {margin-top: 50px;}
.mt-55  {margin-top: 55px;}
.mt-60  {margin-top: 60px;}
.mt-65  {margin-top: 65px;}
.mt-70  {margin-top: 70px;}
.mt-75  {margin-top: 75px;}
.mt-80  {margin-top: 80px;}
.mt-85  {margin-top: 85px;}
.mt-90  {margin-top: 90px;}
.mt-95  {margin-top: 95px;}
.mt-100 {margin-top: 100px;}
.mt-110 {margin-top: 110px;}
.mt-120 {margin-top: 120px;}
.mt-130 {margin-top: 130px;}
.mt-140 {margin-top: 140px;}
.mt-150 {margin-top: 150px;}
.mt-160 {margin-top: 160px;}
.mt-170 {margin-top: 170px;}
.mt-180 {margin-top: 180px;}
.mt-190 {margin-top: 190px;}
.mt-200 {margin-top: 200px;}
.mt-225 {margin-top: 225px;}
.mt-250 {margin-top: 250px;}
.mt-300 {margin-top: 300px;}
.mt-350 {margin-top: 350px;}


.mb-5   {margin-bottom: 5px !important;}
.mb-10  {margin-bottom: 10px;}
.mb-15  {margin-bottom: 15px;}
.mb-20  {margin-bottom: 20px;}
.mb-25  {margin-bottom: 25px;}
.mb-30  {margin-bottom: 30px;}
.mb-35  {margin-bottom: 35px;}
.mb-40  {margin-bottom: 40px;}
.mb-45  {margin-bottom: 45px;}
.mb-50  {margin-bottom: 50px;}
.mb-55  {margin-bottom: 55px;}
.mb-60  {margin-bottom: 60px;}
.mb-65  {margin-bottom: 65px;}
.mb-70  {margin-bottom: 70px;}
.mb-75  {margin-bottom: 75px;}
.mb-80  {margin-bottom: 80px;}
.mb-85  {margin-bottom: 85px;}
.mb-90  {margin-bottom: 90px;}
.mb-95  {margin-bottom: 95px;}
.mb-100 {margin-bottom: 100px;}
.mb-110 {margin-bottom: 110px;}
.mb-120 {margin-bottom: 120px;}
.mb-130 {margin-bottom: 130px;}
.mb-140 {margin-bottom: 140px;}
.mb-150 {margin-bottom: 150px;}
.mb-160 {margin-bottom: 160px;}
.mb-170 {margin-bottom: 170px;}
.mb-180 {margin-bottom: 180px;}
.mb-190 {margin-bottom: 190px;}
.mb-200 {margin-bottom: 200px;}
.mb-225 {margin-bottom: 225px;}
.mb-250 {margin-bottom: 250px;}
.mb-300 {margin-bottom: 300px;}
.mb-350 {margin-bottom: 350px;}




@media (max-width: 991px) {
  .md-p0 {padding: 0 !important;}
  .md-m0 {margin:  0 !important;}

  .md-pt-10  {padding-top: 10px !important;}
  .md-pt-20  {padding-top: 20px !important;}
  .md-pt-30  {padding-top: 30px !important;}
  .md-pt-40  {padding-top: 40px !important;}
  .md-pt-50  {padding-top: 50px !important;}
  .md-pt-60  {padding-top: 60px !important;}
  .md-pt-70  {padding-top: 70px !important;}
  .md-pt-80  {padding-top: 80px !important;}
  .md-pt-90  {padding-top: 90px !important;}
  .md-pt-100 {padding-top: 100px !important;}
  .md-pt-110 {padding-top: 110px !important;}
  .md-pt-120 {padding-top: 120px !important;}
  .md-pt-130 {padding-top: 130px !important;}
  .md-pt-140 {padding-top: 140px !important;}
  .md-pt-150 {padding-top: 150px !important;}
  .md-pt-200 {padding-top: 200px !important;}

  .md-pb-10  {padding-bottom: 10px !important;}
  .md-pb-20  {padding-bottom: 20px !important;}
  .md-pb-30  {padding-bottom: 30px !important;}
  .md-pb-40  {padding-bottom: 40px !important;}
  .md-pb-50  {padding-bottom: 50px !important;}
  .md-pb-60  {padding-bottom: 60px !important;}
  .md-pb-70  {padding-bottom: 70px !important;}
  .md-pb-80  {padding-bottom: 80px !important;}
  .md-pb-90  {padding-bottom: 90px !important;}
  .md-pb-100 {padding-bottom: 100px !important;}
  .md-pb-110 {padding-bottom: 110px !important;}
  .md-pb-120 {padding-bottom: 120px !important;}
  .md-pb-130 {padding-bottom: 130px !important;}
  .md-pb-140 {padding-bottom: 140px !important;}
  .md-pb-150 {padding-bottom: 150px !important;}
  .md-pb-200 {padding-bottom: 200px !important;}

  .md-mt-10  {margin-top: 10px !important;}
  .md-mt-20  {margin-top: 20px !important;}
  .md-mt-30  {margin-top: 30px !important;}
  .md-mt-40  {margin-top: 40px !important;}
  .md-mt-50  {margin-top: 50px !important;}
  .md-mt-60  {margin-top: 60px !important;}
  .md-mt-70  {margin-top: 70px !important;}
  .md-mt-80  {margin-top: 80px !important;}
  .md-mt-90  {margin-top: 90px !important;}
  .md-mt-100 {margin-top: 100px !important;}
  .md-mt-110 {margin-top: 110px !important;}
  .md-mt-120 {margin-top: 120px !important;}
  .md-mt-130 {margin-top: 130px !important;}
  .md-mt-140 {margin-top: 140px !important;}
  .md-mt-150 {margin-top: 150px !important;}
  .md-mt-200 {margin-top: 200px !important;}

  .md-mb-10  {margin-bottom: 10px !important;}
  .md-mb-20  {margin-bottom: 20px !important;}
  .md-mb-30  {margin-bottom: 30px !important;}
  .md-mb-40  {margin-bottom: 40px !important;}
  .md-mb-50  {margin-bottom: 50px !important;}
  .md-mb-60  {margin-bottom: 60px !important;}
  .md-mb-70  {margin-bottom: 70px !important;}
  .md-mb-80  {margin-bottom: 80px !important;}
  .md-mb-90  {margin-bottom: 90px !important;}
  .md-mb-100 {margin-bottom: 100px !important;}
  .md-mb-110 {margin-bottom: 110px !important;}
  .md-mb-120 {margin-bottom: 120px !important;}
  .md-mb-130 {margin-bottom: 130px !important;}
  .md-mb-140 {margin-bottom: 140px !important;}
  .md-mb-150 {margin-bottom: 150px !important;}
  .md-mb-200 {margin-bottom: 200px !important;}
} 
/*(max-width: 991px)*/

@media (max-width: 767px) {
  .sm-p0 {padding: 0 !important;}
  .sm-m0 {margin:  0 !important;}

  .sm-pt-10  {padding-top: 10px !important;}
  .sm-pt-20  {padding-top: 20px !important;}
  .sm-pt-30  {padding-top: 30px !important;}
  .sm-pt-40  {padding-top: 40px !important;}
  .sm-pt-50  {padding-top: 50px !important;}
  .sm-pt-60  {padding-top: 60px !important;}
  .sm-pt-70  {padding-top: 70px !important;}
  .sm-pt-80  {padding-top: 80px !important;}
  .sm-pt-90  {padding-top: 90px !important;}
  .sm-pt-100 {padding-top: 100px !important;}
  .sm-pt-110 {padding-top: 110px !important;}
  .sm-pt-120 {padding-top: 120px !important;}
  .sm-pt-130 {padding-top: 130px !important;}
  .sm-pt-140 {padding-top: 140px !important;}
  .sm-pt-150 {padding-top: 150px !important;}
  .sm-pt-200 {padding-top: 200px !important;}

  .sm-pb-10  {padding-bottom: 10px !important;}
  .sm-pb-20  {padding-bottom: 20px !important;}
  .sm-pb-30  {padding-bottom: 30px !important;}
  .sm-pb-40  {padding-bottom: 40px !important;}
  .sm-pb-50  {padding-bottom: 50px !important;}
  .sm-pb-60  {padding-bottom: 60px !important;}
  .sm-pb-70  {padding-bottom: 70px !important;}
  .sm-pb-80  {padding-bottom: 80px !important;}
  .sm-pb-90  {padding-bottom: 90px !important;}
  .sm-pb-100 {padding-bottom: 100px !important;}
  .sm-pb-110 {padding-bottom: 110px !important;}
  .sm-pb-120 {padding-bottom: 120px !important;}
  .sm-pb-130 {padding-bottom: 130px !important;}
  .sm-pb-140 {padding-bottom: 140px !important;}
  .sm-pb-150 {padding-bottom: 150px !important;}
  .sm-pb-200 {padding-bottom: 200px !important;}

  .sm-mt-10  {margin-top: 10px !important;}
  .sm-mt-20  {margin-top: 20px !important;}
  .sm-mt-30  {margin-top: 30px !important;}
  .sm-mt-40  {margin-top: 40px !important;}
  .sm-mt-50  {margin-top: 50px !important;}
  .sm-mt-60  {margin-top: 60px !important;}
  .sm-mt-70  {margin-top: 70px !important;}
  .sm-mt-80  {margin-top: 80px !important;}
  .sm-mt-90  {margin-top: 90px !important;}
  .sm-mt-100 {margin-top: 100px !important;}
  .sm-mt-110 {margin-top: 110px !important;}
  .sm-mt-120 {margin-top: 120px !important;}
  .sm-mt-130 {margin-top: 130px !important;}
  .sm-mt-140 {margin-top: 140px !important;}
  .sm-mt-150 {margin-top: 150px !important;}
  .sm-mt-200 {margin-top: 200px !important;}

  .sm-mb-10  {margin-bottom: 10px !important;}
  .sm-mb-20  {margin-bottom: 20px !important;}
  .sm-mb-30  {margin-bottom: 30px !important;}
  .sm-mb-40  {margin-bottom: 40px !important;}
  .sm-mb-50  {margin-bottom: 50px !important;}
  .sm-mb-60  {margin-bottom: 60px !important;}
  .sm-mb-70  {margin-bottom: 70px !important;}
  .sm-mb-80  {margin-bottom: 80px !important;}
  .sm-mb-90  {margin-bottom: 90px !important;}
  .sm-mb-100 {margin-bottom: 100px !important;}
  .sm-mb-110 {margin-bottom: 110px !important;}
  .sm-mb-120 {margin-bottom: 120px !important;}
  .sm-mb-130 {margin-bottom: 130px !important;}
  .sm-mb-140 {margin-bottom: 140px !important;}
  .sm-mb-150 {margin-bottom: 150px !important;}
  .sm-mb-200 {margin-bottom: 200px !important;}
}
/*(max-width: 767px)*/