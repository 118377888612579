:root {
    --ui-dark: #0F172A;
    --ui-text: #202C40;
    --ui-green: #1155ED;
    --ui-green2: #00AC47;
    --ui-blue: #1877F2;
    --ui-blue2: #3283FC;
    --ui-grey: #CBD5E1;
    --ui-grey-600: #475569;
    --ui-grey-400: #94A3B8;
    --ui-red: #FE2C25;
    --ui-orange: #FFBB00;
    --ui-font-regular: '400';
    --ui-font-medium: '500';
    --ui-font-bold: '700';
    /* LAnding Page Variables */
    --text-color: #73737B;
    --text-light: #888888;
    /* --heading: #101621; */
    --heading: #050505;
    --blue-dark: #6F55FF;
    --red-light: #FD6A5E;
    --yellow-deep: #FFB840;
    --blue-light: #3BB0D7;
    /* --purple-blue: #655C97; */
    --purple-blue: #082A66;
    --theme-grey: #E6F1FF;
    --vin-red: #973C56;
    --brand-color: #1155ED;
    --animate-duration: 1s;
    --animate-delay: 1s;
    --animate-repeat: 1;
}


/* Importing Fonts */

@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Display:wght@400;500;600;700;800;900&display=swap');
.bg-green {
    background-color: var(--ui-green) !important;
}

.text-green {
    color: var(--ui-green) !important;
}

.text-error {
    color: #E22357;
}

* {
    box-sizing: border-box;
}

body {
    margin: 0;
    padding: 0;
    background: var(--ui-text);
    font-family: 'Red Hat Display', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
li {
    margin: 0;
    padding: 0;
}

img {
    width: 100%;
    height: auto;
}

a,
button,
a:hover,
button:hover {
    transition: .3s all ease;
}


/* Customize Bootsrap Container */

@media (min-width: 1230px) {
    .container {
        max-width: 1202px;
    }
}


/* Adding Fonts */


/* @font-face {
    font-family: 'Objectivity Regular';
    src: url(../fonts/objectivity.regular.otf);
}

@font-face {
    font-family: 'Objectivity Medium';
    src: url(../fonts/Objectivity-Medium.otf);
}

@font-face {
    font-family: 'Objectivity Bold';
    src: url(../fonts/objectivity.bold.otf);
} */

.section_link a {
    font-weight: 600;
    font-size: 20px;
    letter-spacing: -0.02em;
    border-bottom: 1px solid transparent;
    padding-bottom: 4px;
    padding: 0 5px;
}

.section_link a.active,
.section_link a:hover {
    border-bottom: 1px solid var(--ui-green);
    color: var(--ui-green);
    font-weight: 700;
}


/* Site Loader */

.site-loader {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 2;
}

.site-loader span {
    position: absolute;
    display: inline-block;
    width: 100px;
    height: 100px;
    border-radius: 100%;
    background: var(--ui-green);
    -webkit-animation: site-loader 1.5s linear infinite;
    animation: site-loader 1.5s linear infinite;
}

.site-loader span:last-child {
    animation-delay: -0.9s;
    -webkit-animation-delay: -0.9s;
}


/* Site Loader Animation */

@keyframes site-loader {
    0% {
        transform: scale(0, 0);
        opacity: 0.8;
    }
    100% {
        transform: scale(1, 1);
        opacity: 0;
    }
}

@-webkit-keyframes site-loader {
    0% {
        -webkit-transform: scale(0, 0);
        opacity: 0.8;
    }
    100% {
        -webkit-transform: scale(1, 1);
        opacity: 0;
    }
}

#cardOptions::after {
    content: none;
}


/* Site Header */

header .site-header {
    background: #fff;
    /* box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.06); */
    border-bottom: 1px solid var(--ui-grey);
}

@media(min-width: 768px) {
    header .site-header {
        padding: 0 3%;
    }
}

.site-header nav {
    padding: 5px 0;
}

.site-header nav ul li a {
    opacity: 1;
    font-weight: 500;
}

.site-header nav ul li a:not(.btn) {
    color: var(--ui-text) !important;
    margin-left: 13px;
    position: relative;
    font-size: 14px;
}

.site-header nav ul li a.active {
    color: var(--ui-green) !important;
    font-weight: 700;
}

.site-header nav ul li a.btn {
    font-size: 16px;
    padding: 7px 23px !important;
    border: 1px solid var(--ui-green);
    border-radius: 4px;
    color: var(--ui-green) !important;
}

.site-header nav ul li a.btn:hover {
    background: var(--ui-green);
    color: #fff !important;
}

.site-header nav ul li a.sign-up {
    margin: 20px 0 0;
    border-color: var(--ui-green);
    background: var(--ui-green);
    color: #fff !important;
}

@media (min-width: 576px) {
    .site-header nav ul li a.sign-up {
        margin: 0 0 0 20px;
    }
}

.site-header nav ul li a.sign-up:hover {
    background: transparent;
    color: var(--ui-green) !important;
}


/* Dashbaord Nav */

.site-header.dashboard button.navbar-toggler {
    position: absolute;
    right: 0;
}

.site-header nav ul li a:not(.btn)::before {
    content: '';
    position: absolute;
    background: var(--ui-green);
    height: 2px;
    width: 0;
    border-radius: 30px;
    left: 0;
    right: 0;
    bottom: 4px;
    margin: auto;
    transition: .3s all ease;
}

.site-header nav ul li.active a::before,
.site-header nav ul li a:hover::before {
    width: 40px;
    transition: .3s all ease;
}

.site-header .dropdown button {
    box-shadow: none;
    position: relative;
}

@media(min-width: 768px) {
    button#dropdownNotification {
        margin: 0 29px 0 22px;
    }
}

button#dropdownNotification img {
    width: 26px;
    height: 26px;
}

button#dropdownNotification::after {
    content: unset;
}

button#dropdownProfile {
    display: flex;
    align-items: center;
    text-align: left;
    font-size: 14px;
    color: var(--ui-dark);
    font-weight: 500;
}

button#dropdownProfile i {
    font-size: 10px;
}

@media (min-width: 768px) {
    button#dropdownProfile {
        min-width: 135px;
    }
}

button#dropdownProfile::after {
    display: none;
}

button#dropdownProfile img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
}

button#dropdownProfile+.dropdown-menu {
    left: -40%;
}

@media (max-width: 991px) {
    .site-header.dashboard nav {
        padding-right: 70px;
    }
    .site-header .navbar-collapse {
        position: absolute;
        top: 70px;
        background: #fff;
        left: 0px;
        right: 0;
        padding: 20px;
        z-index: 10;
        border-radius: 0 0 10px 10px;
        box-shadow: 0px 6px 30px rgb(0 0 0 / 6%);
    }
    .site-header .noti-drop {
        margin-left: auto;
    }
}

@media (max-width: 767px) {
    .site-header nav ul li a:not(.btn)::before {
        left: 0;
        right: unset;
    }
    .site-header .noti-drop {
        margin-right: 15px;
    }
    .site-header {
        position: unset;
    }
    .site-header.dashboard button.navbar-toggler {
        right: 15px;
    }
    .site-header .navbar-collapse {
        top: 56px;
    }
    .site-header nav ul li a:not(.btn) {
        margin-left: 15px;
    }
}

@media (max-width: 420px) {
    .site-header .navbar-brand img {
        max-width: 120px;
    }
    button#dropdownProfile img {
        width: 40px;
        height: 40px;
    }
}

@media (max-width: 360px) {
    .site-header .noti-drop {
        display: none;
    }
}


/* Layout Login SignUp Page */

section.site-main {
    position: relative;
}

section.site-main .shape {
    position: absolute;
    z-index: -1;
}

section.site-main .shape.left {
    width: 70%;
    height: 100%;
    top: 3%;
    left: -15%;
}

section.site-main .shape.right {
    right: 0;
}

section.site-main .main-row {
    padding: 50px 15px;
}

@media (min-width: 768px) {
    section.site-main .main-row {
        min-height: calc(100vh - 164px);
        padding: 0 0;
    }
}

section.site-main .page-col {
    max-width: 570px;
    margin: auto;
    width: 100%;
}

section.site-main .profile .page-col {
    max-width: 600px;
    padding: 30px 0;
    /* padding: 30px 30px; */
    /* box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.06); */
    border-radius: 5px;
}

@media (max-width: 767px) {
    section.site-main .profile .page-col {
        padding: 30px 0;
    }
}

.section-title h1 {
    position: relative;
    display: inline-block;
}

.section-title h1::before {
    content: '';
    position: absolute;
    width: calc(100% - 13px);
    height: 2px;
    left: 0;
    bottom: -8px;
    background: var(--ui-dark);
}

.section-title h1::after {
    content: '';
    position: absolute;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    right: 0;
    bottom: -10px;
    background: var(--ui-green);
}

.section-title p {
    color: var(--ui-text);
    margin: 14px 0 0;
}

.page-col .form-col {
    padding-top: 40px;
    padding-bottom: 30px;
}


/* .page-col .form-col label {
    color: var(--ui-grey-600);
}

.page-col .form-col input {
    height: 50px;
    color: var(--ui-grey-400);
    border-color: var(--ui-grey);
    border-radius: 4px;
}

.page-col .form-col input::placeholder {
    color: var(--ui-grey-400);
} */

.page-col .form-col input[type="submit"] {
    background: var(--ui-green);
    color: #fff;
    transition: .3s all ease;
    border-color: var(--ui-green);
    height: 50px;
}

.page-col .form-col input[type="submit"]:hover {
    background: transparent;
    color: var(--ui-green);
    transition: .3s all ease;
}

.page-col .sign-with-client {
    border-bottom: 1px solid var(--ui-grey);
    position: relative;
    padding: 40px 0 25px;
}

.sign-with-client p {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    width: fit-content;
    bottom: -14px;
    background: #fff;
    padding: 0 9px;
}

.sign-with-client button {
    font-size: 14px;
    color: var(--ui-text);
    border: 1px solid var(--ui-grey);
    border-radius: 5px;
    width: 100%;
    padding: 13px 10px;
    text-align: center;
}

.sign-with-client button img {
    max-width: 19px;
    margin-right: 10px;
    display: inline-block;
    vertical-align: middle;
}

.sign-with-client button img[alt="facebook"] {
    max-width: 10px;
}


/* ========================================== create Profile Page ======================= */

.form-col .profile-img {
    width: 130px;
    height: 130px;
    margin: 0 auto 50px;
}

.form-col .profile-img img {
    height: 100%;
}

.form-col .profile-img label {
    background: #fff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.1);
    width: 30px;
    height: 30px;
    position: absolute;
    margin: 0;
    bottom: 13px;
    right: 13px;
    cursor: pointer;
}

.form-col .profile-img label input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}

.form-col .urlField label {
    position: relative;
    transform: translate(-9px, 14px) scale(0.75);
    font-weight: 700;
    color: var(--ui-grey-600);
    z-index: 1;
    background: #fff;
    font-size: 16px;
    padding: 0 5px;
}

.form-col .urlField input {
    height: 50px;
    border-left: 0;
    font-weight: 500;
    font-size: 14px;
    color: var(--ui-dark);
    padding-left: 0;
    outline: 0;
    box-shadow: none;
    border-color: rgba(0, 0, 0, 0.23);
}

.form-col .urlField .input-group-prepend span {
    background: transparent;
    font-weight: 500;
    font-size: 14px;
    color: var(--ui-dark);
    padding-right: 0;
}

.form-col .slect-with-icon select {
    padding-left: 48px;
    height: 50px;
}

.form-col .slect-with-icon img,
.form-col .slect-with-icon i {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 17px;
    height: 17px;
    left: 20px;
    margin: auto;
}


/* @media (min-width: 768px) {
    .sign-with-client button {
        width: 48%;
    }
    .sign-with-client button:last-of-type {
        margin-top: 0;
    }
} */

@media (max-width: 576px) {
    div#navbarNav {
        padding: 30px 0;
    }
    .section-title h1 {
        font-size: 30px;
    }
}

@media (max-width: 390px) {
    .section-title h1 {
        font-size: 24px;
    }
    .section-title p {
        font-size: 14px;
    }
}


/* Register Page */

.form-col .label-top label {
    position: absolute;
    color: var(--ui-grey-600);
    font-weight: 700;
    font-size: 12px;
    margin: auto;
    background: #fff;
    top: -7px;
    left: 9px;
    padding: 0 4px;
    line-height: 1.0;
}

.form-col .label-top input {
    color: var(--ui-dark);
    font-weight: 500;
    font-size: 14px;
}

.form-col .pwd-field-eye button {
    position: absolute;
    color: var(--ui-text);
    right: 20px;
    top: 0;
    bottom: 0;
    margin: auto;
    height: fit-content;
    line-height: 1.0;
}

span.pwd-error {
    display: block;
    margin: -12px 0 12px;
    font-size: 14px;
    color: #E22357;
}

.form-col .pwd-power span {
    border-radius: 50px;
    height: 4px;
    width: 18%;
    background-color: rgb(204, 216, 219);
}

@media (max-width: 767px) {
    .form-col .forget-pwd {
        flex-direction: column;
    }
}

.form-col .forget-pwd {}

.form-col .forget-pwd label,
.form-col .forget-pwd a {
    text-decoration: none;
}

.form-col .forget-pwd a {
    color: var(--ui-text);
    font-weight: 500;
    font-size: 14px;
}

.page-content a.reset-pwd-btn {
    background: var(--ui-green);
    font-weight: var(--ui-font-medium);
    color: #fff;
    width: 100%;
    border: 1px solid var(--ui-green);
    padding: 12px 10px;
}

.page-content a.reset-pwd-btn:hover {
    background: transparent;
    color: var(--ui-green);
}


/* .rec_pass h1 {
    letter-spacing: -0.02em;
    font-size: 24px;
    color: var(--ui-text);
} */

.rec_pass h1::before,
.rec_pass h1::after {
    content: none;
}


/* Button CSS Change for Connect Calendar Page */

.page-content.connect-calnder .client-btn button {
    position: relative;
}

.page-content.connect-calnder .client-btn button img {
    max-width: 22px !important;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 12px;
    margin: auto;
    height: fit-content;
}


/* Skip These Steps */

.page-content .skip-this a {
    color: var(--ui-text);
}


/* Dashboard Page */

.green-btn {
    font-size: 16px;
    color: #fff;
    background: var(--ui-green);
    border: 1px solid var(--ui-green);
    border-radius: 5px;
    padding: 12px 26px;
}

.green-btn:hover {
    background: transparent;
    color: var(--ui-green);
}


/* Booking Columns */

.dashboard-content .booking-info-col {
    background: #fff;
    box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.06);
    border-radius: 15px;
    padding: 20px 20px;
}

.booking-info-col h2 {
    font-weight: 600;
    font-size: 20px;
    color: var(--ui-dark);
}

.booking-info-col .booking-on-off {
    color: var(--ui-dark);
    margin: 26px 0 13px;
}


/* .booking-info-col .dropdown-item {
    padding: 0;
}

.booking-info-col .dropdown-item a {
    padding: .25rem 1.5rem;
} */


/* The switch - the box around the slider */

.toggle-btn .switch {
    position: relative;
    display: inline-block;
    width: 36px;
    height: 20px;
    margin: 0;
}


/* Hide default HTML checkbox */

.toggle-btn .switch input {
    opacity: 0;
    width: 0;
    height: 0;
}


/* The slider */

.toggle-btn .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgb(204, 216, 219);
    border-radius: 3px;
    -webkit-transition: .4s;
    transition: .4s;
}

.toggle-btn .slider:before {
    position: absolute;
    content: "";
    height: 10px;
    width: 9px;
    left: 6px;
    bottom: 5px;
    background-color: var(--ui-dark);
    border-radius: 1px;
    -webkit-transition: .4s;
    transition: .4s;
}

.toggle-btn input:checked+.slider {
    background-color: var(--ui-green);
}

.toggle-btn input:checked+.slider:before {
    -webkit-transform: translateX(15px);
    -ms-transform: translateX(15px);
    transform: translateX(15px);
    background-color: #fff;
}


/* Booking Meeting Link */

.booking-info-col .meeting-link a i {
    color: var(--ui-dark);
    font-size: 19px;
    vertical-align: middle;
    margin-right: 3px;
}

.booking-info-col .meeting-link p {
    font-weight: var(--ui-font-medium);
    color: var(--ui-dark);
    margin-top: 15px;
}

.booking-info-col .meeting-link p i {
    font-size: 18px;
    margin-right: 5px;
}

.booking-info-col .meeting-description {
    font-size: 14px;
    margin: 16px 0 19px;
    line-height: 24px;
}

.booking-manage-button a,
.booking-manage-button button {
    width: 100%;
}

.btn-delete {
    border: 1px solid rgb(240, 145, 171);
    color: #E22357;
    padding: 12px 5px;
}

.btn-delete:hover,
.btn-delete:focus {
    border-color: #E22357;
    background: #E22357;
    color: #fff;
}

.btn-edit {
    border: 1px solid var(--ui-green);
    color: var(--ui-green);
    padding: 12px 5px;
}

.btn-edit:hover,
.btn-edit:focus {
    border-color: var(--ui-green);
    background: var(--ui-green);
    color: #fff;
}


/* ========================== My Bookings ========================== */

.my-bokings.section-title {
    margin-bottom: 35px;
}


/* ========================== Create Event ========================== */


/* .creat-event-content .form-col input,
.creat-event-content .form-col textarea {
    padding-left: 20px;
}

.creat-event-content .form-col textarea,
.creat-event-content .form-col textarea::placeholder {
    color: var(--ui-dark);
} */

.creat-event-content .form-col .label-top label {
    left: 16px;
}

.creat-event-content .form-col .label-top i {
    position: absolute;
    left: 20px;
    top: 0;
    bottom: 0;
    height: fit-content;
    margin: auto;
}


/* EVEnt Days */

.creat-event-content .event-days .day-time-container {
    border: 0.3px solid rgba(0, 0, 0, 0.23);
    border-radius: 5px;
    padding: 10px 20px;
}

.creat-event-content .event-days label span {
    font-size: 14px;
}


/* .general_avail .event-days label{
    position: relative;
}
.general_avail .event-days label::before {
    content: '';
    position: absolute;
    background: var(--ui-green);
    border-radius: 3px;
    width: 18px;
    height: 18px;
    top: 2px;
    left: -25px;
    cursor: pointer;
}

.general_avail .event-days label::after {
    content: '';
    position: absolute;
    background: #fff;
    border-radius: 1px;
    width: 8px;
    height: 8px;
    top: 7px;
    left: -20px;
    cursor: pointer;
    display: none;
    transition: .3s all ease;
} */

.creat-event-content [class*="event-day-"] {
    margin-bottom: 15px;
}

.creat-event-content [class*="event-day-"] input:checked+label::after {
    display: inline-block;
    transition: .3s all ease;
}

.creat-event-content .event-days [class*="jsx-"],
.book-event-timetable [class*="jsx-"] {
    width: 100%;
    margin-bottom: 5px;
    z-index: 2;
    background: white;
}

.event-days label p {
    color: var(--ui-dark);
}

.event-days .day-time {
    width: 72%;
    align-items: center;
    margin-left: auto;
    justify-content: flex-end;
}

.event-days .day-time-container .day-time:not(:last-of-type) {
    margin-bottom: 12px;
}

.event-days .day-time p {
    background: #F2F5F7;
    border-radius: 5px;
    line-height: 1.0;
    color: var(--ui-dark);
    padding: 12px 14px 11px;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
}

.event-days .day-time span {
    color: var(--ui-dark);
    margin: 0 10px;
}

.event-days .day-time button.plus-btn {
    margin-left: 20px;
    box-shadow: none !important;
}

.event-days .MuiFormControl-root .MuiSvgIcon-root {
    display: none;
}

.event-days .day-time button.plus-btn i:not(.text-error) {
    opacity: 0.6;
}

.event-days .day-time button.plus-btn:hover i {
    opacity: 1;
}

.event-days .day-time .dropdown {
    width: 100%;
    max-width: 85px;
}

.event-days .day-time button.dropdown-toggle {
    color: var(--ui-text);
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-radius: 5px;
    width: 100%;
    padding: 9px 5px;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.0;
}

.event-days .day-time button.dropdown-toggle::after {
    display: none;
}

.event-days .day-time .dropdown-menu {
    transform: none !important;
    top: 31px !important;
    left: -13px !important;
    height: 170px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.creat-event-content .event-days label.is-unavail::before {
    background: var(--ui-dark);
    opacity: 0.2;
}

.creat-event-content .event-days label.is-unavail::after {
    background: var(--ui-dark);
}

.event-days label.is-unavail p {
    background: transparent;
}

.event-days .event-quest {
    margin: 30px 0;
}


/* .event-quest input {
    border: 0.3px solid #023D4D;
    border-radius: 5px;
    color: var(--ui-dark);
    padding-left: 20px;
    margin-right: 10px;
} */

.event-quest button.btn {
    background: var(--ui-dark);
    color: #fff;
    width: 50px;
    font-size: 24px;
}

.event-days .create-btn button {
    padding: 12px 29px;
    font-weight: var(--ui-font-medium);
}

.event-days .create-btn .btn-edit {
    background: var(--ui-green);
    margin-left: 20px;
    color: #fff;
}

.event-days .create-btn .btn-edit:hover {
    background: transparent;
    color: var(--ui-green);
}

@media (max-width: 767px) {
    .event-days label:not(.is-unavail) .day-time {
        width: 100%;
        font-size: 14px;
    }
    .event-days label p {
        font-size: 14px;
    }
    /* .creat-event-content .event-days label {
        padding: 10px 10px 10px 38px;
    } */
    .creat-event-content .event-days label::before {
        left: 10px;
    }
    .creat-event-content .event-days label::after {
        left: 14.5px;
    }
    .event-quest h4 {
        font-size: 15px;
    }
    .event-days .create-btn button {
        width: 100%;
    }
    .event-days .create-btn .btn-edit {
        margin: 20px 0 0;
    }
}


/* Event Details page */

section.site-main .section-title select,
.section-title option {
    color: var(--ui-dark);
    cursor: pointer;
}

section.site-main .event-details-content {
    min-height: 574px;
    background: #FFFFFF;
    box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.06);
    border-radius: 15px;
    overflow-x: scroll;
}

.event-details-content table {
    width: 100%;
}

.event-details-content table th,
.event-details-content table td {
    padding: 20px 30px;
    color: var(--ui-dark);
    font-size: 14px;
}

.event-details-content table th {
    border-bottom: 0.3px solid #023D4D;
    font-weight: var(--ui-font-medium);
}

table p {
    border-radius: 4px;
    display: inline-block;
    line-height: 1.0;
    padding: 8px 10px;
}

table p.is-schedule {
    background: #ECF8F2;
    color: var(--ui-green);
}

table p.is-past {
    background: #F2F5F7;
    color: var(--ui-text);
}

table p.is-cancel {
    background: #FEF4E5;
    color: #F98D00;
}

table button {
    font-size: 14px;
    padding: 5px 5px 2px;
    margin-left: auto;
    display: block;
}

table button.btn-view {
    color: var(--ui-text);
    border: 1px solid var(--ui-text);
}

table button.btn-view:hover {
    background: var(--ui-text);
    color: #fff;
}

@media (max-width: 767px) {
    .event-details-content table th {
        padding: 20px 105px 20px 30px;
    }
    .event-details-content table th:last-of-type {
        padding: 20px 105px 20px 64px;
    }
}


/* Booking Popup */

.event-detail-popup .modal-dialog {
    position: absolute;
    width: fit-content;
    height: fit-content;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 20px auto;
}

.event-detail-popup .modal-content {
    padding: 50px;
    border-radius: 15px;
}

.event-detail-popup .modal-header button {
    font-weight: 400;
    font-size: 31px;
    position: absolute;
    top: 16px;
    right: 20px;
    padding: 0;
    margin: auto;
    outline: 0;
}

.event-detail-popup .modal-content h2 {
    color: var(--ui-dark);
    font-weight: 700;
    font-size: 36px;
    margin-bottom: 20px;
}

.event-detail-popup .modal-content p,
#popup_radio {
    color: var(--ui-dark);
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 24px;
    word-break: break-all;
}
#popup_radio{
    margin-bottom: 0;
}
.event-detail-popup .modal-content p span,
#popup_radio {
    color: var(--ui-text);
}

.event-detail-popup .btn-delete {
    background: #E22357;
    color: #fff;
}

.event-detail-popup .btn-delete:hover {
    background: transparent;
    color: #E22357;
}


/* ============================ Connect Calnder 2 ============================ */

.form-col.connect-calnder2 .client-btn button {
    min-height: 180px;
    background: #F2F5F7;
    border-radius: 15px;
    border: none;
    font-size: 14px;
}

.form-col.connect-calnder2 .client-btn button img {
    display: block;
    width: 100%;
    max-width: 80px !important;
    position: unset;
    margin-bottom: 30px;
}

.page-content.book-event .main-row {
    padding-top: 0;
    min-height: auto;
}


/* book Meeting With Client */

.page-content.with-client [class*="book-event-"] {
    background: #fff;
    box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.06);
    border-radius: 15px;
    margin-bottom: 30px;
}

@media (min-width: 768px) {
    .page-content.with-client [class*="book-event-"] {
        min-height: 580px;
        margin-bottom: 0;
    }
}

.page-content.with-client [class*="book-event-"] .table-border {
    border-bottom: 0.3px solid rgba(2, 61, 77, 0.6);
}

.page-content.with-client .book-event-with {
    padding: 20px;
}

.page-content.with-client .profile-info img {
    width: 50px;
    height: 50px;
    margin-right: 10px;
    border-radius: 50%;
    object-fit: cover;
    object-position: center center;
}

.page-content.with-client .profile-info h2 {
    font-size: 16px;
}

.page-content.with-client .book-event-with span {
    font-size: 16px;
    color: var(--ui-dark);
}

.book-event-daytable h2 {
    padding: 15px 0;
}

.book-event-daytable .Calendar__sectionWrapper {
    min-height: 40em;
}

.book-event-daytable div.Calendar__weekDays .Calendar__weekDay {
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #678B94;
    font-weight: var(--ui-font-medium);
}

.book-event-daytable .Calendar__sectionWrapper .Calendar__weekRow {
    justify-content: space-between;
}

.book-event-daytable .Calendar__sectionWrapper .Calendar__day {
    border-radius: 10px !important;
    padding: 3px 5px 0;
    width: 35px;
    height: 35px;
    min-height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: var(--ui-font-medium);
    color: var(--ui-dark) !important;
    margin-bottom: 30px;
}

.book-event-daytable .Calendar__sectionWrapper .Calendar__day:not(.-blank,
.-disabled) {
    border: 1px solid #3DA371;
}

.book-event-daytable .Calendar__day.-today:not(.-selectedStart):not(.-selectedEnd):not(.-selectedBetween) {
    color: #3DA371 !important;
    font-weight: 500;
}

.book-event-daytable .Calendar__day.-today:not(.-selectedStart):not(.-selectedEnd):not(.-selectedBetween)::after {
    background: #3DA371;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    opacity: 1;
}

.book-event-daytable .Calendar__day.-selected,
.book-event-daytable .Calendar__day.-selectedStart,
.book-event-daytable .Calendar__day.-selectedEnd,
.book-event-daytable .Calendar__day:not(.-blank):not(.-selectedStart,
.-blank,
.-disabled):not(.-selectedEnd):not(.-selectedBetween):not(.-selected):hover {
    background: #3DA371;
    color: #fff !important;
    outline: 0;
}

.book-event-daytable .Calendar__section.-hiddenNext {
    opacity: 0.5;
    transform: translateX(100%);
}

.book-event-daytable .Calendar__section.-hiddenPrevious {
    opacity: 0.5;
    transform: translateX(-100%);
}


/* .page-content.with-client .book-event-daytable h2 {
    padding: 20px 10px;
}

.page-content.with-client .book-event-daytable {
    overflow-x: auto;
}

.book-event-daytable .calender-month p {
    color: #678B94;
    font-weight: var(--ui-font-medium);
    line-height: 1.0;
    padding: 16px 0;
}

.book-event-daytable .calender-month p b {
    font-weight: var(--ui-font-bold);
    color: var(--ui-dark);
}

.book-event-daytable .calender-month p i {
    color: var(--ui-dark);
}

.book-event-daytable .calender-month p span {
    margin: 0 50px;
}

.book-event-daytable table {
    width: 100%;
    text-align: center;
}

.book-event-daytable table th {
    padding: 23px 10px;
    color: #678B94;
    font-weight: var(--ui-font-medium);
}

.book-event-daytable table td {
    padding: 21px 10px;
    font-weight: var(--ui-font-medium);
    color: var(--ui-dark);
}

.book-event-daytable table td span {
    border-radius: 10px;
    padding: 3px 5px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    margin: auto;
    cursor: pointer;
    position: relative;
}

.book-event-daytable table td span::before {
    position: absolute;
    content: '';
    background: #3DA371;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    display: none;
    transition: .3s all ease;
}

.book-event-daytable table td.not-current-date {
    color: #678B94;
}

.book-event-daytable table td.is-client-avail span {
    border: 1px solid #3DA371;
}

.book-event-daytable table td.selected span {
    background: #3DA371;
    color: #fff;
}

.book-event-daytable table td.current-date span {
    color: #3DA371;
}

.book-event-daytable table td.current-date span::before {
    transition: .3s all ease;
    display: inline-block;
} */

.page-content.with-client .book-event-timetable {
    max-height: 580px;
    overflow-y: auto;
}

.page-content.with-client .book-event-timetable::-webkit-scrollbar {
    display: none;
}


/* .page-content.with-client .book-event-timetable h2 {
    font-size: 16px;
    font-weight: var(--ui-font-medium);
    padding: 23px 10px;
} */

.book-event-timetable .city-time p {
    font-weight: var(--ui-font-medium);
    padding: 12px 0 12px 20px;
}

.book-event-timetable .city-time img {
    max-width: 17px;
    margin-right: 10px;
}

.book-event-timetable .time-selection {
    padding: 15px 5px 0;
}

.book-event-timetable .time-selection input {
    display: none;
}

.book-event-timetable .time-selection label {
    border: 1px solid rgba(2, 61, 77, 0.6);
    border-radius: 8px;
    color: var(--ui-dark);
    font-weight: var(--ui-font-medium);
    display: block;
    text-align: center;
    line-height: 1.0;
    padding: 18px 0 14px;
    margin-bottom: 12px;
    cursor: pointer;
}

.book-event-timetable .time-selection .col-6 {
    padding: 0 10px;
}

.book-event-timetable .time-selection input:checked+label {
    color: #fff;
    background: var(--ui-dark);
}

.event-confirm.modal p img {
    max-width: 17px;
}

.event-confirm.modal p i,
.event-confirm.modal p img {
    margin-right: 10px;
    display: inline-block;
}

.event-confirm.modal .event-confirm-form button {
    width: 100%;
}

.event-confirm-form button .spinner-border {
    width: 22px;
    height: 22px;
    margin-left: 12px;
}

@media (min-width: 768px) {
    .event-confirm.modal .event-confirm-form button {
        width: 48%;
    }
}

.event-confirm.modal .event-confirm-form {
    border-top: 0.3px solid rgba(2, 61, 77, 0.6);
    padding-top: 20px;
}

.event-confirm-form input {
    height: 50px;
    color: var(--ui-dark);
}

.event-confirm-form button.btn-delete {
    background: transparent;
    color: #E22357;
}

.event-confirm-form button.btn-delete:hover {
    background: #E22357;
    color: #fff;
}

.event-confirm-form button.btn-edit {
    background: var(--ui-green);
    color: #fff;
}

.event-confirm-form button.btn-edit:hover {
    color: var(--ui-green);
    background: transparent;
}

.Calendar {
    width: 100% !important;
}

@media (max-width: 767px) {
    .event-detail-popup .modal-content {
        padding: 30px;
    }
    .event-detail-popup .modal-content h2 {
        font-size: 28px;
    }
    .event-confirm-form button.btn-delete {
        margin-bottom: 20px;
    }
    .event-detail-popup .modal-content p {
        font-size: 14px;
    }
}

.form-col [class*='jsx-'],
.book-event-timetable [class*="jsx-"] {
    font-family: 'Red Hat Display', sans-serif;
    margin: 0;
}

.form-col [class*='jsx-'] input,
.book-event-timetable [class*="jsx-"] input {
    width: 100%;
    background: #fff;
    color: #0F172A;
    z-index: 2;
    font-size: 16px;
    height: 50px;
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-radius: 4px;
}

.book-event-timetable [class*="jsx-"] input {
    border-radius: 8px;
    border-color: rgba(2, 61, 77, 0.6);
}

.form-col [class*='jsx-'] ul,
.book-event-timetable [class*="jsx-"] ul {
    z-index: 99;
    background: #fff;
}

.form-col [class*='jsx-']::placeholder {
    color: var(--ui-grey-400);
}

[type="submit"]+fieldset {
    top: 0;
}

[type="submit"]+fieldset legend {
    display: none;
}

.input-icon input {
    height: 50px;
}

.input-icon input:focus {
    box-shadow: none;
    border: 2px solid var(--ui-green);
}

.input-icon input:focus+label {
    color: var(--ui-green);
}


/*================================================================================================
             Appoitly Landing
=================================================================================================
 */


/* Home Page */


/*** 

====================================================================
  Loading Transition
====================================================================

 ***/

.ctn-preloader {
    align-items: center;
    -webkit-align-items: center;
    display: flex;
    display: -ms-flexbox;
    height: 100%;
    justify-content: center;
    -webkit-justify-content: center;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 999999;
    background: #fff;
}

.ctn-preloader .animation-preloader {
    position: absolute;
    z-index: 100;
}


/* Spinner cargando */

.ctn-preloader .animation-preloader .spinner {
    animation: spinner 1.5s infinite linear;
    border-radius: 50%;
    border: 3px solid rgb(17 85 237 / 6%);
    border-top-color: var(--brand-color);
    border-bottom-color: var(--brand-color);
    height: 170px;
    margin: 0 auto 45px auto;
    width: 170px;
}


/* Texto cargando */

.ctn-preloader .animation-preloader .txt-loading {
    text-align: center;
    user-select: none;
}

.ctn-preloader .animation-preloader .txt-loading .letters-loading:before {
    animation: letters-loading 4s infinite;
    color: var(--brand-color);
    content: attr(data-text-preloader);
    left: 0;
    opacity: 0;
    top: 0;
    line-height: 70px;
    position: absolute;
}

.ctn-preloader .animation-preloader .txt-loading .letters-loading {
    font-weight: 500;
    letter-spacing: 15px;
    display: inline-block;
    color: rgb(17 85 237 / 14%);
    position: relative;
    font-size: 70px;
    line-height: 70px;
}

.ctn-preloader .animation-preloader .txt-loading .letters-loading:nth-child(2):before {
    animation-delay: 0.2s;
}

.ctn-preloader .animation-preloader .txt-loading .letters-loading:nth-child(3):before {
    animation-delay: 0.4s;
}

.ctn-preloader .animation-preloader .txt-loading .letters-loading:nth-child(4):before {
    animation-delay: 0.6s;
}

.ctn-preloader .animation-preloader .txt-loading .letters-loading:nth-child(5):before {
    animation-delay: 0.8s;
}

.ctn-preloader .animation-preloader .txt-loading .letters-loading:nth-child(6):before {
    animation-delay: 1s;
}

.ctn-preloader .animation-preloader .txt-loading .letters-loading:nth-child(7):before {
    animation-delay: 1.2s;
}

.ctn-preloader .animation-preloader .txt-loading .letters-loading:nth-child(8):before {
    animation-delay: 1.4s;
}

.ctn-preloader .loader-section {
    background-color: #ffffff;
    height: 100%;
    position: fixed;
    top: 0;
    width: calc(50% + 1px);
}

.loaded .animation-preloader {
    opacity: 0;
    transition: 0.3s ease-out;
}


/* Animación del preloader */

@keyframes spinner {
    to {
        transform: rotateZ(360deg);
    }
}

@keyframes letters-loading {
    0%,
    75%,
    100% {
        opacity: 0;
        transform: rotateY(-90deg);
    }
    25%,
    50% {
        opacity: 1;
        transform: rotateY(0deg);
    }
}

@media screen and (max-width: 767px) {
    .ctn-preloader .animation-preloader .spinner {
        height: 8em;
        width: 8em;
    }
}

@media screen and (max-width: 500px) {
    .ctn-preloader .animation-preloader .spinner {
        height: 7em;
        width: 7em;
    }
    .ctn-preloader .animation-preloader .txt-loading .letters-loading {
        font-size: 40px;
        letter-spacing: 10px;
    }
}


/*** 

====================================================================
  Scroll to top
====================================================================

 ***/

.scroll-top {
    width: 35px;
    height: 35px;
    line-height: 35px;
    position: fixed;
    bottom: 20px;
    right: 5px;
    z-index: 99;
    text-align: center;
    color: #fff;
    font-size: 18px;
    cursor: pointer;
    border-radius: 50%;
    background: #000;
    display: none;
    transition: all 0.3s ease-in-out;
}

.scroll-top:after {
    position: absolute;
    z-index: -1;
    content: '';
    top: 100%;
    left: 5%;
    height: 10px;
    width: 90%;
    opacity: 1;
    background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0) 80%);
}


/* Header */

.theme-menu-four ul,
.theme-menu-four li {
    list-style: none;
}

.theme-main-menu {
    position: fixed;
    z-index: 99;
    top: 0;
    left: 0;
    right: 0;
    background: #fff;
    padding: 30px 70px;
    transition: all 0.4s ease-out;
}

.theme-main-menu.bg-none {
    background: transparent;
}

.theme-main-menu>div {
    position: relative;
}

.theme-main-menu .logo a {
    display: block;
}

.theme-menu-four .logo img {
    max-width: 120px;
}

@media (min-width: 1200px) {
    .theme-menu-four .logo img {
        max-width: 165px;
    }
}

.theme-main-menu.sticky-menu.fixed {
    z-index: 9999;
    padding-top: 0;
    padding-bottom: 0;
    background: #fff;
    box-shadow: 0 13px 35px -12px rgb(35 35 35 / 10%);
}

.theme-main-menu.sticky-menu.fixed.theme-menu-four {
    background: #fff;
}

.theme-menu-four .navbar {
    padding: 0;
    z-index: 1;
    -webkit-transition: 0.3s linear all;
    -moz-transition: 0.3s linear all;
    -ms-transition: 0.3s linear all;
    -o-transition: 0.3s linear all;
    transition: 0.3s linear all;
}

@media screen and (min-width: 992px) {
    .theme-menu-four .navbar {
        padding-top: 0px;
        padding-bottom: 0px;
    }
}

#mega-menu-holder {
    position: static;
}

.theme-menu-four .navbar .navbar-toggler {
    float: right;
    font-size: 26px;
    padding: 5px 8px;
    margin: 0;
    color: #fff;
    background: #2a2a2a;
    box-shadow: 0 15px 20px 0px rgb(0 0 0 / 5%);
}

.theme-menu-four .navbar .navbar-toggler {
    background: var(--brand-color);
}

.theme-menu-four .navbar-nav .nav-item a {
    padding: 30px 0;
    margin: 0 15px;
    display: block;
    color: #2B2C2E;
    font-size: 18px;
    text-transform: capitalize;
    position: relative;
    transition: all 0.3s ease-in-out;
}

.theme-menu-four .navbar-nav .nav-item a[aria-current="page"],
.theme-menu-four .navbar-nav .nav-item:hover>a {
    color: var(--brand-color);
}

.theme-menu-four .navbar-nav .nav-item a[aria-current="page"] {
    font-weight: 600;
}

@media (min-width: 992px) {
    .theme-menu-four .right-button-group {
        margin-left: 100px;
    }
}

.theme-menu-four .right-button-group .signIn-action {
    font-weight: 500;
    font-size: 18px;
    line-height: 45px;
    color: var(--heading);
    margin-right: 40px;
    transition: all 0.3s ease-in-out;
}

.theme-menu-four .right-button-group .signIn-action:hover {
    color: var(--brand-color);
}

body .theme-btn-five {
    line-height: 56px;
    padding: 0 35px;
    text-align: center;
    /* border: 2px solid var(--brand-color); */
    border-radius: 6px;
    color: #fff;
    background: var(--brand-color);
    position: relative;
    transition: .3s all ease;
    z-index: 1;
    overflow: hidden;
}

body .theme-btn-five:before,
.fancy-short-banner-five form button::before,
.fancy-short-banner-three .form-wrapper form button::before,
body .theme-btn-two::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: var(--purple-blue);
    z-index: -1;
    transition: all 0.3s ease-in-out;
    transform: scale(0, 1);
    transform-origin: 0 100%;
}

body .theme-btn-five:hover:before,
.fancy-short-banner-five form button:hover::before,
.fancy-short-banner-three .form-wrapper form button:hover::before,
body .theme-btn-two:hover::before {
    transform: scale(1, 1);
}


/* body .theme-btn-five:hover {
    background: var(--brand-color);
    color: #fff;
    transition: .3s all ease;
} */

body .theme-main-menu .theme-btn-five,
.hero-banner-four a {
    background: var(--brand-color);
    color: #fff;
}

body .theme-main-menu .theme-btn-five:hover,
.hero-banner-four a:hover {
    border-color: var(--purple-blue);
    /* background: var(--purple-blue); */
}

@media screen and (max-width: 991px) {
    .theme-menu-four .navbar-collapse {
        position: absolute;
        top: 100%;
        background-color: rgb(224, 240, 254);
        left: 0px;
        right: 0px;
        max-height: 70vh;
        overflow-y: auto;
        box-shadow: 0px 40px 50px 0px rgb(137 139 142 / 15%);
        padding-bottom: 20px;
    }
    .theme-main-menu.sticky-menu.fixed.theme-menu-four .navbar-collapse {
        background: #fff;
    }
    .theme-menu-four .navbar-collapse::-webkit-scrollbar {
        display: none;
    }
    .theme-menu-four .navbar-collapse .navbar-nav {
        align-items: center;
    }
    .theme-menu-four .navbar .navbar-collapse .navbar-nav .nav-item a {
        padding: 15px;
        font-size: 18px;
        border-top: 1px solid #ececec;
        margin: 0;
        text-align: center;
    }
    .hero-banner-four h1 .theme-menu-four .right-button-group {
        margin: 0 auto !important;
        padding: 15px 0;
    }
}


/* Hero Section */

.hero-banner-four {
    position: relative;
    background: rgb(224, 240, 254);
    padding: 250px 0 110px;
    z-index: 1;
}

.hero-banner-four:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 37px;
    left: 0;
    bottom: -33px;
    background: url(../images/landing/shape/116.svg) no-repeat center bottom;
    background-size: 100% 100%;
}

.shapes {
    position: absolute;
    z-index: -1;
}

.hero-banner-four .shape-four {
    top: 1%;
    left: 15%;
}

.hero-banner-four .shape-five {
    top: 11%;
    right: 31%;
}

.hero-banner-four .shape-six {
    bottom: 5%;
    left: 0;
}

.hero-banner-four .shape-seven {
    bottom: 8%;
    right: 0;
}

.illustration-holder {
    position: relative;
}

.hero-banner-four .illustration {
    max-width: 660px;
}

.hero-banner-four .illustration-holder .shape-one {
    width: 14%;
    top: 9%;
    right: 8%;
    z-index: 1;
    animation: jump10p 5s infinite linear;
}

.hero-banner-four .illustration-holder .shape-two {
    width: 14%;
    top: 0;
    right: 30%;
    z-index: 1;
    animation: jump10pRsv 5s infinite linear;
}

.hero-banner-four .illustration-holder .shape-three {
    width: 15%;
    top: 9%;
    right: 51%;
    z-index: 1;
    animation: jump10p 5s infinite linear;
}

.hero-banner-four .review-text {
    text-align: right;
    font-size: 20px;
    color: #000;
    padding-top: 30px;
}

.hero-banner-four .review-text span {
    font-size: 1.6em;
    color: var(--purple-blue);
    text-decoration: underline;
}

.hero-banner-four h1 {
    font-size: 72px;
    line-height: 1.14em;
}

.hero-banner-four h1 span {
    position: relative;
    z-index: 5;
}

.hero-banner-four h1 span:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 25px;
    background: rgba(186, 211, 255, 0.8);
    left: 0;
    bottom: 12px;
    z-index: -1;
}

.hero-banner-four .sub-text {
    font-size: 28px;
    color: #565657;
    line-height: 1.4em;
    padding: 50px 0 65px;
}

.hero-banner-four a {
    font-weight: 500;
}


/* Fancy Feature Section */

.fancy-feature-eight .bg-wrapper {
    position: relative;
    z-index: 5;
    background: #fff;
    padding: 35px 0 42px;
}

.fancy-feature-eight .bg-wrapper:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 24px;
    background: url(../images/landing/shape/97.svg) no-repeat center top;
    left: 0;
    top: -20px;
}

.fancy-feature-eight .bg-wrapper:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 13px;
    background: url(../images/landing/shape/98.svg) no-repeat center bottom;
    left: 0;
    bottom: -10px;
}

.block-style-thirteen {
    position: relative;
    text-align: center;
    padding: 32px 40px 20px;
}


/* .block-style-thirteen .icon {
    height: 70px;
} */

.block-style-thirteen .icon img {
    margin: 0 auto;
    max-height: 100%;
}

.block-style-thirteen .title {
    font-size: 18px;
    color: rgba(0, 0, 0, 0.3);
    padding: 15px 0 20px;
}

.block-style-thirteen p {
    font-size: 24px;
    line-height: 1.45em;
    color: #000;
}

.block-style-thirteen.style-border {
    border-left: 1px solid #E7E7E7;
    border-right: 1px solid #E7E7E7;
}


/* Fancy Text Block */

.fancy-text-block-nineteen .text-wrapper .client-info {
    font-size: 24px;
    color: #A09A92;
    padding-bottom: 20px;
}

.fancy-text-block-nineteen .text-wrapper .client-info span {
    color: #000;
    text-decoration: underline;
}

.title-style-five h2 {
    font-size: 54px;
    line-height: 1.25em;
    font-weight: 500;
}

.title-style-five h2 span {
    position: relative;
    z-index: 1;
}

.title-style-five h2 span:before {
    content: '';
    width: 100%;
    height: 15px;
    position: absolute;
    background: rgba(186, 211, 255, 0.8);
    left: 0;
    bottom: 12px;
    z-index: -1;
}

.fancy-feature-nine .title-style-five h2 span:before {
    display: none;
}

.fancy-text-block-nineteen .text-wrapper p {
    font-size: 24px;
    color: #0E0E0E;
    line-height: 1.58em;
    padding-top: 40px;
}

.fancy-text-block-nineteen .text-wrapper .name {
    font-size: 24px;
    color: #000;
    position: relative;
    padding-left: 28px;
    margin-top: 50px;
}

.fancy-text-block-nineteen .text-wrapper .name:before {
    content: '';
    width: 18px;
    height: 3px;
    border-radius: 2px;
    background: #000;
    position: absolute;
    left: 0;
    top: 13px;
}


/* Fancy Feature Nine */

.fancy-feature-nine {
    position: relative;
    background: rgb(224, 240, 254);
    padding: 140px 0 135px;
    z-index: 5;
}

.fancy-feature-nine:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 52px;
    background: url(../images/landing/shape/105.svg) no-repeat center top;
    background-size: cover;
    left: 0;
    top: -50px;
}

.fancy-feature-nine:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 37px;
    background: url(../images/landing/shape/116.svg) no-repeat center bottom;
    background-size: 100% 100%;
    left: 0;
    bottom: -32px;
}

.fancy-feature-nine .shape-one {
    top: 7%;
    right: 13%;
    animation: jumpThree 5s infinite linear;
}

.fancy-feature-nine .shape-two {
    top: 45%;
    right: 5%;
    animation: jumpTwo 5s infinite linear;
}

.fancy-feature-nine .shape-three {
    top: 88%;
    right: 10%;
    animation: jumpThree 5s infinite linear;
}

.fancy-feature-nine .shape-four {
    top: 89%;
    left: 11%;
    animation: jumpTwo 5s infinite linear;
}

.fancy-feature-nine .shape-five {
    top: 43%;
    left: 4%;
    animation: jumpThree 5s infinite linear;
}

.fancy-feature-nine .shape-six {
    top: 9%;
    left: 15%;
    animation: jumpTwo 5s infinite linear;
}

.title-style-five h6,
.block-style-nine .text-wrapper h6 {
    font-size: 20px;
    color: rgba(0, 0, 0, 0.35);
    text-transform: uppercase;
    letter-spacing: 1px;
    padding-bottom: 12px;
}

.block-style-fourteen {
    position: relative;
    padding: 0 25px;
    margin-top: 55px;
    text-align: center;
}

.block-style-fourteen .illustration {
    height: 220px;
}

.block-style-fourteen .illustration img {
    margin: 0 auto;
    max-height: 100%;
}

.block-style-fourteen .title {
    font-size: 20px;
    color: rgba(0, 0, 0, 0.35);
    padding: 20px 0 15px;
}

.block-style-fourteen p {
    font-size: 23px;
    line-height: 1.52em;
    color: #000;
}

.block-style-fourteen.arrow-shape:before,
.block-style-fourteen.arrow-shape:after {
    content: url(../images/landing/shape/104.svg);
    position: absolute;
    top: 26%;
    z-index: 1;
}

.block-style-fourteen.arrow-shape:before {
    left: -20%;
}

.block-style-fourteen.arrow-shape:after {
    right: -20%;
}


/* Fancy Text Block Six */

.accordion-style-two .card {
    background: #fff;
    margin-bottom: 0;
    border-radius: 0;
    border: none;
    border-bottom: 2px solid #000;
}

.accordion-style-two .card .card-header {
    background: transparent;
    border-radius: 0;
    padding: 0;
    border: none;
}

.accordion-style-two .card .card-header button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    text-align: left;
    padding: 22px 70px 22px 0;
    font-size: 30px;
    line-height: 1.40em;
    border: none;
    border-radius: 0;
    margin: 0;
    color: var(--heading);
    text-decoration: none;
    position: relative;
    box-shadow: none;
}

.accordion-style-two .card .card-header button i {
    font-size: 22px;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    height: fit-content;
    margin: auto;
    transition: .3s all ease;
}

.accordion-style-two .card .card-header button:not(.collapsed) i {
    transform: rotate(-180deg);
    transition: .3s all ease;
}

.accordion-style-two .card .card-body {
    padding: 0 70px 30px 0;
}

.accordion-style-two .card .card-body p,
.accordion-style-two p {
    font-size: 20px;
    line-height: 1.55em;
}

.accordion-style-two h2 {
    font-size: 34px;
    font-weight: 500;
    line-height: 1.40em;
    margin-bottom: 15px;
}

.fancy-text-block-six .illustration-holder .shape-one {
    right: -20%;
    top: 29%;
    animation: jumpThree 5s infinite linear;
}

.fancy-text-block-six .illustration-holder img {
    filter: drop-shadow(3px 3px 55px rgba(0, 0, 0, 0.1));
}


/* 
=====================================================
	Pricing Section Three
=====================================================
*/

.pricing-section-three {
    background: rgb(224, 240, 254);
    position: relative;
    z-index: 1;
    padding: 105px 0 170px;
}

.pricing-section-three:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 52px;
    background: url(../images/landing/shape/105.svg) no-repeat center top;
    background-size: 100% 100%;
    left: 0;
    top: -47px;
}

.pricing-section-three:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 33px;
    background: url(../images/landing/shape/116.svg) no-repeat center bottom;
    background-size: 100% 100%;
    left: 0;
    bottom: -31px;
}

.pricing-section-three .shape-one {
    top: 7%;
    right: 13%;
    animation: jumpThree 5s infinite linear;
}

.pricing-section-three .shape-two {
    top: 45%;
    right: 5%;
    animation: jumpTwo 5s infinite linear;
}

.pricing-section-three .shape-three {
    top: 88%;
    right: 10%;
    animation: jumpThree 5s infinite linear;
}

.pricing-section-three .shape-four {
    top: 89%;
    left: 11%;
    animation: jumpTwo 5s infinite linear;
}

.pricing-section-three .shape-five {
    top: 43%;
    left: 4%;
    animation: jumpThree 5s infinite linear;
}

.pricing-section-three .shape-six {
    top: 9%;
    left: 15%;
    animation: jumpTwo 5s infinite linear;
}

.pricing-section-three .pricing-table-area-three {
    position: relative;
}


/* .pricing-section-three .pricing-table-area-three:before {
    content: url(../images/landing/shape/117.svg);
    position: absolute;
    left: -5%;
    top: -5%;
    z-index: -1;
} */

.pricing-table-area-three .pr-table-wrapper {
    background: #fff;
    padding: 40px 20px 40px;
    text-align: center;
    margin-top: 45px;
    position: relative;
    transition: all 0.3s ease-in-out;
}

.pricing-table-area-three .pr-table-wrapper.skew-right:before {
    content: '';
    position: absolute;
    top: -8px;
    right: 0;
    width: 100%;
    height: 25px;
    background: #fff;
    transform: skewY( 2.3deg);
}

.pricing-table-area-three .pr-table-wrapper .pack-name {
    font-size: 32px;
    color: #000;
}

.pricing-table-area-three .pr-table-wrapper .pack-name span {
    position: relative;
    z-index: 1;
}

.pricing-table-area-three .pr-table-wrapper .pack-name span:before {
    content: '';
    width: 109%;
    height: 15px;
    position: absolute;
    left: 50%;
    bottom: 5px;
    transform: translateX(-50%);
    z-index: -1;
}

.pricing-table-area-three .pr-table-wrapper .pack-name.pc1 span:before {
    background: #D4FDF1;
}

.pricing-table-area-three .pr-table-wrapper .price {
    font-size: 65px;
    color: #000;
    padding: 24px 0 0;
    line-height: initial;
}

.pricing-table-area-three .pr-table-wrapper .user-condition {
    font-size: 24px;
    color: #000;
}

.pricing-table-area-three .pr-table-wrapper .line {
    width: 100%;
    margin: 25px auto 38px;
}

.pricing-table-area-three .pr-table-wrapper ul {
    text-align: left;
    padding-left: 18px;
}

.pricing-table-area-three .pr-table-wrapper ul li {
    font-size: 17px;
    line-height: 42px;
    color: #464646;
    position: relative;
    padding-left: 30px;
}

.pricing-table-area-three .pr-table-wrapper ul li:before {
    content: url(../images/landing/icon/63.svg);
    position: absolute;
    left: 0;
    top: 1px;
}

.pricing-table-area-three .pr-table-wrapper ul li.disable {
    color: #B9B9B9;
}

.pricing-table-area-three .pr-table-wrapper ul li.disable:before {
    content: url(../images/landing/icon/64.svg);
    top: 5px;
    left: -8px;
}

.pricing-table-area-three .pr-table-wrapper .subscribe-btn {
    display: block;
    width: 95%;
    margin: 37px auto 0;
    line-height: 46px;
    text-align: center;
    border: 2px solid var(--brand-color);
    border-radius: 6px;
    color: var(--brand-color);
    transition: all 0.3s ease-in-out;
}

.pricing-table-area-three .pr-table-wrapper .subscribe-btn:hover {
    background: var(--brand-color);
    color: #fff;
}

.pricing-table-area-three .pr-table-wrapper.skew-left:before {
    content: '';
    position: absolute;
    top: -8px;
    right: 0;
    width: 100%;
    height: 25px;
    background: #fff;
    transform: skewY( -2.3deg);
}

.pricing-table-area-three .pr-table-wrapper .pack-name.pc2 span:before {
    background: #DBF7FF;
}

.pricing-table-area-three .pr-table-wrapper .pack-name.pc3 span:before {
    background: #FFE3E3;
}

.pricing-table-area-three .pr-table-wrapper:hover {
    transform: translateY(-10px);
}


/* =====================================================
				Client Feedback Slider Three
===================================================== */

.client-feedback-slider-three {
    position: relative;
    z-index: 5;
}

.clientSliderThree {
    text-align: center;
}

.clientSliderThree p {
    font-size: 28px;
    line-height: 1.71em;
    color: rgba(0, 0, 0, 0.8);
    padding: 25px 0 45px;
}

.clientSliderThree .name {
    font-weight: 500;
    font-size: 24px;
    color: #000;
}

.clientSliderThree .desig {
    color: #A8AFAE;
}

.slider-arrow {
    cursor: pointer;
    margin: 0 10px;
    font-size: 30px;
    color: #5D5D5D;
    transition: all 0.3s ease-in-out;
    transform: scale(0.8);
    position: absolute;
    bottom: -50px;
    width: fit-content;
    left: 0;
    right: 0;
    margin: auto;
}

.slider-arrow:hover {
    transform: scale(1);
}

.slider-arrow.slider-next {
    right: -50px;
}

.slider-arrow.slider-prev {
    left: -50px;
}

.client-feedback-slider-three .shape_1 {
    left: 18%;
    top: -3%;
}

.client-feedback-slider-three .shape_2 {
    left: 9%;
    top: 38%;
}

.client-feedback-slider-three .shape_3 {
    left: 13%;
    top: 85%;
}

.client-feedback-slider-three .shape_4 {
    right: 18%;
    top: -4%;
}

.client-feedback-slider-three .shape_5 {
    right: 4%;
    top: 33%;
}

.client-feedback-slider-three .shape_6 {
    right: 19%;
    top: 87%;
}


/* =====================================================
				Faq Section Four
===================================================== */

.faq-section-four {
    background: rgb(224, 240, 254);
    position: relative;
    z-index: 1;
    padding: 78px 0 150px;
}

.faq-section-four:before {
    content: '';
    position: absolute;
    width: 100%;
    /* height: 100px;
    background: url(../images/landing/shape/118.svg) no-repeat center top;
    background-size: cover;
    left: 0;
    top: -98px; */
    height: 52px;
    background: url(../images/landing/shape/105.svg) no-repeat center top;
    background-size: 100% 100%;
    left: 0;
    top: -47px;
}

.faq-section-four:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 33px;
    background: url(../images/landing/shape/119.svg) no-repeat center bottom;
    background-size: 100% 100%;
    left: 0;
    bottom: -31px;
}

.faq-section-four .shape-one {
    left: 0;
    top: 21%;
}

.faq-section-four .shape-two {
    right: 0;
    top: 75%;
}

.accordion-style-four .card {
    background: #fff;
    border-radius: 0;
    border: none;
    margin-bottom: 10px;
}

.accordion-style-four .card .card-header {
    background: transparent;
    border-radius: 0;
    padding: 0;
    border: none;
}

.accordion-style-four .card .card-header button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    text-align: left;
    padding: 27px 70px 27px 45px;
    font-size: 24px;
    border: none;
    border-radius: 0;
    margin: 0;
    color: var(--heading);
    text-decoration: none;
    position: relative;
    box-shadow: none;
}

.accordion-style-four .card .card-header button i {
    font-size: 22px;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 35px;
    height: fit-content;
    margin: auto;
    transition: .3s all ease;
}

.accordion-style-four .card .card-header button:not(.collapsed) i {
    transform: rotate(-180deg);
    transition: .3s all ease;
}

@media (max-width: 767px) {
    .accordion-style-four .card .card-header button:before {
        font-size: 11px;
    }
}

.accordion-style-four .card .card-header button:before .accordion-style-four .card .card-body {
    padding: 0 50px 20px 45px;
}

.accordion-style-four .card .card-body p {
    padding-bottom: 15px;
}


/* =====================================================
				Fancy Short Banner Five
===================================================== */

.fancy-short-banner-five .sub-heading {
    font-size: 28px;
    line-height: 1.32em;
    color: #000;
    text-align: center;
    padding: 40px 0 55px;
}

.fancy-short-banner-five form {
    height: 70px;
    position: relative;
    margin-bottom: 17px;
}

.fancy-short-banner-five form input {
    width: 100%;
    height: 100%;
    border: 2px solid #000;
    padding: 0 170px 0 40px;
    color: #000;
    border-radius: 6px;
}

.fancy-short-banner-five form button {
    position: absolute;
    width: 160px;
    right: 8px;
    top: 8px;
    bottom: 8px;
    background: var(--brand-color);
    border-radius: 6px;
    color: #fff;
    font-size: 16px;
    transition: all 0.3s ease-in-out;
    overflow: hidden;
}

.fancy-short-banner-five form button::before {
    z-index: 1;
}

.fancy-short-banner-five form button span {
    position: relative;
    z-index: 1;
}

.fancy-short-banner-five .info-text {
    text-align: center;
    font-size: 16px;
    color: #999999;
}

.fancy-short-banner-five .info-text a {
    color: #000;
}

.fancy-short-banner-five .info-text a:hover {
    color: var(--brand-color);
}


/* =====================================================
    Footer
===================================================== */

.theme-footer-four {
    background: var(--ui-green);
    padding: 80px 0 70px;
    position: relative;
    z-index: 5;
}

.theme-footer-four:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 52px;
    background: url(../images/landing/shape/105-foooter.svg) no-repeat center top;
    background-size: 100% 100%;
    left: 0;
    top: -47px;
}

.theme-footer-four .footer-about-widget ul li a {
    font-size: 20px;
    color: #fff;
    margin-bottom: 5px;
    transition: all 0.2s ease-in-out;
}

.theme-footer-four .footer-about-widget ul li a {
    font-size: 20px;
    color: #fff;
    margin-bottom: 5px;
    transition: all 0.2s ease-in-out;
}

.theme-footer-four [class*="col-"] {
    margin-bottom: 35px;
}

.theme-footer-four .footer-title {
    font-size: 28px;
    color: #fff;
    position: relative;
    margin: 5px 0 21px;
}

.theme-footer-four .footer-list ul li a {
    color: #fff;
    line-height: 40px;
    transition: all 0.2s ease-in-out;
}

.theme-footer-four .footer-about-widget ul li a:hover,
.theme-footer-four .footer-list ul li a:hover {
    text-decoration: underline !important;
    color: #fff;
}

.theme-footer-four .bottom-footer-content p {
    font-size: 16px;
    color: #fff;
    margin-top: -70px;
}


/* =====================================================
    Contact Us Page
===================================================== */

.fancy-hero-four {
    position: relative;
    background: rgb(224, 240, 254);
    padding: 220px 0 320px;
    z-index: 5;
    text-align: center;
}

.fancy-hero-four h6 {
    font-size: 18px;
    letter-spacing: 1.8px;
    text-transform: uppercase;
    color: rgba(42, 42, 42, 0.4);
    padding-bottom: 25px;
}

.fancy-hero-four h2 {
    font-weight: 800;
    font-size: 80px;
    line-height: 1.1em;
}

.contact-style-two .contact-info-wrapper {
    position: relative;
    z-index: 5;
    margin-top: -195px;
}

.contact-style-two .contact-info-wrapper .address-info {
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 15px 30px rgb(0 0 0 / 4%);
    padding: 55px 15px 22px;
    text-align: center;
    margin-bottom: 35px;
    height: 100%;
    width: 100%;
}

.contact-style-two .contact-info-wrapper .address-info .icon {
    height: 82px;
}

.contact-style-two .contact-info-wrapper .address-info .icon img {
    margin: 0 auto;
    max-height: 100%;
}

.contact-style-two .contact-info-wrapper .address-info .title {
    font-size: 20px;
    color: #9E9E9E;
    padding: 17px 0 22px;
    position: relative;
    display: inline-block;
}

.contact-style-two .contact-info-wrapper .address-info .title:before {
    content: '';
    width: 100%;
    height: 3px;
    background: rgba(186, 211, 255, 0.8);
    border-radius: 2px;
    position: absolute;
    left: 0;
    bottom: 22px;
}

.contact-style-two .contact-info-wrapper .address-info p {
    font-size: 28px;
    line-height: 1.35em;
    color: #000;
}

.contact-style-two .contact-info-wrapper .address-info ul li a {
    font-size: 22px;
    margin: 5px 15px;
    color: rgba(0, 0, 0, 0.2);
    transition: all 0.2s ease-in-out;
}

.contact-style-two .contact-info-wrapper .address-info ul li a:hover {
    color: var(--brand-color);
}

.contact-style-two .contact-info-wrapper .shape-one {
    left: -7%;
    top: -18%;
    animation: jumpTwo 5s infinite linear;
}


/* Contact Us form */

.form-style-classic .input-group-meta {
    height: 58px;
    position: relative;
}

.form-style-classic .input-group-meta label {
    font-size: 14px;
    font-weight: normal;
    color: #BFBFBF;
    position: absolute;
    left: 0;
    top: -20px;
    z-index: 1;
}

.form-style-classic .input-group-meta input {
    width: 100%;
    height: 100%;
    font-size: 23px;
    border: none;
    border-bottom: solid 2px #000;
    color: var(--heading);
    background: transparent;
}

#contact-form .form-group .help-block {
    position: absolute;
    left: 0;
    bottom: -24px;
    font-size: 14px;
    line-height: 22px;
    color: #fff;
    padding: 0 15px;
    border-radius: 3px;
    background: #dc3545;
    box-shadow: 0px 10px 25px 0px rgb(123 147 171 / 15%);
}

.form-style-classic .input-group-meta.lg {
    height: 200px;
}

.form-style-classic .input-group-meta textarea {
    width: 100%;
    height: 100%;
    font-size: 23px;
    color: var(--heading);
    border: none;
    border-bottom: solid 2px #000;
    resize: none;
    padding: 20px 0;
    overflow: auto;
}

body .theme-btn-two {
    font-weight: 600;
    font-size: 18px;
    color: #fff;
    line-height: 48px;
    background: var(--brand-color);
    padding: 0 42px;
    border-radius: 5px;
    transition: all 0.3s ease-in-out;
    overflow: hidden;
    position: relative;
    z-index: 1;
}


/* body .theme-btn-two:hover {
    background: var(--purple-blue);
    border-color: var(--purple-blue);
} */


/* =====================================================
    About Us Page
===================================================== */

.fancy-text-block-twenty {
    position: relative;
    padding: 215px 0 100px;
    z-index: 5;
}

.fancy-text-block-twenty:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 36%;
    background: url(../images/landing/shape/123.svg) no-repeat center bottom;
    top: 0;
    left: 0;
    z-index: -1;
    background-size: cover;
}

.fancy-text-block-twenty .shape-one {
    left: 0;
    top: 19%;
}

.fancy-text-block-twenty .shape-two {
    right: 0;
    top: 19%;
}

.fancy-text-block-twenty .title {
    text-align: center;
    font-size: 72px;
    padding-bottom: 130px;
}

.fancy-video-box-one {
    position: relative;
}

.fancy-video-box-one .main-img {
    width: 100%;
}

.fancy-video-box-one .video-button {
    position: absolute;
    width: 100px;
    height: 100px;
    background: #fff;
    border-radius: 50%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    padding-left: 8px;
}

.fancy-text-block-twenty .client-info {
    font-size: 24px;
    color: #A09A92;
    padding-bottom: 20px;
}

.fancy-text-block-twenty .client-info span {
    color: #000;
    text-decoration: underline;
}

.fancy-text-block-twenty .nav-tabs {
    border: none;
}

.fancy-text-block-twenty .nav-tabs li {
    margin: 0 82px 0 0;
}

.fancy-text-block-twenty .nav-tabs li:last-child {
    margin-right: 0;
}

.fancy-text-block-twenty .nav-tabs li a {
    font-size: 22px;
    color: #969696;
    padding: 0;
    border: none;
}

.fancy-text-block-twenty .nav-tabs li a.active {
    color: #000;
    text-decoration: underline !important;
}

.fancy-text-block-twenty .tab-pane p {
    font-size: 20px;
    line-height: 1.9em;
    color: #000;
    padding-top: 35px;
}


/* =====================================================
				Counter With Icon One
===================================================== */

.counter-box-three {
    text-align: center;
    margin-top: 40px;
}

.counter-box-three .icon {
    height: 62px;
    display: inline-block;
}

.counter-box-three .number {
    font-size: 38px;
    padding: 5px 0 3px;
}

.counter-box-three p {
    font-weight: 300;
    font-size: 24px;
    color: #000;
}


/* =============================================
				Team Section
==============================================  */

.team-section-four {
    background: rgb(224, 240, 254);
    position: relative;
    padding: 100px 0 60px;
    z-index: 1;
}


/* .team-section-four:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 70px;
    background: url(../images/landing/shape/127.svg) no-repeat center top;
    background-size: cover;
    left: 0;
    top: -58px;
} */

.team-section-four:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 65px;
    background: url(../images/landing/shape/128.svg) no-repeat center bottom;
    background-size: cover;
    left: 0;
    bottom: -63px;
}

.team-section-four .shape-one {
    right: 0;
    top: 5%;
}

.team-section-four .shape-two {
    left: 0;
    bottom: -3%;
}

.team-section-four .team-wrapper {
    position: relative;
    z-index: 5;
}

.team-section-four .team-member {
    margin-bottom: 60px;
    text-align: center;
    background: #fff;
    border-radius: 10px;
    overflow: hidden;
    padding-bottom: 30px;
    cursor: pointer;
}

.team-section-four .team-member .img-holder {
    overflow: hidden;
    border-radius: 10px 10px 0 0;
    margin-bottom: 30px;
}

.team-section-four .team-member .img-holder img {
    width: 100%;
    transition: all 0.5s ease-in-out;
}

.team-section-four .team-member:hover .img-holder img {
    transform: scale3d(1.1, 1.1, 1);
}

.team-section-four .team-member .name {
    font-size: 24px;
}

.team-section-four .team-member .position {
    font-size: 16px;
    color: rgba(42, 42, 42, .4);
}

.team-section-four .team-wrapper .shape-one {
    top: -6%;
    left: -5%;
}


/* =====================================================
				Feature Blog Four
===================================================== */

.feature-blog-four {
    background: rgb(224, 240, 254);
    position: relative;
    z-index: 1;
    padding: 100px 0 100px;
}


/* .feature-blog-four:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 85px;
    background: url(../images/landing/shape/115.svg) no-repeat center top;
    background-size: cover;
    left: 0;
    top: -84px;
} */

.feature-blog-four:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 33px;
    background: url(../images/landing/shape/116.svg) no-repeat center bottom;
    background-size: cover;
    left: 0;
    bottom: -32px;
}

.feature-blog-four .feature-article-meta {
    margin-bottom: 35px;
}

.feature-blog-four .feature-article-meta .img-meta {
    position: relative;
    overflow: hidden;
    margin-bottom: 40px;
}

.feature-blog-four .feature-article-meta .img-meta img {
    transition: all 0.5s ease-in-out;
    width: 100%;
}

.feature-blog-four .feature-article-meta:hover .img-meta img {
    transform: scale3d(1.1, 1.1, 1);
}

.feature-blog-four .feature-article-meta .post-meta .author_info {
    font-size: 20px;
    color: var(--heading);
    margin-top: 25px;
}

.feature-blog-four .feature-article-meta .post-meta a {
    font-size: 30px;
    line-height: 1.3em;
    transition: all 0.3s ease-in-out;
}

.feature-blog-four .feature-article-meta:hover .post-meta a {
    color: var(--vin-red);
}

.feature-blog-four .feature-article-meta .post-meta .author_info span {
    color: #B7B7B7;
}


/* =====================================================
		Our Features Page 
===================================================== */

.fancy-hero-three {
    background: rgb(224, 240, 254);
    position: relative;
    padding: 225px 0 180px;
    z-index: 5;
    text-align: center;
}

.fancy-hero-three:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 24px;
    background: url(../images/landing/shape/48.svg) no-repeat center;
    background-size: cover;
    left: 0;
    bottom: -20px;
}

.fancy-hero-three .shape-one {
    width: 6px;
    height: 6px;
    background: #F77A56;
    top: 29%;
    right: 27%;
    animation: jumpThree 5s infinite linear;
}

.fancy-hero-three .shape-two {
    width: 12px;
    height: 12px;
    background: #FFDBD0;
    top: 59%;
    right: 12%;
    animation: jumpTwo 5s infinite linear;
}

.fancy-hero-three .shape-three {
    width: 8px;
    height: 8px;
    background: #F77A56;
    top: 84%;
    right: 25%;
    animation: jumpThree 5s infinite linear;
}

.fancy-hero-three .shape-four {
    width: 10px;
    height: 10px;
    background: #FFDBD0;
    top: 79%;
    left: 21%;
    animation: jumpTwo 5s infinite linear;
}

.fancy-hero-three .shape-five {
    width: 6px;
    height: 6px;
    background: #F77A56;
    top: 50%;
    left: 12%;
    animation: jumpThree 5s infinite linear;
}

.fancy-hero-three .shape-six {
    width: 11px;
    height: 11px;
    background: #FF7052;
    top: 27%;
    left: 21%;
    animation: jumpTwo 5s infinite linear;
}

.fancy-hero-three .heading {
    font-weight: 800;
    font-size: 80px;
    line-height: 1.10em;
    padding: 0 0 38px;
}

.fancy-hero-three .sub-heading {
    font-size: 22px;
    line-height: 1.41em;
    color: #2A2A2A;
}


/* =============================================
				Fancy Text block Eleven
============================================== */

.fancy-text-block-eleven .text-wrapper {
    padding: 50px 0 0 65px;
}

.title-style-two h2,
.title-style-one h2 {
    font-weight: 800;
    font-size: 51px;
    line-height: 1.15em;
}

.title-style-two span {
    position: relative;
}

.title-style-two span img {
    position: absolute;
    bottom: -30px;
    left: 0;
    z-index: -1;
}

.fancy-text-block-eleven .text-wrapper p {
    font-size: 22px;
    line-height: 1.81em;
}

.fancy-text-block-eleven .img-gallery {
    position: relative;
    display: inline-block;
}

.fancy-text-block-eleven .img-gallery .overlay-img {
    position: absolute;
    right: -130px;
    top: -70px;
    z-index: 1;
}

.fancy-text-block-eleven .img-gallery .shape-one {
    top: -13%;
    left: -16%;
}

.fancy-text-block-eleven .img-gallery .shape-two {
    bottom: -19%;
    right: -21%;
    animation: rotatedTwo 50s infinite linear;
}


/* =============================================
				Fancy Feature Six
============================================== */

.fancy-feature-six {
    padding: 0 15px;
}

.fancy-feature-six .bg-wrapper {
    max-width: 1440px;
    margin: 0 auto;
    padding: 110px 0 210px;
    background: url(../images/landing/shape/bg4.svg) no-repeat top center;
    background-size: cover;
    position: relative;
    z-index: 5;
}

.fancy-feature-six .bg-wrapper .shape-one {
    width: 16px;
    height: 16px;
    background: #F96F60;
    z-index: 1;
    top: 5px;
    left: 14%;
    animation: jumpThree 5s infinite linear;
}

.fancy-feature-six .bg-wrapper .shape-two {
    width: 12px;
    height: 12px;
    background: #F96F60;
    top: 36%;
    right: 4%;
    animation: jumpTwo 5s infinite linear;
}

.fancy-feature-six .bg-wrapper .shape-three {
    width: 7px;
    height: 7px;
    background: #F96F60;
    top: 68%;
    right: 4%;
    opacity: .25;
    animation: jumpTwo 5s infinite linear;
}

.fancy-feature-six .bg-wrapper .shape-four {
    width: 10px;
    height: 10px;
    background: #F96F60;
    top: 91%;
    left: 7%;
    animation: jumpThree 5s infinite linear;
}

.fancy-feature-six .bg-wrapper .shape-five {
    width: 7px;
    height: 7px;
    background: #F96F60;
    top: 31%;
    left: 4%;
    opacity: .25;
    animation: jumpTwo 5s infinite linear;
}

.fancy-feature-six .bg-wrapper .shape-six {
    top: 78%;
    right: 2%;
    animation: rotatedTwo 50s infinite linear;
}

.title-style-two .sub-text {
    font-size: 24px;
    line-height: 1.5em;
    color: #6D6D6D;
    padding-top: 15px;
}

.block-style-five {
    background: #fff;
    transition: all 0.3s ease-in-out;
    padding: 46px 30px 50px 48px;
    margin-top: 30px;
    border-radius: 5px;
    cursor: pointer;
}

.block-style-five:hover {
    box-shadow: 0px 30px 50px rgb(0 0 0 / 4%);
    transform: translateY(-5px);
}

.block-style-five .icon {
    height: 72px;
}

.block-style-five .icon img {
    max-height: 100%;
}

.block-style-five .title {
    font-weight: 800;
    font-size: 20px;
    margin: 24px 0 29px;
    display: inline-block;
    position: relative;
}

.block-style-five .title:before {
    content: '';
    width: 100%;
    height: 3px;
    background: rgba(186, 211, 255, 0.8);
    border-radius: 2px;
    position: absolute;
    left: 0;
    bottom: -2px;
}

.block-style-five p {
    font-size: 24px;
    line-height: 1.45em;
    color: #000;
}


/* =====================================================
				Fancy Text block Twelve
===================================================== */

.title-style-three h6 {
    font-size: 18px;
    color: #ADB1B5;
    padding-bottom: 15px;
}

.title-style-three h2 {
    font-weight: 800;
    font-size: 47px;
    line-height: 1.18em;
}

.title-style-three span {
    position: relative;
}

.title-style-three span img {
    position: absolute;
    bottom: -21px;
    left: 0;
    z-index: -1;
}

.fancy-text-block-twelve .text-wrapper .sub-text {
    font-size: 22px;
    line-height: 1.81em;
    padding-top: 40px;
}

.fancy-text-block-twelve .img-gallery {
    display: inline-block;
    padding: 30px 45px 0 25px;
    position: relative;
}

.fancy-text-block-twelve .img-gallery .shape-one {
    top: -7%;
    left: -10%;
}

.fancy-text-block-twelve .img-gallery .shape-two {
    bottom: -13%;
    right: -4%;
    animation: jumpTwo 5s infinite linear;
}

.block-style-eight {
    background: #fff;
    transition: all 0.3s ease-in-out;
    padding: 20px 15px 25px 32px;
    margin-top: 30px;
    border: 2px solid #DCE4E8;
    border-radius: 5px;
}

.block-style-eight .title {
    font-weight: 800;
    font-size: 20px;
    margin: 0 0 24px;
    display: inline-block;
    position: relative;
}

.block-style-eight .title:before {
    content: '';
    width: 100%;
    height: 3px;
    background: rgba(186, 211, 255, 0.8);
    border-radius: 2px;
    position: absolute;
    left: 0;
    bottom: -2px;
}

.block-style-eight p {
    line-height: 1.72em;
    color: #000;
}

.block-style-eight:hover {
    border-color: var(--purple-blue);
    background: var(--purple-blue);
}

.block-style-eight:hover .title,
.block-style-eight:hover p {
    color: #fff;
}


/* =====================================================
				Useable Tools
===================================================== */

.useable-tools-section-two.bg-shape {
    padding: 0 15px;
}

.useable-tools-section-two.bg-shape .bg-wrapper {
    max-width: 1460px;
    margin: 0 auto;
    background: rgb(224, 240, 254);
    position: relative;
    padding: 108px 0 0;
    z-index: 1;
}

.useable-tools-section-two.bg-shape .bg-wrapper:before,
.team-section-four:before,
.feature-blog-four:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 15px;
    background-image: url(../images/landing/shape/37.svg);
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    top: -13px;
    left: 0;
}

.useable-tools-section-two.bg-shape .bg-wrapper:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 24px;
    background-image: url(../images/landing/shape/38.svg);
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: cover;
    bottom: -23px;
    left: 0;
}

.useable-tools-section-two.bg-shape .bg-wrapper .shapes {
    z-index: 1;
}

.useable-tools-section-two.bg-shape .bg-wrapper .shape-one {
    width: 6px;
    height: 6px;
    background: #F2DAD5;
    top: 6%;
    left: 11%;
    animation: jumpThree 5s infinite linear;
}

.useable-tools-section-two.bg-shape .bg-wrapper .shape-two {
    width: 10px;
    height: 10px;
    background: #F77A56;
    top: 10%;
    right: 11%;
    animation: jumpTwo 5s infinite linear;
}

.useable-tools-section-two.bg-shape .bg-wrapper .shape-three {
    width: 6px;
    height: 6px;
    background: #F2DAD5;
    top: 45%;
    right: 24%;
    animation: jumpThree 5s infinite linear;
}

.useable-tools-section-two.bg-shape .bg-wrapper .shape-four {
    width: 8px;
    height: 8px;
    background: #F77A56;
    top: 43%;
    left: 16%;
    animation: jumpTwo 5s infinite linear;
}

.title-style-two p {
    text-transform: uppercase;
    font-size: 16px;
    color: #ADB1B5;
    letter-spacing: 1.12px;
    padding-bottom: 7px;
}

.useable-tools-section-two .icon-wrapper ul {
    margin: 0 -15px;
    position: relative;
    z-index: 1;
    transform: translateY(58px);
}

.useable-tools-section-two .icon-wrapper ul li {
    padding: 0 15px;
    float: left;
    width: 11.11111%;
}

.useable-tools-section-two .icon-wrapper ul li:nth-child(odd) {
    margin-top: 50px;
}

.useable-tools-section-two .icon-wrapper ul li:nth-child(4),
.useable-tools-section-two .icon-wrapper ul li:nth-child(6) {
    margin-top: 110px;
}

.useable-tools-section-two .icon-wrapper ul li .icon-box {
    background: #fff;
    border-radius: 10px;
    height: 100px;
    margin: 20px 0;
    cursor: pointer;
}

.fancy-text-block-eleven .img-gallery .shape-three {
    bottom: -9%;
    left: -16%;
    animation: jumpThree 5s infinite linear;
}


/* =====================================================
				Fancy Short Banner Three
===================================================== */

.fancy-short-banner-three .bg-wrapper {
    background: url(../images/landing/shape/bg2.svg) no-repeat;
    background-size: cover;
    border-radius: 40px;
    padding: 82px 50px 89px;
    position: relative;
}


/* .fancy-short-banner-three .bg-wrapper:before {
    content: url(../images/landing/shape/43.svg);
    position: absolute;
    top: -124px;
    right: -104px;
    z-index: -1;
} */

.title-style-one h6 {
    font-size: 20px;
    color: #F96F60;
    padding-bottom: 15px;
}

.fancy-short-banner-three .title-style-one h2 {
    font-size: 42px;
}

@media (max-width: 1199px) {
    .fancy-short-banner-three .title-style-one h2 {
        font-size: 36px;
    }
}

.fancy-short-banner-three .form-wrapper {
    padding-left: 65px;
}

.fancy-short-banner-three .form-wrapper form {
    position: relative;
    height: 70px;
    background: #fff;
    border-radius: 7px;
}

.fancy-short-banner-three .form-wrapper form input {
    font-style: italic;
    font-size: 18px;
    width: 100%;
    border: none;
    height: 100%;
    padding: 0 150px 0 25px;
    background: transparent;
}

.fancy-short-banner-three .form-wrapper form button {
    position: absolute;
    right: 8px;
    bottom: 8px;
    top: 8px;
    width: 150px;
    border-radius: 6px;
    color: #fff;
    font-weight: 500;
    /* font-size: 16px;
    border: 2px solid var(--brand-color); */
    background: var(--brand-color);
    transition: all 0.3s ease-in-out;
    overflow: hidden;
}

.fancy-short-banner-three .form-wrapper form button span {
    position: relative;
    z-index: 1;
}

.fancy-short-banner-three .form-wrapper form button::before {
    z-index: 1;
}

.fancy-short-banner-three .form-wrapper p {
    font-size: 16px;
    padding-top: 10px;
}

.fancy-short-banner-three .form-wrapper p a {
    color: #000;
}


/* =============================================
		How it Works Page
============================================== */

.fancy-hero-three.bg-transparent::before {
    display: none;
}


/* =============================================
				Fancy Feature Seven
============================================== */

.fancy-feature-seven {
    background: #ECF6FF;
    padding: 170px 0 150px;
    position: relative;
    z-index: 1;
}

.fancy-feature-seven:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 45px;
    background: url(../images/landing/shape/58.svg) no-repeat top center;
    background-size: cover;
    left: 0;
    top: -42px;
}

.fancy-feature-seven:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 23px;
    background: url(../images/landing/shape/59.svg) no-repeat top center;
    background-size: cover;
    left: 0;
    bottom: -20px;
}

.block-style-nine {
    position: relative;
}

.block-style-nine:nth-child(1) {
    padding-bottom: 30px;
}

.block-style-nine:nth-child(1):before {
    content: url(../images/landing/shape/55.svg);
    position: absolute;
    left: 33%;
    bottom: -14%;
}

.block-style-nine:nth-child(2) {
    padding-bottom: 30px;
}

.block-style-nine:nth-child(2):before {
    content: url(../images/landing/shape/56.svg);
    position: absolute;
    right: 33%;
    bottom: -26%;
}

.block-style-nine:nth-child(3) {
    padding-bottom: 60px;
}

.block-style-nine:nth-child(3):before {
    content: url(../images/landing/shape/57.svg);
    position: absolute;
    left: 36%;
    bottom: -16%;
}

.block-style-nine .text-wrapper h6 {
    padding-bottom: 0;
}

.block-style-nine .text-wrapper .title {
    font-size: 38px;
    line-height: 1.28em;
    padding: 5px 0 30px;
}

.block-style-nine .text-wrapper p {
    font-size: 20px;
    line-height: 1.8em;
    color: rgba(14, 14, 14, 0.8);
}


/* =====================================================
		Fancy Text Block Thirteen
===================================================== */

.fancy-text-block-thirteen .text-wrapper p {
    font-size: 24px;
    line-height: 1.70em;
    padding-top: 45px;
}


/* =====================================================
				Fancy Text Block Fourteen
===================================================== */

.fancy-text-block-fourteen .text-wrapper .sub-text {
    font-size: 24px;
    line-height: 1.58em;
    color: #0E0E0E;
    padding-top: 40px;
}

.fancy-text-block-fourteen .text-wrapper .name {
    font-weight: 700;
    font-size: 24px;
    color: #000;
    position: relative;
    padding-left: 28px;
    margin-top: 40px;
}

.fancy-text-block-fourteen .text-wrapper .name:before {
    content: '';
    width: 18px;
    height: 3px;
    border-radius: 2px;
    background: #000;
    position: absolute;
    left: 0;
    top: 13px;
}

.fancy-text-block-fourteen .text-wrapper .name span {
    font-weight: 600;
    color: #AEB3B7;
}

.fancy-text-block-fourteen .img-holder {
    position: relative;
    padding: 0 50px 0 60px;
}

.fancy-text-block-fourteen .img-holder .shape-one {
    right: 0;
    top: -10%;
}

.fancy-text-block-fourteen .img-holder .shape-two {
    left: -1%;
    top: -12%;
    z-index: 1;
    animation: jumpTwo 5s infinite linear;
}


/* =====================================================
		Pricing Page
===================================================== */

.pricing-section-three-inner-page {
    background: rgb(224, 240, 254);
    position: relative;
    z-index: 1;
    padding: 215px 0 110px;
}

.pricing-section-three-inner-page:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 33px;
    left: 0;
    bottom: -30px;
    background: url(../images/landing/shape/116.svg) no-repeat center bottom;
    background-size: 100% 100%;
}

.pricing-section-three-inner-page .shape-one {
    left: 0;
    top: 19%;
}

.pricing-section-three-inner-page .shape-two {
    right: 0;
    top: 19%;
}


/* =====================================================
		FAQ Section
===================================================== */

.faq-section-four.bg-white::before,
.faq-section-four.bg-white::after {
    display: none;
}

.faq-section-four.bg-white .accordion-style-four .card {
    background: #F7F7F7;
}


/* =====================================================
		SignUp Section
===================================================== */

.fancy-short-banner-five.with-bg {
    position: relative;
    background: rgb(224, 240, 254);
    z-index: 1;
}

.fancy-short-banner-five.with-bg .shape-one {
    right: 5%;
    top: 20%;
}

.fancy-short-banner-five.with-bg .shape-two {
    left: 5%;
    top: 20%;
}


/* ===========================
		Privacy Policy Page
============================= */

section.policy {
    padding: 150px 0;
}

@media (max-width: 767px) {
    section.policy {
        padding: 120px 0;
    }
}

section.policy .section-title {
    margin-bottom: 40px;
}

section.terms .section-title p {
    font-size: 20px !important;
}

section.policy [class*="text-que-"] {
    margin-bottom: 30px;
}

section.policy [class*="text-que-"]:last-of-type {
    margin-bottom: 0;
}

[class*="text-que-"] h3 {
    margin-bottom: 15px;
    font-size: 24px;
}

[class*="text-que-"] p,
[class*="text-que-"] ul li {
    font-size: 18px;
}

[class*="text-que-"] ul li {
    margin-top: 10px;
}

.MuiCalendarPicker-root {
    width: 100% !important;
    max-height: unset !important;
}

.MuiCalendarPicker-root .css-1n2mv2k {
    justify-content: space-around;
}

.MuiCalendarPicker-root .css-dhopo2 {
    min-height: 480px;
}

.MuiCalendarPicker-root .PrivatePickersSlideTransition-root [role="row"] {
    justify-content: space-around;
    margin: 26px 0;
}

.MuiCalendarPicker-root .MuiTypography-caption {
    font-weight: 600;
    font-size: 14px;
    font-family: 'Red Hat Display', sans-serif;
    color: var(--ui-text);
}

.MuiCalendarPicker-root .MuiPickersDay-root {
    width: 40px;
    height: 40px;
    font-size: 16px;
    font-family: 'Red Hat Display', sans-serif;
    border-radius: 10px;
}


/* Integration Responsive */

.connect-calnder2 h3,
.conference_apps h3 {
    font-size: 18px;
    margin-bottom: 4px;
}

.connect-calnder2 p,
.conference_apps p {
    font-size: 14px;
    line-height: 1.1 !important;
}

.connect-calnder2 .MuiFormControlLabel-root span {
    font-size: 14px;
}